import {
  Deck,
  DeckProps,
  FilterContext,
  Layer,
  MapView,
  MapViewState,
  OrbitView,
  OrbitViewState,
  OrthographicView,
  OrthographicViewState,
} from '@deck.gl/core/typed';
import GL from '@luma.gl/constants';
import {
  NODE_TYPE_PROJECT,
  SyntheticNode,
  Node,
  NODE_TYPE_BUCKET,
  getNodePropsFromParametricInputParamsList,
  getCubesFromNodeProps,
  ParametricCube,
  NodeKeyframe,
  RELATION_KEY_HAS_DATA_SOURCE,
  getDataSourceNodeProps,
  NodeProps,
  EdgeProps,
  Appearance,
  getChildNodes,
  NodePropsMap,
  getEdgeId,
  mergeNodeProps,
  UpdateTriggers,
  getSegmentsFromNodeProps,
} from '@paramountric/graph';
// import { Portal, PortalProps } from './portal';
import { convertArrayOfObjectsToCSV } from './util/objectToCsv';
import { NodeViewerProps, defaultNodeViewerProps } from './viewer-props';
import EventSource from './event-source';
import { getIconAtlas } from './layers/arrows';
import { lerp } from './util/interpolators';
import { ViewStateChangeParameters } from '@deck.gl/core/typed/controllers/controller';
// import { Interaction } from './interaction/interaction';
// import ZoomToNodeInterpolator from './zoom-to-node-interpolator';
// import { ManualLayout } from './layouts/manual-layout';
// import { EdgeInteractionState, NodeInteractionState } from './layouts/layout';
import {
  // BEZIER_EDGE_LABEL_LAYER_ID,
  ChartLayout,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  // DEFAULT_START_ZOOM,
  // DEFAULT_VIEW_AREA_SIZE,
  // EDGE_NODE_LAYER_KEY,
  // BoardLayout,
  // MapLayout,
  // GeoLayout,
  // GltfLayout,
  // ImageLayout,
  // Layout,
  // LayoutProps,
  // NODE_POLYGON_LAYER_KEY,
  // STRAIGHT_EDGE_LABEL_LAYER_ID,
  // TILE_SIZE,
  // SupplyChainLayout,
  // getSupplyChainLayout,
  // VegaLayout,
} from '@paramountric/layout';
import {
  EdgeInteractionState,
  InteractionManager,
  InteractionMode,
  NodeInteractionState,
  Pixel,
} from './interaction-manager';
import { debounce } from './util/debounce';
import { Timeline } from '@paramountric/animation';
import { GltfLayout } from './gltf-layout';

const DEBUG = true;

// this has keys from both RoomLayoutKey and BucketLayoutKey!!
const availableLayouts = {
  // manual: BoardLayout,
  // cubes: ChartLayout,
  // // vega: ChartLayout,
  // image: ImageLayout,
  // // force: ForceLayout,
  // // cubes: CubesLayout,
  // // sankey: SankeyLayout,
  // // supplychain: SupplyChainLayout,
  gltf: GltfLayout,
};

export class NodeViewer extends EventSource {
  props: NodeViewerProps;
  deck: Deck | null;
  canvas: HTMLCanvasElement;
  // boardLayout: BoardLayout;
  nodeViewLayouts: {
    [nodeUri: string]: any;
  } = {}; //Map<string, Layout> = new Map();

  // keep the cache ref here so that nodes can be found from viewport by versionUri
  nodes: NodePropsMap = new Map();

  projectNode: NodeProps;
  // we want to sync each portal keyframes, so the timeline is shared
  timeline: Timeline;
  interactionManager: InteractionManager;
  interactionMode: InteractionMode = 'panning';
  shouldAnimate: boolean = false;
  videoCapture: any; // todo: check out capture/video-capture
  presenting: boolean = false;

  // versionUri cache for parametric cubes
  cubeCache: Map<string, ParametricCube[]> = new Map();

  // only use to rerender selection bounds
  selectedVersionUriCache: string[] = [];

  blob: Blob;
  mimeType: string = 'image/png';
  quality: number = 1;

  constructor(viewportProps: NodeViewerProps) {
    super();

    const { interactionMode, debug, projectNode, presenting, ...restProps } =
      viewportProps;
    const { projectNode: defaultProjectNode, ...restDefaultNodeViewerProps } =
      defaultNodeViewerProps;
    const resolvedProps = Object.assign(
      {
        // default - will be overwritten by restProps
        width: window.innerWidth,
        height: window.innerHeight,
      },
      restDefaultNodeViewerProps,
      restProps,
      // will overwrite default and sent in
      {
        // onLoad: this.onLoad.bind(this),
        onError: (error: Error) => {
          if (viewportProps.onContextLost) {
            viewportProps.onContextLost(error);
          }
        },
        // onHover: this.onHover.bind(this),
        // onResize: this.onResize.bind(this),
        // getCursor: this.interactionManager.getCursor,
        getTooltip: null,
        // id: 'viewport',
        debug: DEBUG || debug || false,
      }
    ) as DeckProps;
    // this.props = resolvedProps as NodeViewerProps;
    // this.presenting = presenting || false;

    // if (interactionMode) {
    //   this.interactionMode = interactionMode;
    // }

    // this.timeline = new Timeline();

    // const position = (
    //   projectNode.appearance?.position
    //     ? [...projectNode.appearance.position]
    //     : [TILE_SIZE / 2, TILE_SIZE / 2, 0]
    // ) as [number, number, number];

    // const initialCameraFrame: Appearance = {
    //   position: position,
    //   target: position,
    //   zoom: projectNode.appearance?.zoom || DEFAULT_START_ZOOM,
    //   rotationOrbit: projectNode.appearance?.rotationOrbit || 0,
    //   rotationX: projectNode.appearance?.rotationX || 90,
    //   minZoom: projectNode.appearance?.minZoom || DEFAULT_MIN_ZOOM,
    //   maxZoom: projectNode.appearance?.maxZoom || DEFAULT_MAX_ZOOM,
    //   backgroundColor:
    //     projectNode.appearance?.backgroundColor ||
    //     (DEFAULT_BACKGROUND_COLOR as [number, number, number]),
    //   viewX: projectNode.appearance?.viewX || 0,
    //   viewY: projectNode.appearance?.viewY || 0,
    //   paddingLeft: projectNode.appearance?.paddingLeft || 0,
    //   // width: this.props.width,
    //   // height: this.props.height,
    // };

    // this.projectNode = {
    //   id: projectNode.id || defaultProjectNode.id,
    //   key: projectNode.key || defaultProjectNode.key,
    //   type: NODE_TYPE_PROJECT,
    //   name: projectNode.name || defaultProjectNode.name,
    //   // the projectNode is the board and the id === boardId
    //   boardId: projectNode.id || defaultProjectNode.id,
    //   namespace: projectNode.namespace || defaultProjectNode.namespace,
    //   appearance: {
    //     // add settings from DB for the board / node here
    //     ...initialCameraFrame,
    //     // cannot override the size of the board
    //     size: [DEFAULT_VIEW_AREA_SIZE, DEFAULT_VIEW_AREA_SIZE],
    //   },
    // };
    // this.boardLayout = new BoardLayout({
    //   parentNodeProps: this.projectNode,
    //   nodePropsList: [],
    //   edgePropsList: [],
    //   timeline: this.timeline,
    //   cameraFrame: {
    //     ...initialCameraFrame,
    //   },
    // });
    // console.log('this.boardLayout', this.boardLayout);
    // console.log('id', this.boardLayout.getViewId());
    // this.interactionManager = new InteractionManager({ viewport: this });

    // console.log(this.projectNode, '<- p');

    // // this.onViewStateChange = this.onViewStateChange.bind(this);
    // // this.layerFilter = this.layerFilter.bind(this);

    // console.log('viewport', resolvedProps);
    // this.deck = new Deck(resolvedProps);
    // console.log('deck', this.deck);
  }

  // // call from a react component on unmount
  // dispose() {
  //   this.deck?.finalize();
  //   this.deck = null;
  // }

  // onLoad() {
  //   console.log('onLoad');
  //   // @ts-ignore
  //   const { gl } = this.deck.deckRenderer;
  //   this.deck._addResources({
  //     arrowAtlas: getIconAtlas(gl),
  //   });
  //   // @ts-ignore
  //   this.canvas = this.deck.canvas;

  //   // this.canvas.addEventListener(
  //   //   'mousemove',
  //   //   this.interactionManager.onMouseMove
  //   // );
  //   // this.canvas.addEventListener(
  //   //   'mousedown',
  //   //   this.interactionManager.onMouseDown
  //   // );
  //   // this.canvas.addEventListener('mouseup', this.interactionManager.onMouseUp);
  //   // this.canvas.addEventListener(
  //   //   'mouseout',
  //   //   this.interactionManager.onMouseOut
  //   // );
  //   // // add event listener for scroll wheel
  //   // this.canvas.addEventListener('wheel', this.interactionManager.onWheel);
  //   // this.canvas.addEventListener('dragover', e => {
  //   //   e.preventDefault();
  //   // });
  //   // this.canvas.addEventListener('drop', (e: any) => {
  //   //   e.preventDefault();
  //   //   if (e.dataTransfer?.files?.length > 0) {
  //   //     this.fileManager.importFile(
  //   //       e.dataTransfer.files[0],
  //   //       e.clientX,
  //   //       e.clientY
  //   //     );
  //   //   }
  //   // });

  //   this.update();
  //   this.emit('viewport:onload');
  // }

  // boardIsTilted() {
  //   return this.boardLayout.isTilted();
  // }

  // getBoardZoom() {
  //   return this.boardLayout.getZoom();
  // }

  // getViews() {
  //   const views = [
  //     this.boardLayout.getView({
  //       nodeProps: this.boardLayout.parentNodeProps,
  //       interactionMode: this.interactionMode,
  //       disableController: this.interactionManager.disableController,
  //       orthographic: this.props.orthographic === false ? false : true,
  //     }),
  //   ];

  //   // todo: should render a picture on the parent node until the user interacts with the view
  //   // also, in 3D the view cannot be shown -> should have a 3D representation of all views (optimised)
  //   if (!this.boardLayout.isTilted() && !this.boardLayout.isRotated()) {
  //     const children = this.boardLayout.getVisibleNodes() || [];
  //     for (const child of children) {
  //       const nodeUri = child.nodeUri;
  //       const layout = this.nodeViewLayouts[nodeUri];
  //       if (
  //         layout &&
  //         layout.hasView() &&
  //         layout.viewIsActive(this.presenting)
  //       ) {
  //         const view = this.nodeViewLayouts[nodeUri]?.getView({
  //           nodeProps: child,
  //           orthographic: child.appearance?.orthographic || false,
  //           disableController: this.presenting,
  //         });
  //         views.push(view);
  //       }
  //     }
  //   }
  //   return views;
  // }

  // getViewStates(): {
  //   [viewId: string]: Appearance;
  // } {
  //   const viewStates = {
  //     [this.boardLayout.getViewId()]: this.boardLayout.cameraFrame,
  //   };
  //   const children = this.boardLayout.getVisibleNodes() || [];
  //   for (const child of children) {
  //     const nodeUri = child.nodeUri;
  //     const layout = this.nodeViewLayouts[nodeUri];
  //     if (layout && layout.hasView() && layout.viewIsActive(this.presenting)) {
  //       const viewState = this.nodeViewLayouts[nodeUri]?.getViewState();
  //       viewStates[nodeUri] = viewState;
  //     }
  //   }
  //   return viewStates;
  // }

  // snapRotation(currentRotationOrbit: number): number {
  //   if (currentRotationOrbit > 1 || currentRotationOrbit < -1) {
  //     return currentRotationOrbit;
  //   }
  //   return Math.round(currentRotationOrbit);
  // }

  // setBoardCameraFrame(viewState: Appearance, viewId) {
  //   if (this.presenting) {
  //     // let the Canvas component increse timing from the zoom increments
  //   } else {
  //     this.boardLayout.cameraFrame = {
  //       ...this.boardLayout.cameraFrame,
  //       ...viewState,
  //       rotationOrbit: this.snapRotation(viewState.rotationOrbit),
  //     };
  //   }
  // }

  // onViewStateChange({
  //   viewState,
  //   viewId,
  //   interactionState,
  //   oldViewState,
  // }: ViewStateChangeParameters & { viewId: string }) {
  //   // if board layout - change the main camera
  //   if (viewId === this.boardLayout.getViewId()) {
  //     this.setBoardCameraFrame(viewState, viewId);

  //     // all children with views must be zoom synced or panned when board is panned
  //     const children = this.boardLayout.getVisibleNodes() || [];
  //     for (const child of children) {
  //       this.updateNodeViewFromUserInteraction(child, viewState);
  //       // if (
  //       //   child.layoutKey === 'map' ||
  //       //   child.layoutKey === 'timeline'
  //       // ) {
  //       //   const nodeUri = child.nodeUri;
  //       //   const nodeLayout = this.nodeViewLayouts[nodeUri];
  //       //   if (nodeLayout && nodeLayout.hasView()) {
  //       //     const view = this.deck?.getViewports().find(v => v.id === viewId);
  //       //     if (view) {
  //       //       const nodePixels = this.getNodePixels(child, view);
  //       //       const nodeCameraFrame = nodeLayout.getCameraFrame();
  //       //       const zoomDiff = viewState.zoom - oldViewState.zoom;
  //       //       // console.log('zoomDiff', zoomDiff);
  //       //       nodeLayout.setCameraFrame(
  //       //         {
  //       //           ...nodeCameraFrame,
  //       //           ...nodePixels,
  //       //           zoom: nodeCameraFrame.zoom + zoomDiff,
  //       //           // zoom:
  //       //           //   (nodeLayout.parentNode.insight?.zoom || 0) + viewState.zoom, // nodeLayout.getCameraFrame().zoom + zoomDiff,
  //       //         } as CameraKeyframe,
  //       //         nodeUri
  //       //       );
  //       //     }
  //       //   } else {
  //       //     // console.log('no layout for', viewId);
  //       //   }
  //       // }
  //     }

  //     this.update();
  //     this.updateSelectedNodeBox(this.selectedVersionUriCache);
  //     this.emit('viewport:camerachange', viewState);
  //   } else {
  //     const nodeUri = viewId;
  //     const nodeLayout = this.nodeViewLayouts[nodeUri];
  //     if (nodeLayout) {
  //       nodeLayout.setCameraFrame(viewState as any, nodeUri);
  //       // const viewNode = this.boardLayout.getNodePropsFromNodeFrameUri(nodeUri);
  //       this.update();
  //       // update the camera on the node viewer to db
  //       // do not do this here, instead when the view is inactivated
  //       // this.emit('node:camerachange', {
  //       //   viewState,
  //       //   nodeProps: viewNode,
  //       // });
  //     } else {
  //       // console.log('no layout for', viewId);
  //     }
  //   }
  // }

  // updateNodeViewFromUserInteraction(
  //   nodeProps: NodeProps,
  //   viewState: Appearance
  // ) {
  //   if (
  //     nodeProps.layoutKey === 'map' ||
  //     nodeProps.layoutKey === 'timeline' ||
  //     nodeProps.layoutKey === 'gltf'
  //   ) {
  //     const nodeUri = Node.nodePropsToUri(nodeProps);
  //     const viewId = this.boardLayout.getViewId();
  //     const nodeLayout = this.nodeViewLayouts[nodeUri];
  //     if (nodeLayout && nodeLayout.hasView()) {
  //       const view = this.deck?.getViewports().find(v => v.id === viewId);
  //       if (view) {
  //         const nodePixels = this.getNodePixels(nodeProps, view);
  //         // check if parent node has the animation from nodes = camera settings
  //         const nodeCameraFrame =
  //           this.boardLayout.getNodeFrame(nodeProps) ||
  //           nodeLayout.getCameraFrame();
  //         console.log('get node frame', nodeCameraFrame);
  //         const pixelPadding = 0;
  //         const viewX = nodePixels.viewX + pixelPadding;
  //         const viewY = nodePixels.viewY + pixelPadding;
  //         const width = nodePixels.width - pixelPadding * 2;
  //         const height = nodePixels.height - pixelPadding * 2;
  //         // const zoomDiff = viewState.zoom - oldViewState.zoom;
  //         // console.log('zoomDiff', zoomDiff);
  //         nodeLayout.setCameraFrame(
  //           {
  //             ...nodeCameraFrame,
  //             viewX,
  //             viewY,
  //             width,
  //             height,
  //             // zoom: nodeCameraFrame.zoom + zoomDiff,
  //             // zoom:
  //             //   (nodeLayout.parentNode.insight?.zoom || 0) + viewState.zoom, // nodeLayout.getCameraFrame().zoom + zoomDiff,
  //           } as Appearance,
  //           nodeUri
  //         );
  //       }
  //     } else {
  //       // console.log('no layout for', viewId);
  //     }
  //   } else if (nodeProps.layoutKey === 'geo') {
  //     const nodeUri = Node.nodePropsToUri(nodeProps);
  //     const nodeLayout = this.nodeViewLayouts[nodeUri];
  //     if (nodeLayout) {
  //       // this is to sync the isTilted for the node viewer layers
  //       // nodeLayout.setCameraFrame({ rotationX: viewState.rotationX });
  //     }
  //   }
  // }

  // // async canvasToArrayBuffer(
  // //   canvas: HTMLCanvasElement,
  // //   type: string,
  // //   quality?: number
  // // ) {
  // //   const base64 = canvas.toDataURL(type, quality);
  // //   const response = await fetch(base64);
  // //   return await response.arrayBuffer();
  // // }

  // // async add(canvas: HTMLCanvasElement) {
  // //   // Adding a frame just overwrites old image
  // //   const buffer = await this.canvasToArrayBuffer(
  // //     canvas,
  // //     this.mimeType,
  // //     this.quality
  // //   );
  // //   this.blob = new Blob([buffer], { type: this.mimeType });
  // //   return Promise.resolve();
  // // }

  // deactivateViews() {
  //   for (const nodeViewer of Object.values(this.nodeViewLayouts)) {
  //     if (nodeViewer.viewIsActive(this.presenting)) {
  //       console.log('deactivate and snapshot', nodeViewer.id);
  //       this.snapshot(nodeViewer.id);
  //       nodeViewer.setViewActive(false);
  //       this.emit('view:deactivate', nodeViewer);
  //     }
  //   }
  //   this.update();
  // }

  // activateView(nodeProps: NodeProps) {
  //   const nodeUri = Node.nodePropsToUri(nodeProps);
  //   console.log('activateView', nodeUri, nodeProps);
  //   if (this.nodeViewLayouts[nodeUri]) {
  //     const nodePixels = this.getNodePixels(nodeProps);
  //     this.nodeViewLayouts[nodeUri].setCameraFrame({
  //       // latitude: nodeProps.geometry?.coordinates[1], // figure out how to do with coordinate since geojson might be a polygon
  //       ...nodePixels,
  //       zoom: nodeProps.appearance?.zoom || 0,
  //       rotationX: nodeProps.appearance?.rotationX || 0,
  //       rotationOrbit: nodeProps.appearance?.rotationOrbit || 0,
  //       longitude: nodeProps.appearance?.longitude,
  //       latitude: nodeProps.appearance?.latitude,
  //       bearing: nodeProps.appearance?.bearing || 0,
  //       pitch: nodeProps.appearance?.pitch || 0,
  //       target: nodeProps.appearance?.target || [0, 0, 0],
  //     });
  //     this.nodeViewLayouts[nodeUri].setViewActive(true);
  //     this.emit('view:activate', this.nodeViewLayouts[nodeUri]);
  //   } else {
  //     console.warn('no node layout for', nodeUri);
  //   }
  //   this.update();
  // }

  // nodeViewerIsActive(nodeProps: NodeProps) {
  //   return this.nodeViewLayouts[Node.nodePropsToUri(nodeProps)]?.viewIsActive(
  //     this.presenting
  //   );
  // }

  // emitSnapshot = debounce(nodeUri => {
  //   this.snapshot(nodeUri);
  //   this.update();
  // }, 1000);

  // snapshot(nodeUri?: string) {
  //   // @ts-ignore
  //   const ctx = this.canvas.getContext('webgl2');
  //   let width;
  //   let height;
  //   let viewX;
  //   let viewY;
  //   let nodeProps;
  //   let cameraView;

  //   // snapshot on full board
  //   if (!nodeUri || nodeUri === this.boardLayout.getViewId()) {
  //     width = this.props.width;
  //     height = this.props.height;
  //     viewX = this.props.viewX || 0;
  //     viewY = this.props.viewY || 0;

  //     if (!width || !height) {
  //       console.warn('no width or height');
  //       return;
  //     }

  //     nodeProps = this.boardLayout.parentNodeProps;
  //     cameraView = this.boardLayout.getCameraFrame();
  //   } else {
  //     // snapshot on node viewer
  //     const nodeLayout = this.nodeViewLayouts[nodeUri];
  //     if (nodeLayout) {
  //       const view = true; // this.deck?.getViewports().find(v => v.id === nodeUri);
  //       nodeProps = this.boardLayout.getNodePropsFromNodeFrameUri(nodeUri);
  //       if (!view || !nodeProps) {
  //         console.warn(
  //           'no node frame for snapshot',
  //           this.deck?.getViewports(),
  //           nodeUri,
  //           nodeProps
  //         );
  //         return;
  //       }
  //       const nodePixels = this.getNodePixels(
  //         nodeProps
  //         // view
  //       );
  //       width = nodePixels.width;
  //       height = nodePixels.height;
  //       viewX = nodePixels.viewX;
  //       viewY = nodePixels.viewY;
  //       cameraView = nodeLayout.getCameraFrame();
  //     } else {
  //       console.warn('no node layout for snapshot', nodeUri);
  //       return;
  //     }
  //   }

  //   // // Create a simple shader program
  //   // const vertexShader = ctx.createShader(ctx.VERTEX_SHADER);
  //   // ctx.shaderSource(
  //   //   vertexShader,
  //   //   `
  //   //   attribute vec2 a_position;
  //   //   void main() {
  //   //     gl_Position = vec4(a_position, 0, 1);
  //   //   }
  //   // `
  //   // );
  //   // ctx.compileShader(vertexShader);

  //   // const fragmentShader = ctx.createShader(ctx.FRAGMENT_SHADER);
  //   // ctx.shaderSource(
  //   //   fragmentShader,
  //   //   `
  //   //   void main() {
  //   //     gl_FragColor = vec4(1, 0, 0, 1);  // Red color
  //   //   }
  //   // `
  //   // );
  //   // ctx.compileShader(fragmentShader);

  //   // const program = ctx.createProgram();
  //   // ctx.attachShader(program, vertexShader);
  //   // ctx.attachShader(program, fragmentShader);
  //   // ctx.linkProgram(program);

  //   // // Create a buffer for the rectangle's positions
  //   // const positionBuffer = ctx.createBuffer();
  //   // ctx.bindBuffer(ctx.ARRAY_BUFFER, positionBuffer);
  //   // ctx.bufferData(
  //   //   ctx.ARRAY_BUFFER,
  //   //   new Float32Array([
  //   //     -1,
  //   //     -1, // First vertex
  //   //     1,
  //   //     -1, // Second vertex
  //   //     -1,
  //   //     1, // Third vertex
  //   //     -1,
  //   //     1, // Fourth vertex
  //   //     1,
  //   //     -1, // Fifth vertex
  //   //     1,
  //   //     1, // Sixth vertex
  //   //   ]),
  //   //   ctx.STATIC_DRAW
  //   // );

  //   // // Set the rectangle's positions for the vertex shader
  //   // const positionLocation = ctx.getAttribLocation(program, 'a_position');
  //   // ctx.enableVertexAttribArray(positionLocation);
  //   // ctx.vertexAttribPointer(positionLocation, 2, ctx.FLOAT, false, 0, 0);

  //   // // Draw the rectangle
  //   // ctx.useProgram(program);
  //   // ctx.drawArrays(ctx.TRIANGLES, 0, 6);

  //   this.deck.redraw('true');

  //   // Read the pixels
  //   // const pixelsTest = new Uint8Array(width * height * 4);
  //   // ctx.readPixels(
  //   //   0,
  //   //   0,
  //   //   width,
  //   //   height,
  //   //   ctx.RGBA,
  //   //   ctx.UNSIGNED_BYTE,
  //   //   pixelsTest
  //   // );

  //   // // Check if the pixel data is as expected (red rectangle)
  //   // const isPixelsAsExpected = pixelsTest.every((value, index) => {
  //   //   // The pixel data is in RGBA format, so we check every 4th value for red, green, and blue
  //   //   return (
  //   //     (index % 4 === 0 && value === 255) || // Red
  //   //     (index % 4 === 1 && value === 0) || // Green
  //   //     (index % 4 === 2 && value === 0) || // Blue
  //   //     (index % 4 === 3 && value === 255)
  //   //   ); // Alpha
  //   // });
  //   // console.log(`Is pixel data as expected: ${isPixelsAsExpected}`);

  //   // width = this.props.width;
  //   // height = this.props.height;

  //   ctx.finish();

  //   // Create a buffer to store the pixel data
  //   const pixels = new Uint8Array(width * height * 4);

  //   // Unbind any currently bound framebuffer
  //   // ctx.bindFramebuffer(ctx.FRAMEBUFFER, null);

  //   // Calculate the adjusted y-coordinate
  //   const adjustedY = ctx.drawingBufferHeight - viewY - height;

  //   // Read the pixels from the defined area
  //   ctx.readPixels(
  //     viewX,
  //     adjustedY,
  //     width,
  //     height,
  //     ctx.RGBA,
  //     ctx.UNSIGNED_BYTE,
  //     pixels
  //   );

  //   // // Fill the buffer with a pattern (alternating black and white pixels)
  //   // // for (let i = 0; i < pixels.length; i += 4) {
  //   // //   const color = (i / 4) % 2 === 0 ? 255 : 0; // Alternate between black and white
  //   // //   pixels[i] = color; // Red
  //   // //   pixels[i + 1] = color; // Green
  //   // //   pixels[i + 2] = color; // Blue
  //   // //   pixels[i + 3] = 255; // Alpha
  //   // // }

  //   // // Check if the pixel data is empty
  //   // const isPixelsEmpty = pixels.every(value => value === 0);
  //   // console.log(`Is pixel data empty: ${isPixelsEmpty}`);

  //   // await this.add(this.canvas);

  //   // Create a new canvas to draw the extracted part
  //   const canvas = document.createElement('canvas');
  //   canvas.width = width;
  //   canvas.height = height;
  //   const newCtx = canvas.getContext('2d');
  //   const imageData = new ImageData(
  //     new Uint8ClampedArray(pixels),
  //     width,
  //     height
  //   );
  //   newCtx.putImageData(imageData, 0, 0);

  //   // Flip the image vertically
  //   newCtx.save();
  //   newCtx.scale(1, -1);
  //   newCtx.drawImage(canvas, 0, 0, width, height, 0, -height, width, height);
  //   newCtx.restore();

  //   // Convert canvas to Blob
  //   canvas.toBlob(blob => {
  //     this.emit('snapshot', {
  //       nodeProps,
  //       cameraView,
  //       blob, //: this.blob,
  //     });
  //   });

  //   // else {
  //   //   const nodeLayout = this.nodeViewLayouts[nodeUri];
  //   //   if (nodeLayout) {
  //   //     const view = this.deck?.getViewports().find(v => v.id === nodeUri);
  //   //     const nodeProps =
  //   //       this.boardLayout.getNodePropsFromNodeFrameUri(nodeUri);
  //   //     if (!view || !nodeProps) {
  //   //       console.warn(
  //   //         'no node frame',
  //   //         this.deck?.getViewports(),
  //   //         nodeUri,
  //   //         nodeProps
  //   //       );
  //   //       return;
  //   //     }
  //   //     const { viewX, viewY, width, height } = this.getNodePixels(
  //   //       nodeProps
  //   //       // view
  //   //     );
  //   //     // @ts-ignore
  //   //     const ctx = this.deck.deckRenderer.gl;

  //   //     console.log('snapshot', width, height, viewX, viewY);

  //   //     if (!width || !height) {
  //   //       console.warn('no width or height');
  //   //       return;
  //   //     }

  //   //     // Create a buffer to store the pixel data
  //   //     const pixels = new Uint8Array(width * height * 4);

  //   //     // Read the pixels from the defined area
  //   //     ctx.readPixels(
  //   //       viewX,
  //   //       viewY,
  //   //       width,
  //   //       height,
  //   //       ctx.RGBA,
  //   //       ctx.UNSIGNED_BYTE,
  //   //       pixels
  //   //     );

  //   //     // Create a new canvas to draw the extracted part
  //   //     const canvas = document.createElement('canvas');
  //   //     canvas.width = width;
  //   //     canvas.height = height;
  //   //     const newCtx = canvas.getContext('2d');
  //   //     const imageData = new ImageData(
  //   //       new Uint8ClampedArray(pixels),
  //   //       width,
  //   //       height
  //   //     );
  //   //     newCtx.putImageData(imageData, 0, 0);

  //   //     // Convert canvas to Blob
  //   //     canvas.toBlob(blob => {
  //   //       this.emit('snapshot', {
  //   //         nodeProps,
  //   //         blob,
  //   //       });

  //   //       // Upload the Blob to S3
  //   //       // const params = {
  //   //       //     Key: fileName,
  //   //       //     Body: blob,
  //   //       //     ContentType: 'image/png'
  //   //       // };

  //   //       // s3.upload(params, (err, data) => {
  //   //       //     if (err) {
  //   //       //         return console.error('Error uploading image:', err);
  //   //       //     }
  //   //       //     console.log('Successfully uploaded image to S3:', data.Location);
  //   //       // });
  //   //     }, 'image/png');

  //   //     // Convert the new canvas to a Base64 string
  //   //     // const dataURL = newCanvas.toDataURL('image/png');
  //   //     // const base64String = dataURL.split(',')[1];

  //   //     // // Create JSON structure
  //   //     // const imageDataJson = {
  //   //     //   image: base64String,
  //   //     //   format: 'png',
  //   //     //   metadata: {
  //   //     //     width: width,
  //   //     //     height: height,
  //   //     //     extractedAt: new Date().toISOString(),
  //   //     //   },
  //   //     // };
  //   //   }
  //   // }
  // }

  // layerFilter({ layer, viewport }: FilterContext) {
  //   if (!layer || !viewport) {
  //     return false;
  //   }
  //   if (!layer.id.startsWith(viewport.id)) {
  //     // console.log('skip layer', layer.id, viewport.id);
  //     return false;
  //   }
  //   return true;
  // }

  // getLayers() {
  //   const layers =
  //     this.boardLayout.getLayers(
  //       this.interactionManager,
  //       null,
  //       this.boardLayout.getViewId(),
  //       this.presenting
  //     ) || [];

  //   // todo: check which children have an insight attached -> render child layers (or instantiate first if layout is not instantiated yet)
  //   // ONLY FOR VISIBLE NODES of the active portal layout
  //   const visibleNodes = this.boardLayout.getVisibleNodes() || [];
  //   for (const child of visibleNodes) {
  //     const { nodeUri } = child;
  //     let nodeLayout = this.nodeViewLayouts[nodeUri];
  //     // if the child viewer does NOT have a view - or if it HAS a view AND the view is active
  //     if (
  //       nodeLayout &&
  //       (!nodeLayout.hasView() || nodeLayout.viewIsActive(this.presenting))
  //     ) {
  //       const childLayers = nodeLayout.getLayers(
  //         this.interactionManager,
  //         {
  //           ...child,
  //           appearance: {
  //             ...child.appearance,
  //             position: child.appearance.position,
  //             size: child.appearance.size,
  //           },
  //         },
  //         nodeLayout.hasView()
  //           ? nodeLayout.getViewId()
  //           : this.boardLayout.getViewId()
  //       );
  //       // console.log('childlayers', childLayers);
  //       if (childLayers && childLayers.length > 0) {
  //         layers.push(...childLayers);
  //       }
  //     }
  //   }

  //   return layers;
  // }

  // getNodeFrame(nodeProps: NodeProps) {
  //   // todo: search node in nodeViewLayouts as well
  //   return this.boardLayout.getNodeFrame(nodeProps);
  // }

  // async getFile(node, callback) {
  //   // if (this.s3 && node._file?.key) {
  //   //   this.s3.getObject(
  //   //     {
  //   //       Bucket: node._file.bucket,
  //   //       Key: node._file.key,
  //   //     },
  //   //     (err, data) => {
  //   //       if (err) {
  //   //         callback(err);
  //   //       } else {
  //   //         callback(data.Body);
  //   //       }
  //   //     }
  //   //   );
  //   // }
  // }

  // getProps({ onNextFrame = undefined, extraProps = undefined } = {}) {
  //   const backgroundColor =
  //     this.boardLayout.getBackgroundColor() ||
  //     this.props.backgroundColor ||
  //     DEFAULT_BACKGROUND_COLOR;

  //   const props: DeckProps = {
  //     ...this.props,
  //     ...extraProps,
  //     _animate: false, //this.shouldAnimate,
  //     parameters: {
  //       // these are found in the mapbox overlay for the interleaved map
  //       depthMask: true,
  //       depthTest: true,
  //       blend: true,
  //       blendFunc: [
  //         GL.SRC_ALPHA,
  //         GL.ONE_MINUS_SRC_ALPHA,
  //         GL.ONE,
  //         GL.ONE_MINUS_SRC_ALPHA,
  //       ],
  //       polygonOffsetFill: true,
  //       depthFunc: GL.LEQUAL,
  //       blendEquation: GL.FUNC_ADD,
  //       clearColor: [
  //         backgroundColor[0] / 256,
  //         backgroundColor[1] / 256,
  //         backgroundColor[2] / 256,
  //         1,
  //       ],
  //     },
  //     width: this.props.width,
  //     height: this.props.height,
  //     viewState: this.getViewStates(),
  //     views: this.getViews(),
  //     onViewStateChange: this.onViewStateChange,
  //     layerFilter: this.layerFilter,
  //     getCursor: this.interactionManager.getCursor,
  //     // onInteractionStateChange:
  //     //   this.interactionManager.onInteractionStateChange,
  //     // onDragStart: this.interaction.onDragStart,
  //     // onDrag: this.interaction.onDrag,
  //     // onDragEnd: this.interaction.onDragEnd,
  //     layers: this.getLayers(),
  //     effects: [],
  //     getTooltip: null,
  //   };

  //   if (onNextFrame) {
  //     props.onAfterRender = () => this.onAfterRender(onNextFrame);
  //   } else {
  //     props.onAfterRender = () => {
  //       // const children = this.boardLayout.getVisibleNodes() || [];
  //       // for (const child of children) {
  //       //   this.updateNodeViewFromUserInteraction(child, {});
  //       // }
  //     };
  //   }

  //   return props;
  // }

  // getZoom() {
  //   const viewState = this.boardLayout.getViewState();
  //   return (viewState?.zoom || 0) as number;
  // }

  // getProjectNode() {
  //   return this.projectNode;
  // }

  // // addNodeAtPixel(node: Node, pixel: number[]) {
  // //   const coordinate = this.pixelToCartesian(pixel[0], pixel[1]);
  // //   this.addNodeAtCoordinate(node, coordinate);
  // // }

  // // addNodeAtCoordinate(node: Node, coordinate: number[]) {
  // //   if (this.boardLayout instanceof ManualLayout) {
  // //     this.boardLayout.addNodeAtCoordinate(
  // //       node,
  // //       coordinate as [number, number]
  // //     );
  // //   }
  // // }

  // setInteractionMode(mode: InteractionMode) {
  //   this.interactionMode = mode;
  // }

  // pixelToCartesian(x: number, y: number): number[] | null {
  //   try {
  //     const viewport = this.deck
  //       ?.getViewports()
  //       .find(v => v.id === this.boardLayout.getViewId());
  //     if (!viewport) {
  //       console.log('no viewport found', this.boardLayout.getViewId());
  //       return null;
  //     }
  //     return viewport.unproject([x || 0, y || 0]);
  //   } catch (e) {
  //     console.log('error pixel to cartesian', e);
  //     return null;
  //   }
  // }

  // cartesianToPixel(x: number, y: number): number[] | null {
  //   try {
  //     const viewport = this.deck
  //       ?.getViewports()
  //       .find(v => v.id === this.boardLayout.getViewId());
  //     if (!viewport) {
  //       console.log('no viewport found', this.boardLayout.getViewId());
  //       return null;
  //     }
  //     return viewport.project([x, y]);
  //   } catch (e) {
  //     return null;
  //   }
  // }

  // update(updateTriggers?: UpdateTriggers) {
  //   if (!this.deck) {
  //     console.log('no deck');
  //     return;
  //   }
  //   if (updateTriggers) {
  //     this.boardLayout.applyUpdateTriggers(updateTriggers);
  //   }
  //   // console.log('update viewport');
  //   try {
  //     this.deck.setProps(this.getProps());
  //   } catch (e) {
  //     console.log('error updating viewport', e);
  //   }
  // }

  // getNodePixels(
  //   nodeProps: NodeProps,
  //   boardViewport?: any
  // ): {
  //   viewX: number;
  //   viewY: number;
  //   width: number;
  //   height: number;
  // } {
  //   let nodeFrame = this.boardLayout.getNodeFrame(nodeProps);
  //   if (!nodeFrame) {
  //     console.warn(
  //       'no nodeFrame found in board layout - this is only for nodes within views - rare!?'
  //     );
  //     const nodeUri = Node.nodePropsToUri(nodeProps);
  //     const nodeLayout = this.nodeViewLayouts[nodeUri];
  //     if (nodeLayout) {
  //       nodeFrame = nodeLayout.getNodeFrame(nodeProps);
  //       if (!nodeFrame) {
  //         console.warn(
  //           'no nodeFrame found in nodeLayout in getNodePixels',
  //           this,
  //           nodeProps
  //         );
  //       }
  //     } else {
  //       console.warn('no nodeLayout found in getNodePixels', nodeUri);
  //     }
  //   }
  //   if (!nodeFrame) {
  //     console.warn('no nodeFrame found in getNodePixels', nodeProps);
  //     const position = nodeProps.appearance?.position || [0, 0, 0];
  //     const size = nodeProps.appearance?.size || [TILE_SIZE, TILE_SIZE];
  //     nodeFrame = {
  //       position,
  //       size,
  //     } as any;
  //   }

  //   const width = nodeFrame.size[0];
  //   const height = nodeFrame.size[1];
  //   const x = nodeFrame.position[0] - width / 2;
  //   const y = nodeFrame.position[1] + height / 2;
  //   const pixelTopLeft = boardViewport
  //     ? boardViewport.project([x, y])
  //     : this.cartesianToPixel(x, y);
  //   const pixelBottomRight = boardViewport
  //     ? boardViewport.project([x + width, y - height])
  //     : this.cartesianToPixel(x + width, y - height);

  //   if (!pixelTopLeft || !pixelBottomRight) {
  //     console.log('no pixelTopLeft or pixelBottomRight', nodeProps);
  //     return {
  //       viewX: 0,
  //       viewY: 0,
  //       width: 10,
  //       height: 10,
  //     };
  //   }

  //   return {
  //     viewX: Math.round(pixelTopLeft[0]),
  //     viewY: Math.round(pixelTopLeft[1]),
  //     width: Math.round(pixelBottomRight[0] - pixelTopLeft[0]),
  //     height: Math.round(pixelBottomRight[1] - pixelTopLeft[1]),
  //   };
  // }

  // // only for observedAt in nodes
  // setTime(timing: number) {
  //   // this.boardLayout.setTime(timing);
  //   // for (const nodeLayout of Object.values(this.nodeViewLayouts)) {
  //   //   nodeLayout.setTime(timing);
  //   // }
  //   // this.boardLayout.updatePolygons();
  //   // this.boardLayout.setLastNodePositionUpdate();
  //   // this.update();
  // }

  // setInsightCursor(timing: number | null) {
  //   // this.timeline.setTime(timing);
  //   this.boardLayout.setInsightCursor(timing >= 0 ? timing : null);
  //   // for view Layouts
  //   for (const nodeLayout of Object.values(this.nodeViewLayouts)) {
  //     nodeLayout.setInsightCursor(timing);
  //   }
  //   this.interactionManager.createInitialConnectionHandles();
  //   // this.boardLayout.updatePolygons();
  //   this.boardLayout.setLastNodePositionUpdate();
  //   this.update();
  // }

  // // getFrameOrder(nodeUri: string, timing: number) {
  // //   console.log('is this called (viewport.getFrameOrder)?');
  // //   if (this.boardLayout.nodeAnimations[nodeUri]) {
  // //     return this.boardLayout.nodeAnimations[nodeUri].getFrameOrder(timing);
  // //   } else {
  // //     console.log('todo: try to find in child view layouts');
  // //   }
  // // }

  // // RECORDING

  // setAnimationProps() {
  //   // this will loop the getProps and onNextFrame every ms of videoCapture
  //   this.deck.setProps(this.getProps({ onNextFrame: this.setAnimationProps }));
  // }

  // record({
  //   // Encoder = PreviewEncoder,
  //   formatConfigs = {},
  //   filename = undefined,
  //   timecode = { start: 0, end: 0, framerate: 30 },
  //   onStopped = undefined,
  //   onSave = undefined,
  //   onComplete = undefined,
  // }) {
  //   // todo: use the render function in deck-adapter
  //   this.shouldAnimate = true;
  //   // this.videoCapture.render({
  //   //   Encoder,
  //   //   formatConfigs,
  //   //   timecode,
  //   //   filename,
  //   //   onStop: () => this.stop({onStopped, onSave, onComplete})
  //   // });
  //   // this.enabled = true;
  //   this.seek({ timeMs: timecode.start });
  //   // the update() call but with a the onNextFrame callback that will loop through the animation
  //   this.deck.setProps(this.getProps({ onNextFrame: this.setAnimationProps }));
  // }

  // stopRecording({ onStopped, onSave, onComplete, abort }) {
  //   // this.enabled = false; // todo: enableViewportInteraction
  //   this.shouldAnimate = false;
  //   this.videoCapture.stop({ onStopped, onSave, onComplete, abort });
  // }

  // // only use in recording from this.onAfterRender or this.record
  // // otherwise set the time and
  // seek({ timeMs }) {
  //   if (timeMs || timeMs === 0) {
  //     // this.boardLayout.timeline.setTime(timeMs);
  //     // console.log('seek', timeMs);
  //     // this.boardLayout.applyUpdateTriggers({
  //     //   nodePositionChange: 1,
  //     // });
  //     // this.update();
  //   }
  //   // do some operation required during a draw call
  //   // this.portalManager.draw();
  // }

  // // after each frame push the seek to the next time and run the callback = this.setAnimationProps
  // onAfterRender(setAnimationProps, readyToCapture = true) {
  //   const areAllLayersLoaded =
  //     this.deck &&
  //     this.deck.props.layers.every(layer => layer && (layer as Layer).isLoaded);
  //   if (
  //     this.videoCapture &&
  //     this.videoCapture.isRecording() &&
  //     areAllLayersLoaded &&
  //     readyToCapture
  //   ) {
  //     this.videoCapture.capture(this.canvas, nextTimeMs => {
  //       this.seek({ timeMs: nextTimeMs });
  //       //
  //       setAnimationProps(nextTimeMs);
  //     });
  //   }
  // }

  // downloadNodes = (
  //   fileName: string,
  //   nodeProps: NodeProps[],
  //   fileType: 'json' | 'csv' = 'json',
  //   keysToInclude?: string[]
  // ) => {
  //   const filter = keysToInclude || [];
  //   const filterMap = filter.reduce((acc, key) => {
  //     acc[key] = true;
  //     return acc;
  //   }, {});
  //   const nodeData = nodeProps.map(n => {
  //     const serialized = n;
  //     const out = {};
  //     for (const key of Object.keys(serialized)) {
  //       if (filter.length && !filterMap[key]) {
  //         continue;
  //       }
  //       out[key] = serialized[key];
  //     }
  //     return out;
  //   });
  //   let saveAs;
  //   let stringData;

  //   if (fileType === 'csv') {
  //     const nodeDataSimplified = nodeData.map(n => {
  //       const out = {};
  //       for (const key of Object.keys(n)) {
  //         // if is object - simplify it
  //         if (typeof n[key] === 'object') {
  //           if (n[key] instanceof Array && n[key].length > 0) {
  //             // bucket properties can be arrays
  //             if (n[key][0].type === 'Property') {
  //               out[key] = n[key]
  //                 .map(property => {
  //                   return property.value;
  //                 })
  //                 .join(' ');
  //             }
  //             if (n[key][0].type === 'Relation') {
  //               out[key] = n[key]
  //                 .map(property => {
  //                   return property.object;
  //                 })
  //                 .join(' ');
  //             } else {
  //               out[key] = n[key].join(' ');
  //             }
  //           } else if (n[key].type === 'Property') {
  //             out[key] = n[key].value;
  //           }
  //         } else {
  //           out[key] = n[key] || ' ';
  //         }
  //       }
  //       return out;
  //     });
  //     saveAs = `${fileName}.csv`;
  //     stringData = convertArrayOfObjectsToCSV(
  //       nodeDataSimplified,
  //       keysToInclude
  //     );
  //   } else {
  //     saveAs = `${fileName}.json`;
  //     stringData = JSON.stringify(nodeData);
  //   }
  //   let element = document.createElement('a');
  //   element.setAttribute(
  //     'href',
  //     'data:text/plain;charset=utf-8,' + encodeURIComponent(stringData)
  //   );
  //   element.setAttribute('download', saveAs);
  //   element.style.display = 'none';
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };

  // // hasModelMatrix(node: Node, insight: BucketInsight) {
  // //   let values = [];
  // //   const insightColumns = insight.sanddanceInsight?.columns;
  // //   if (!insightColumns) {
  // //     console.warn('no insight columns');
  // //     return values;
  // //   }
  // //   if (node.isBucket()) {
  // //     const dataSourceEdges = node.getInsightFromNodeEdges();
  // //     const nodeMap: NodeMap = {};
  // //     for (const edge of dataSourceEdges) {
  // //       const dataSourceNode = edge.target;
  // //       dataSourceNode.getCubeNodes(['volume'], nodeMap);
  // //     }
  // //     return Object.values(nodeMap).find(n => n._props.modelMatrix);
  // //   }
  // // }

  // getNodeInteractionState(nodeUri: string) {
  //   return this.interactionManager.nodeInteractionStates[nodeUri] || 'default';
  // }

  // setNodeInteractionState(nodeUri: string, state: NodeInteractionState) {
  //   this.interactionManager.nodeInteractionStates[nodeUri] = state;
  // }

  // getEdgeInteractionState(edgeId: string) {
  //   return this.interactionManager.edgeInteractionStates[edgeId] || 'default';
  // }

  // setEdgeInteractionState(edgeId: string, state: EdgeInteractionState) {
  //   this.interactionManager.edgeInteractionStates[edgeId] = state;
  // }

  // // 3. The nodeProps can be sent in, as well as what should be triggered
  // // applyNodeProps(nodeProps?: NodeProps[], updateTriggers?: UpdateTriggers) {
  // //   console.log('IS THIS CALLED?');
  // //   // update the cubes if parametricInput has changed -> do this separately instead
  // //   // this.portal.applyNodeProps(nodeProps);

  // //   // at this point the nodeProps has changed and should preplace state in the layout
  // //   this.boardLayout.applyNodeProps(nodeProps);

  // //   // trigger only if changed
  // //   this.boardLayout.applyUpdateTriggers(updateTriggers);

  // //   this.update();
  // // }

  // // GET DEFAULT NODE SIZE
  // getDefaultNodeSize() {
  //   return this.boardLayout.getDefaultNodeSize();
  // }

  // getFullTriggers() {
  //   return {
  //     nodePositionChange: Date.now(),
  //     nodeSizeChange: Date.now(),
  //     nodeExtrusionChange: Date.now(),
  //     nodeScaleChange: Date.now(),
  //     nodeRotateChange: Date.now(),
  //     nodeFillColorChange: Date.now(),
  //     nodeStrokeColorChange: Date.now(),
  //   };
  // }

  // // UPDATE THE VISUAL NODES
  // // setNodePropsMap({
  // //   changedNodeProps,
  // //   updateTriggers,
  // // }: {
  // //   changedNodeProps: NodeProps[];
  // //   updateTriggers?: UpdateTriggers;
  // // }) {
  // //   this.applyNodeProps(changedNodeProps, updateTriggers);
  // // }

  // // initNodeProps(nodePropsList: NodeProps[], edgePropsList: EdgeProps[]) {
  // //   // first: need to add parent groups for map and timeline
  // //   const mapLayouts = new Map<string, NodeProps[]>();
  // //   const timelineLayouts = new Map<string, NodeProps[]>();
  // //   const boardLayoutNodes: NodeProps[] = [];
  // //   for (const n of nodePropsList) {
  // //     const nodeUri = Node.nodePropsToUri(n);
  // //     if (n.layoutKey === 'map') {
  // //       if (!mapLayouts.has(nodeUri)) {
  // //         mapLayouts.set(nodeUri, []);
  // //       }
  // //     } else if (n.layoutKey === 'timeline') {
  // //       if (!timelineLayouts.has(nodeUri)) {
  // //         timelineLayouts.set(nodeUri, []);
  // //       }
  // //     }
  // //   }

  // //   // then: add nodeProps to the correct layout
  // //   for (const n of nodePropsList) {
  // //     if (mapLayouts[n.parentId]) {
  // //       mapLayouts[n.parentId].push(n);
  // //     } else if (timelineLayouts[n.parentId]) {
  // //       timelineLayouts[n.parentId].push(n);
  // //     } else {
  // //       boardLayoutNodes.push(n);
  // //     }
  // //   }

  // //   // init the board layout nodes
  // //   // this.boardLayout.initNodeProps(boardLayoutNodes, []);

  // //   // todo: create the map and timeline layouts
  // // }

  // updatePolygons() {
  //   // this.boardLayout.updatePolygons();
  //   this.boardLayout.setLastNodePositionUpdate();
  //   this.update();
  // }

  // nodeHasSeparateLayoutParent(child: NodeProps, allNodes: NodePropsMap) {
  //   const versionUri = `${child.parentId}/latest`;
  //   const parent = allNodes.get(versionUri);
  //   if (!parent) {
  //     return false;
  //   }
  //   return Boolean(availableLayouts[parent.layoutKey]);
  // }

  // // some layouts must be ran before this step (grid, sankey, supplychain, map)
  // // if the size change of a node with cubes/vega layout this needs to update after on receiver computer
  // updateNodeProps({
  //   nodes, // use for layout "latest" nodes
  //   changedNodeProps,
  //   updateTriggers, // Todo: use this to decide if rerender!
  // }: {
  //   nodes: NodePropsMap;
  //   changedNodeProps: NodeProps[];
  //   updateTriggers?: UpdateTriggers;
  // }) {
  //   // keep ref in viewport
  //   this.nodes = nodes;

  //   console.log('updateNodeProps', changedNodeProps, updateTriggers);

  //   // todo: reduce function..
  //   const childNodesWithSeparateLayout = new Map(
  //     [...nodes].filter(([key, value]) =>
  //       this.nodeHasSeparateLayoutParent(value, nodes)
  //     )
  //   );
  //   const childNodesForBoardLayout = new Map(
  //     [...nodes].filter(
  //       ([key, value]) => !this.nodeHasSeparateLayoutParent(value, nodes)
  //     )
  //   );

  //   // First: add the nodes without parentId to the boardLayout (this sets the overall layout nodes as containers to children)
  //   const nodesWithoutParent = changedNodeProps.filter(n => !n.parentId);
  //   if (nodesWithoutParent.length > 0) {
  //     this.boardLayout.applyNodeProps(
  //       childNodesForBoardLayout,
  //       nodesWithoutParent
  //     );
  //   }

  //   // Todo: do this as an optimization later
  //   // delete all layouts if layout is changed
  //   // for (const n of changedNodeProps) {
  //   //   if (n.layoutKey) {
  //   //     const nodeUri = Node.nodePropsToUri(n);
  //   //     delete this.nodeViewLayouts[nodeUri];
  //   //   }
  //   // }

  //   // Then: for the nodes with view, create nodeViewLayouts
  //   const nodesWithView = changedNodeProps.filter(
  //     n => n.layoutKey && n.layoutKey !== 'node'
  //   );

  //   // create all nodeViewers here (chart viewers are updated during updateChart later)
  //   for (const n of nodesWithView) {
  //     const nodeUri = Node.nodePropsToUri(n);
  //     if (!this.nodeViewLayouts[nodeUri]) {
  //       const originalNode = nodes.get(nodeUri);
  //       const originalNodeWithChange = originalNode
  //         ? mergeNodeProps([originalNode, n])[0]
  //         : n;
  //       this.createNodeViewer(originalNodeWithChange);
  //     } else if (
  //       this.nodeViewLayouts[nodeUri].parentNodeProps.layoutKey !== n.layoutKey
  //     ) {
  //       // recreate the nodeViewer with a different layout
  //       const originalNode = nodes.get(nodeUri);
  //       const originalNodeWithChange = originalNode
  //         ? mergeNodeProps([originalNode, n])[0]
  //         : n;
  //       this.createNodeViewer(originalNodeWithChange);
  //     }
  //   }

  //   // for nodes with parent - add them to either the boardLayout or the nodeViewLayouts
  //   // todo: if node has parentId, find recusively the boardLayout.nodeId or the map or timeline nodeIds

  //   const nodesWithParentOnBoardLayout = changedNodeProps.filter(
  //     n => n.parentId && !this.nodeViewLayouts[n.parentId]
  //   );
  //   // these nodes have parent and no node viewer and should be added to the boardLayout
  //   if (nodesWithParentOnBoardLayout.length > 0) {
  //     this.boardLayout.applyNodeProps(
  //       childNodesForBoardLayout,
  //       nodesWithParentOnBoardLayout
  //     );
  //   }

  //   const nodesWithNodeLayouts = changedNodeProps.filter(
  //     n => n.parentId && this.nodeViewLayouts[n.parentId]
  //   );
  //   // these nodes have parent and node viewer and should be added to the respective nodeViewLayouts
  //   for (const n of nodesWithNodeLayouts) {
  //     const parentLayout = this.nodeViewLayouts[n.parentId];
  //     const childNodesForNodeViewer = new Map(
  //       [...childNodesWithSeparateLayout].filter(
  //         ([key, value]) => value.parentId === n.parentId
  //       )
  //     );
  //     parentLayout.applyNodeProps(childNodesForNodeViewer, [n]);
  //   }

  //   // update the viewers depending on parent changes or if layoutKey was changed
  //   for (const n of changedNodeProps) {
  //     const versionUri = Node.nodePropsToVersionUri(n);
  //     const nodeId = Node.nodePropsToUri(n);

  //     const parentLayout = this.nodeViewLayouts[nodeId];
  //     if (parentLayout) {
  //       const originalNode = nodes.get(versionUri);
  //       if (!originalNode) {
  //         console.log(
  //           'original node not found',
  //           originalNode,
  //           versionUri,
  //           n,
  //           nodes
  //         );
  //         continue;
  //       }

  //       const layoutKey = n.layoutKey || originalNode.layoutKey;

  //       // PARAMETRIC TO SEGMENTS !!!

  //       // if parametric settings or properties has change - update cube cache
  //       // NOTE: if several nodeProps changes and updateTriggers is set, all of the nodeProps will be updated
  //       // however - normally only the nodeProps that has changed will be sent in (or all if init)
  //       if (
  //         layoutKey === 'cubes' ||
  //         (layoutKey === 'vega' &&
  //           (updateTriggers?.parametricChange ||
  //             updateTriggers?.propertyChange))
  //       ) {
  //         let segments = getSegmentsFromNodeProps(originalNode);
  //         if (layoutKey === 'cubes' && segments.length === 0) {
  //           // ONLY FOR CUBES = if no explicit settings, represent the node itself as one cube = amount 1
  //           segments = [
  //             {
  //               versionUri: Node.nodePropsToVersionUri(n),
  //               amount: 1,
  //               component: n.type,
  //             } as ParametricCube,
  //           ];
  //         }
  //         this.cubeCache.set(Node.nodePropsToVersionUri(n), segments);
  //         console.log('set segments', segments);
  //         parentLayout.applyNodeProps([], [originalNode], segments);
  //       } else if (updateTriggers?.chartChange) {
  //         let cachedSegments = this.cubeCache.get(versionUri);
  //         if (cachedSegments) {
  //           parentLayout.applyNodeProps([], [originalNode], cachedSegments);
  //         }
  //       } else {
  //         // just change layout, not regenerate segment data
  //         let cachedSegments = this.cubeCache.get(versionUri);
  //         if (cachedSegments) {
  //           parentLayout.applyNodeProps([], [originalNode], cachedSegments);
  //         }
  //       }
  //     }
  //   }

  //   this.boardLayout.applyUpdateTriggers(updateTriggers);

  //   if (Object.keys(updateTriggers || {}).length > 0) {
  //     this.update();
  //   }
  // }

  // getCubeCache(nodeProps: NodeProps) {}

  // // make sure nodeProps is complete node, not only the change
  // createNodeViewer(nodeProps: NodeProps) {
  //   if (!availableLayouts[nodeProps.layoutKey]) {
  //     console.warn('no layout for', nodeProps.layoutKey);
  //     return;
  //   }
  //   const nodeUri = Node.nodePropsToUri(nodeProps);
  //   const nodeViewer = new availableLayouts[nodeProps.layoutKey]({
  //     parentNodeProps: nodeProps,
  //     timeline: this.boardLayout.timeline,
  //     // deck: this.deck,
  //   } as LayoutProps);
  //   if (nodeProps.layoutKey === 'map') {
  //     const nodePixels = this.getNodePixels(nodeProps);
  //     nodeViewer.setCameraFrame({
  //       // latitude: nodeProps.geometry?.coordinates[1], // figure out how to do with coordinate since geojson might be a polygon
  //       ...nodePixels,
  //       // ...nodeProps.appearance,
  //       minZoom: nodeProps.appearance?.minZoom || 0,
  //       maxZoom: nodeProps.appearance?.maxZoom || 20,
  //       zoom: nodeProps.appearance?.zoom || 0,
  //       longitude: nodeProps.appearance?.longitude || 0.1,
  //       latitude: nodeProps.appearance?.latitude || 0.1,
  //       bearing: nodeProps.appearance?.bearing || 0,
  //       pitch: nodeProps.appearance?.pitch || 0,
  //     });
  //   } else if (nodeProps.layoutKey === 'gltf') {
  //     console.log('start gltf', nodeProps);
  //     const nodePixels = this.getNodePixels(nodeProps);
  //     nodeViewer.setCameraFrame({
  //       // latitude: nodeProps.geometry?.coordinates[1], // figure out how to do with coordinate since geojson might be a polygon
  //       ...nodePixels,
  //       // ...n.appearance,
  //       minZoom: nodeProps.appearance?.minZoom || -10,
  //       maxZoom: nodeProps.appearance?.maxZoom || 20,
  //       zoom: nodeProps.appearance?.zoom || 0,
  //       rotationX: nodeProps.appearance?.rotationX || 0,
  //       rotationOrbit: nodeProps.appearance?.rotationOrbit || 0,
  //       target: nodeProps.appearance?.target || [0, 0, 0],
  //     });
  //   }
  //   this.nodeViewLayouts[nodeUri] = nodeViewer;
  // }

  // // this is only for the children in boardLayout (sankey, supply chain, grid, etc)
  // // difference between this kind of layout and parametric charts is that the appearance
  // // goes back into db - so users can adjust position and size after layouted
  // // the parametric charts are 100% generated on the client side (on the fly)
  // // updateNodeLayouts({
  // //   parentNodeProps,
  // //   allNodeProps,
  // // }: {
  // //   parentNodeProps: NodeProps;
  // //   allNodeProps: NodePropsMap;
  // // }) {
  // //   const childPropsMap: NodePropsMap = new Map();
  // //   const children = getChildNodes(
  // //     allNodeProps,
  // //     Node.nodePropsToUri(parentNodeProps)
  // //   );
  // //   getSupplyChainLayout(parentNodeProps, children);
  // // }

  // // the nodeProps.parametric has changed and the cubes are unique per versionUri
  // // so they are cached and used in layout run
  // // obviously this must be done before updateCharts
  // // updateParametric({ changedNodeProps }: { changedNodeProps: NodeProps[] }) {
  // //   for (const nodeProps of changedNodeProps) {
  // //     // if (
  // //     //   (nodeProps.parametric && nodeProps.layoutKey === 'cubes') ||
  // //     //   nodeProps.layoutKey === 'vega'
  // //     // ) {
  // //     const cubes = getCubesFromNodeProps(nodeProps);
  // //     this.cubeCache.set(Node.nodePropsToVersionUri(nodeProps), cubes);
  // //     // } else if (nodeProps.properties) {
  // //     //   const cubes = getCubesFromNodeProps(nodeProps);
  // //     //   this.cubeCache.set(Node.nodePropsToVersionUri(nodeProps), cubes);
  // //     // }
  // //   }
  // // }

  // // must be updated if chart type / settings or size has changed
  // // explain why we need to keep instances of the chart layout? -> the cubes are cached on this.cubeCache
  // // so why not do one-shot like supply-chain etc
  // // updateCharts({
  // //   changedNodeProps,
  // //   allNodeProps, // for deriving data source
  // // }: {
  // //   changedNodeProps: NodeProps[];
  // //   allNodeProps: NodePropsMap;
  // // }) {
  // //   for (const node of changedNodeProps) {
  // //     if (node.layoutKey !== 'cubes' && node.layoutKey !== 'vega') {
  // //       continue;
  // //     }
  // //     const nodeUri = Node.nodePropsToUri(node);
  // //     const versionUri = Node.nodePropsToVersionUri(node);

  // //     let nodeLayout = this.nodeViewLayouts[nodeUri];
  // //     if (!nodeLayout && availableLayouts[node.layoutKey]) {
  // //       nodeLayout = new availableLayouts[node.layoutKey]({
  // //         parentNodeProps: node,
  // //         timeline: this.boardLayout.timeline,
  // //       } as LayoutProps);
  // //       let cachedSegments = this.cubeCache.get(versionUri);
  // //       if (!cachedSegments) {
  // //         this.updateParametric({ changedNodeProps: [node] });
  // //         cachedSegments = this.cubeCache.get(versionUri);
  // //       }
  // //       if (cachedSegments) {
  // //         console.log(
  // //           'node viewer was created from chart!',
  // //           nodeUri,
  // //           cachedSegments
  // //         );
  // //         nodeLayout.applyNodeProps([], [node], cachedSegments);
  // //         this.nodeViewLayouts[nodeUri] = nodeLayout;
  // //       }
  // //     } else if (nodeLayout) {
  // //       let cachedSegments = this.cubeCache.get(versionUri);
  // //       if (!cachedSegments) {
  // //         this.updateParametric({ changedNodeProps: [node] });
  // //         cachedSegments = this.cubeCache.get(versionUri);
  // //       }
  // //       if (cachedSegments) {
  // //         nodeLayout.applyNodeProps([], [node], cachedSegments);
  // //       }
  // //     } else {
  // //       console.log('no layout for', nodeUri);
  // //     }
  // //     // if (node.layoutKey === 'cubes') {
  // //     //   let cubesLayout = this.nodeViewLayouts[nodeUri];
  // //     //   if (!cubesLayout) {
  // //     //     const nodeLayout = new availableLayouts[nodeLayoutKey]({
  // //     //       nodeProps,
  // //     //       timeline: this.boardLayout.timeline,
  // //     //       viewportProps: this.props,
  // //     //     } as LayoutProps);
  // //     //     this.createNodeLayout(node, 'cubes');
  // //     //   }
  // //     // }
  // //   }
  // // }

  // // cubes need to be cached per insight when insight settings are changed
  // // NOTE: these cubes are not the same as the cubes in animation! these are precalculated cube data that goes into layout run
  // // updateCubeCache(nodeProps: NodeProps) {
  // //   const cubes = getCubesFromNodeProps(nodeProps);
  // //   // todo: for each cube, push into nodeFrame on nodeProps.versionUri + cube.ordinal
  // //   this.cubeCache.set(Node.nodePropsToVersionUri(nodeProps), cubes);
  // //   // console.log(
  // //   //   'added to cube cache',
  // //   //   Node.nodePropsToVersionUri(nodeProps),
  // //   //   cubes
  // //   // );
  // // }

  // updateEdgeProps({ changedEdgeProps }) {
  //   // update the layout
  //   this.boardLayout.applyEdgeProps(changedEdgeProps);
  //   // update the layers
  //   this.update();
  // }

  // // setViewActive(nodeProps: NodeProps, setActive: boolean) {
  // //   const nodeUri = Node.nodePropsToUri(nodeProps);
  // //   if (this.nodeViewLayouts[nodeUri]) {
  // //     const nodePixels = this.getNodePixels(nodeProps);
  // //     // this.nodeViewLayouts[nodeUri].setCameraFrame({
  // //     //   // latitude: nodeProps.geometry?.coordinates[1], // figure out how to do with coordinate since geojson might be a polygon
  // //     //   ...nodePixels,
  // //     //   zoom: nodeProps.appearance?.zoom || 0,
  // //     //   longitude: nodeProps.appearance?.longitude,
  // //     //   latitude: nodeProps.appearance?.latitude,
  // //     //   bearing: nodeProps.appearance?.bearing || 0,
  // //     //   pitch: nodeProps.appearance?.pitch || 0,
  // //     // });
  // //     // now the view will be rendered
  // //     this.nodeViewLayouts[nodeUri].setViewActive(setActive);
  // //   }
  // // }

  // // MY OWN SELECTION
  // updateMySelection(versionUris: string[]) {
  //   const nodeUriToColors: Record<string, [number, number, number]> =
  //     versionUris.reduce((acc, nodeUri, i) => {
  //       acc[nodeUri] = [0, 0, 0];
  //       return acc;
  //     }, {});
  //   console.log('update selection', nodeUriToColors);
  //   this.interactionManager.setNodeSelectionColors(nodeUriToColors);
  //   this.boardLayout.setLastNodeStyleUpdate();
  //   // for (const versionUri of versionUris) {
  //   //   const nodeUri = Node.versionUriToNodeUri(versionUri);
  //   //   if (
  //   //     this.nodeViewLayouts[nodeUri] &&
  //   //     this.nodeViewLayouts[nodeUri].viewIsActive(this.presenting)
  //   //   ) {
  //   //     this.nodeViewLayouts[nodeUri].setViewActive(false);
  //   //   }
  //   // }
  //   // set the selection on active layout

  //   // this.boardLayout.setSelection(versionUris);
  //   // // trigger update in rendered layers
  //   // this.boardLayout.updateMySelectionStrokeColors(versionUris);
  //   // // update the selected nodes box layer
  //   this.updateSelectedNodeBox(versionUris);
  //   // // trigger update in resize handles (uses layout.selectedVersionUris[0] to create handles)
  //   // this.boardLayout.setLastResizeHandlePositionUpdate();
  //   this.update();
  // }

  // updateMyEdgeSelection(edgeIds: string[]) {
  //   const edgeIdToColors: Record<string, [number, number, number]> =
  //     edgeIds.reduce((acc, edgeId, i) => {
  //       acc[edgeId] = [0, 0, 0];
  //       return acc;
  //     }, {});
  //   this.interactionManager.setEdgeSelectionColors(edgeIdToColors);
  //   this.boardLayout.updateTriggers.edgeFillColorChange = Date.now();
  //   this.update();
  // }

  // // SELECTION BOX AROUND THE SELECTED NODES
  // updateSelectedNodeBox(versionUris: string[]) {
  //   // this is needed to rerender the selection extent from the viewport
  //   this.selectedVersionUriCache = versionUris;

  //   // const coordinateExtent =
  //   //   this.boardLayout.updateSelectedNodesBox(
  //   //     versionUris && versionUris.length > 1 ? versionUris : []
  //   //   ) || null;
  //   const coordinateExtent =
  //     this.boardLayout.updateSelectedNodesBox(versionUris) || null;
  //   if (coordinateExtent) {
  //     const min = this.cartesianToPixel(
  //       coordinateExtent[0],
  //       coordinateExtent[1]
  //     );
  //     const max = this.cartesianToPixel(
  //       coordinateExtent[2],
  //       coordinateExtent[3]
  //     );

  //     if (min && max) {
  //       const pixelExtent = [...min, ...max];
  //       this.emit('viewport:selectionextent', { pixelExtent });
  //       return;
  //     }
  //   }
  //   this.emit('viewport:selectionextent', { pixelExtent: null });
  // }

  // setResizeNode(nodeProps: NodeProps | null) {
  //   this.interactionManager.setResizeNode(nodeProps);
  //   this.update();
  // }

  // setConnectionNode(nodeProps: NodeProps | null) {
  //   this.interactionManager.setConnectionNode(nodeProps);
  //   this.update();
  // }

  // // SELECTION COLORS OF THE USERS
  // setSelectionColors(
  //   nodeUriToColors: Record<string, [number, number, number]>
  // ) {
  //   this.interactionManager.setNodeSelectionColors(nodeUriToColors);
  // }

  // // todo: this is duplicated
  // createEdgeId(sourceUri: string, relationKey: string, targetUri: string) {
  //   return getEdgeId({ sourceUri, relationKey, targetUri });
  // }

  // // used to pick only the board layout nodes and edges (context menu)
  // pickBoardLayout(pixel: Pixel, dim3: boolean = false): any | null {
  //   try {
  //     const [x, y] = pixel;
  //     const pickInfo = this.deck.pickObject({
  //       x,
  //       y,
  //       // radius: 30,
  //       layerIds: [
  //         this.boardLayout.getLayerId('node-icon-layer'),
  //         this.boardLayout.getLayerId(NODE_POLYGON_LAYER_KEY),
  //         this.boardLayout.getLayerId(EDGE_NODE_LAYER_KEY),
  //         this.boardLayout.getLayerId(BEZIER_EDGE_LABEL_LAYER_ID),
  //         this.boardLayout.getLayerId(STRAIGHT_EDGE_LABEL_LAYER_ID),
  //         this.boardLayout.getLayerId('edge-bezier-layer'),
  //         this.boardLayout.getLayerId('edge-line-layer'),
  //       ],
  //       unproject3D: dim3,
  //     });
  //     return pickInfo?.object || null;
  //   } catch (e) {
  //     return null;
  //   }
  // }

  // // PICKING ITEM ON THE CANVAS
  // pick(pixel: Pixel, dim3: boolean = false): any | null {
  //   try {
  //     const [x, y] = pixel;

  //     // the active node viewer layers dont need to be picked as they have it's own view that is interactive
  //     // only the node image is picked on top
  //     // const activeNodeViewerLayerIds = Object.values(this.nodeViewLayouts).map(
  //     //   nodeLayout => nodeLayout.getLayerId('context-grid-layer-0')
  //     // );
  //     // console.log('activeNodeViewerLayerIds', activeNodeViewerLayerIds);
  //     // todo: create priority for picking different layers and return what is picked
  //     // handles, text, image, node, edge
  //     const pickInfo = this.deck.pickObject({
  //       x,
  //       y,
  //       // radius: 30,
  //       // layerIds: [
  //       //   this.boardLayout.getLayerId(RESIZE_HANDLE_LAYER_KEY),
  //       //   this.boardLayout.getLayerId(CONNECTION_HANDLE_LAYER_KEY),
  //       //   this.boardLayout.getLayerId('node-icon-layer'),
  //       //   this.boardLayout.getLayerId(NODE_POLYGON_LAYER_KEY),
  //       //   this.boardLayout.getLayerId(EDGE_NODE_LAYER_KEY),
  //       //   this.boardLayout.getLayerId(BEZIER_EDGE_LABEL_LAYER_ID),
  //       //   this.boardLayout.getLayerId(STRAIGHT_EDGE_LABEL_LAYER_ID),
  //       //   this.boardLayout.getLayerId('edge-bezier-layer'),
  //       //   this.boardLayout.getLayerId('edge-line-layer'),
  //       //   this.boardLayout.getLayerId(
  //       //     `${this.boardLayout.getViewId()}-vega-cubes`
  //       //   ),
  //       // ...activeNodeViewerLayerIds,
  //       // ],
  //       unproject3D: dim3,
  //     });

  //     // const cubeLayerId = this.portal.getLayerId(`${this.portal.node.id}-vega-cubes`)
  //     return pickInfo?.object || null;
  //   } catch (e) {
  //     return null;
  //   }
  // }

  // // SET RESIZE FOR SELECTED NODE
  // setResizeHandles(versionUris: string[]) {
  //   // this.boardLayout.setResizeHandles(
  //   //   versionUris.length > 0 ? versionUris[0] : undefined
  //   // );
  // }

  // // setNodeName(versionUri: string, name: string) {
  // //   this.boardLayout.setNodeName(versionUri, name);
  // // }

  // setNodeType(versionUri: string, type: string) {
  //   // this.boardLayout.setNodeType(versionUri, type);
  // }

  // runAutoLayout(
  //   nodeProps: NodeProps,
  //   overrideChildren?: NodeProps[]
  // ): NodeProps[] {
  //   // take all children recursively of the nodeProps node and apply layout
  //   // input into layout -> nodes, output -> nodes with appearance changed
  //   // add the result to the layout at that timing -> applyNodeProps
  //   return [];
  //   // const supplyChainLayout = new SupplyChainLayout({
  //   //   nodeProps,
  //   //   timeline: this.boardLayout.timeline,
  //   // });
  //   // const versionUri = Node.nodePropsToVersionUri(nodeProps);
  //   // const children =
  //   //   overrideChildren || this.boardLayout.getChildNodes(versionUri);
  //   // supplyChainLayout.applyNodeProps([nodeProps, ...children]);
  //   // supplyChainLayout.run(nodeProps);
  //   // const updatedNodeProps = [nodeProps, ...children].map(child => {
  //   //   const frame = supplyChainLayout.getNodeFrame(child);
  //   //   if (
  //   //     !frame ||
  //   //     !frame.position ||
  //   //     !frame.position[0] ||
  //   //     !frame.position[1]
  //   //   ) {
  //   //     console.log('no position - add one', child);
  //   //     return child;
  //   //   }
  //   //   child.appearance.position = frame.position;
  //   //   return child;
  //   // });
  //   // // do not apply nodeProps to layout, instead return the updated nodeProps and update for all users - including the user that triggered the layout
  //   // // if (!overrideChildren) {
  //   // //   this.boardLayout.applyNodeProps(updatedNodeProps);
  //   // //   this.update();
  //   // // }
  //   // return updatedNodeProps;
  //   // this.boardLayout.runAutoLayout(nodeProps, layoutKey);
  // }

  // updateNodesFromNodeProps(changes: NodeProps[]) {
  //   for (const change of changes) {
  //     const node = change.synthetic
  //       ? new SyntheticNode(change)
  //       : new Node(change);
  //     this.projectNode.addNode(node);
  //   }
  // }
}
