// src/constants.ts
var RELATION_KEY_HAS_CHILD = "hasChild";
var RELATION_KEY_HAS_PARENT = "hasParent";
var RELATION_KEY_HAS_TYPE = "hasType";
var RELATION_KEY_HAS_PROPERTY = "hasProperty";
var RELATION_KEY_PROPERTY_OF = "propertyOf";
var RELATION_KEY_CONTAINED_IN = "containedIn";
var RELATION_KEY_CONTAINS = "contains";
var RELATION_KEY_COMPOSES = "composes";
var RELATION_KEY_COMPOSED_BY = "composedBy";
var RELATION_KEY_DOWNSTREAM = "downstream";
var RELATION_KEY_UPSTREAM = "upstream";
var RELATION_KEY_DOWNSTREAM_DEMAND = "downstreamDemand";
var RELATION_KEY_UPSTREAM_DEMAND = "upstreamDemand";
var RELATION_KEY_DOWNSTREAM_SUPPLY = "downstreamSupply";
var RELATION_KEY_UPSTREAM_SUPPLY = "upstreamSupply";
var RELATION_KEY_TRACE = "trace";
var RELATION_KEY_TRACK = "track";
var RELATION_KEY_HAS_DATA_SOURCE = "hasDataSource";
var RELATION_KEY_DATA_SOURCE_TO = "dataSourceTo";
var RELATION_KEY_CARRIER_SOURCE = "_source";
var RELATION_KEY_CARRIER_TARGET = "_target";
var NODE_TYPE_DEFAULT = "Node";
var NODE_TYPE_SYNTHETIC = "SyntheticNode";
var NODE_TYPE_WORKSPACE = "Workspace";
var NODE_TYPE_PROJECT = "Project";
var NODE_TYPE_SCENARIO = "Scenario";
var NODE_TYPE_CASE = "Case";
var NODE_TYPE_THING = "Thing";
var NODE_TYPE_INSIGHT_COLUMN = "InsightColumn";
var NODE_TYPE_CELL = "Cell";
var NODE_TYPE_BASE = "Base";
var NODE_TYPE_BUCKET = "Bucket";
var NODE_TYPE_TYPE = "Type";
var NODE_TYPE_PROPERTY = "Property";
var NODE_TYPE_RELATION = "Relation";
var NODE_TYPE_INDICATOR = "Indicator";
var NODE_TYPE_CARRIER = "Carrier";
var NODE_TYPE_CATALOG = "Catalog";
var NODE_TYPE_DATA_SOURCE = "DataSource";
var TYPE_FILE = "File";
var TYPE_IFC_FILE = "IfcFile";
var TYPE_EXCEL_FILE = "ExcelFile";
var TYPE_GEOJSON_FILE = "GeoJsonFile";
var BUILT_IN_NODE_TYPES = [
  NODE_TYPE_PROJECT,
  NODE_TYPE_SCENARIO,
  NODE_TYPE_BASE,
  NODE_TYPE_BUCKET,
  NODE_TYPE_TYPE,
  NODE_TYPE_PROPERTY,
  NODE_TYPE_RELATION,
  NODE_TYPE_INDICATOR,
  NODE_TYPE_CARRIER,
  NODE_TYPE_CATALOG,
  NODE_TYPE_DATA_SOURCE
];
var DEFAULT_NAMESPACE = "basebucket.com";
var DEFAULT_PROJECT_ID = "default";
var PROPERTY_KEY_CATALOG = "_catalog";
var PROPERTY_EVENT_ARRIVED_AT = "arrivedAt";
var PROPERTY_EVENT_SHIPPED_AT = "shippedAt";
var PROPERTY_LOCATION = "location";
var TEST_URI_1 = `${DEFAULT_NAMESPACE}/${DEFAULT_PROJECT_ID}/${NODE_TYPE_DEFAULT}/test-1`;
var TEST_URI_2 = `${DEFAULT_NAMESPACE}/${DEFAULT_PROJECT_ID}/${NODE_TYPE_DEFAULT}/test-2`;
var TYPE_FOREST = "Forest";
var TYPE_FOREST_STAND = "ForestStand";
var TYPE_FOREST_LOADING_ZONE = "ForestLoadingZone";
var TYPE_FOREST_HARVESTING = "ForestHarvesting";
var TYPE_FOREST_PRODUCT_BATCH = "ForestProductBatch";
var TYPE_FOREST_TREE_GROUP = "ForestTreeGroup";
var TYPE_FOREST_CELL = "ForestCell";
var TYPE_FOREST_TREE_CELL = "ForestTreeCell";
var TYPE_FOREST_TREE = "ForestTree";
var TYPE_HARVESTING_VIRKESORDER = "HarvestingVirkesorder";
var TYPE_HARVESTING_TRUNK = "HarvestingTrunk";
var TYPE_HARVESTING_LOG = "HarvestingLog";
var TYPE_HARVESTING_FIREWOOD = "HarvestingFirewood";
var TYPE_HARVESTING_BRANCHES = "HarvestingBranchesAndTops";
var TYPE_SAWMILL = "Sawmill";
var TYPE_SAWMILL_STATION = "SawmillStation";
var TYPE_SAWMILL_PRODUCT_BATCH = "SawmillProductBatch";
var TYPE_GLULAM_FACTORY_PRODUCT_BATCH = "GlulamFactoryProductBatch";
var TYPE_FACTORY = "Factory";
var TYPE_GLULAM_FACTORY = "GlulamFactory";
var TYPE_GLULAM_FACTORY_STATION = "GlulamFactoryStation";
var TYPE_GLULAM_BATCH = "GlulamBatch";
var TYPE_BOARD_BATCH = "BoardBatch";
var TYPE_LOG_BATCH = "LogBatch";
var TYPE_SUPPLY_BATCH = "SupplyBatch";
var TYPE_QUANTIFICATION_BATCH = "QuantificationBatch";
var TYPE_BATCH = "Batch";
var TYPE_BATCH_ITEM = "BatchItem";
var TYPE_COMPONENT = "Component";
var TYPE_BUILDING = "Building";
var TYPE_BUILDING_SYSTEM = "BuildingSystem";
var TYPE_BUILDING_SYSTEM_STRUCTURE = "BuildingSystemStructure";
var TYPE_BUILDING_SYSTEM_FACADE = "BuildingSystemFacade";
var TYPE_BUILDING_SYSTEM_ROOF = "BuildingSystemRoof";
var TYPE_BUILDING_SYSTEM_INTERIOR = "BuildingSystemInterior";
var TYPE_BUILDING_COMPONENT = "BuildingComponent";
var TYPE_BUILDING_SUB_COMPONENT = "BuildingSubComponent";
var TYPE_CATALOG_MATERIAL = "Material";
var TYPE_CATALOG_RAW_MATERIAL = "RawMaterial";
var TYPE_CATALOG_PROCESSED_MATERIAL = "ProcessedMaterial";
var TYPE_CATALOG_PRODUCT = "Product";
var TYPE_CATALOG_PRODUCT_EPD = "EPD";
var TYPE_CATALOG_PRODUCT_GLULAM = "Glulam";
var TYPE_BOM = "BillOfMaterial";
var CATALOG_SPRUCE = "Spruce";
var CATALOG_PINE = "Pine";
var CATALOG_BIRCH = "Birch";
var CATALOG_SPRUCE_LOGS = "SpruceLogs";
var CATALOG_PINE_LOGS = "PineLogs";
var CATALOG_BIRCH_LOGS = "BirchLogs";
var CATALOG_SMALL_DIAMETER_SPRUCE_LOGS = "SmallDiameterSpruceLogs";
var CATALOG_SMALL_DIAMETER_PINE_LOGS = "SmallDiameterPineLogs";
var CATALOG_GLULAM = "Glulam";
var CATALOG_GLULAM_BEAM = "GlulamBeam";
var CATALOG_GLULAM_COLUMN = "GlulamColumn";
var ASSET_URL = "https://woodlaunch-assets.s3.eu-north-1.amazonaws.com";
var green = {
  fillColor: [173, 213, 170],
  strokeColor: [100, 184, 109],
  name: "green"
};
var brown = {
  fillColor: [185, 174, 166],
  strokeColor: [143, 129, 116],
  name: "brown"
};
var blue = {
  fillColor: [184, 195, 202],
  strokeColor: [135, 157, 169],
  name: "blue"
};
var grey = {
  fillColor: [185, 182, 182],
  strokeColor: [124, 122, 121],
  name: "grey"
};
var red = {
  fillColor: [204, 146, 146],
  strokeColor: [186, 74, 72],
  name: "red"
};
var orange = {
  fillColor: [255, 224, 178],
  // Lighter orange
  strokeColor: [255, 178, 102],
  // Softer orange
  name: "orange"
};
var yellow = {
  fillColor: [255, 255, 178],
  // Lighter yellow
  strokeColor: [255, 224, 102],
  // Softer yellow
  name: "yellow"
};
var purple = {
  fillColor: [204, 146, 204],
  strokeColor: [186, 74, 186],
  name: "purple"
};
var pink = {
  fillColor: [204, 146, 204],
  strokeColor: [186, 74, 186],
  name: "pink"
};
var dark = {
  fillColor: [52, 64, 84],
  strokeColor: [32, 24, 44],
  name: "dark"
};
var accent = {
  fillColor: [214, 72, 45],
  strokeColor: [174, 42, 25],
  name: "accent"
};
var white = {
  fillColor: [249, 246, 243],
  strokeColor: [209, 206, 203],
  name: "white"
};
var typeToImageMap = {
  TreeSpruce: "tree_spruce_4.png",
  [TYPE_FOREST]: "scene_forest_mix1_16.png",
  [TYPE_FOREST_STAND]: "scene_forest_mix1_16.png",
  [TYPE_SAWMILL]: "industrial_building_M2_32.png",
  [TYPE_FACTORY]: "industrial_building_L1_32.png",
  [TYPE_GLULAM_FACTORY]: "industrial_building_L3_32.png",
  [TYPE_BUILDING]: "apartments_M1_32.png",
  [TYPE_SUPPLY_BATCH]: "box_S_closed_1.png",
  [NODE_TYPE_DATA_SOURCE]: "database_1_1.png",
  [TYPE_IFC_FILE]: "file_BIM_house_model_1.png",
  [TYPE_EXCEL_FILE]: "file_spread_XY_1.png"
};
var catalogToImageMap = {
  [`${PROPERTY_KEY_CATALOG}:SpruceLogs`]: ["trees_spruces_8.png"],
  [`${PROPERTY_KEY_CATALOG}:PineLogs`]: ["trees_pines_8.png"]
};
var typeToColorMap = {
  [TYPE_FOREST]: green,
  [TYPE_SAWMILL]: brown,
  [TYPE_FACTORY]: blue,
  [TYPE_FOREST_STAND]: green,
  [TYPE_GLULAM_FACTORY]: blue,
  [TYPE_BUILDING]: grey,
  [TYPE_SUPPLY_BATCH]: grey,
  [NODE_TYPE_DATA_SOURCE]: grey,
  [TYPE_IFC_FILE]: grey,
  [TYPE_EXCEL_FILE]: grey,
  [NODE_TYPE_BUCKET]: white
};

// src/utils.ts
import PoissonDiskSampling from "poisson-disk-sampling";
import seedrandom from "seedrandom";
function getRounding(value) {
  if (value > 1e3) {
    return roundNumber(value, 0);
  }
  if (value > 100) {
    return roundNumber(value, 1);
  }
  if (value > 10) {
    return roundNumber(value, 2);
  }
  return roundNumber(value, 3);
}
function roundNumber(value, precision) {
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
}
function getRandomId() {
  return Math.floor(Math.random() * 16777215).toString(16);
}
function generateNodeKey(name) {
  const key = name ? encodeURIComponent(name) : getRandomId();
  return key;
}
var generateForest = (treeGroups, forestSize, position = [0, 0]) => {
  const treePositions = getTreePositions(forestSize[0], forestSize[1], 5, 6);
  const negativeOffset = [-forestSize[0] / 2, -forestSize[1] / 2];
  let treeCount = 0;
  for (const generatedPosition of treePositions) {
    const treeIndex = treeCount % treeGroups.length;
    const randomGroup = treeGroups[treeIndex];
    const positionWithOffset = [
      generatedPosition[0] + negativeOffset[0] + position[0],
      generatedPosition[1] + negativeOffset[1] + position[1]
    ];
    randomGroup.data.push({
      position: positionWithOffset
    });
    treeCount++;
  }
};
function mulberry32(a) {
  return function() {
    var t = a += 1831565813;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  };
}
function getTreePositions(width, height, minDistance = 5, maxDistance = 10, tries = 10, seed) {
  const p = new PoissonDiskSampling(
    {
      shape: [width, height],
      minDistance,
      maxDistance,
      tries
    },
    mulberry32(seed || 1)
  );
  const points = p.fill();
  return points;
}
function generateRandomSeedNumbers(seed, count) {
  const rng = seedrandom(seed);
  const randomNumbers = [];
  for (let i = 0; i < count; i++) {
    randomNumbers.push(rng());
  }
  return randomNumbers;
}

// src/node/edge.ts
var Edge = class {
  source;
  target;
  // the node has carrierEdgeId (and carrierEdge instance runtime) to save the connection after serialisation
  _carrier;
  // find from relationship or the indicator before instantiation
  _name;
  // the relationship key
  _key;
  // cached value - use carrier node to get the value
  _value;
  _unit;
  _style;
  // runtime for straight lines
  _path;
  constructor(props, temp) {
    const { relationKey, name, value, ...rest } = props;
    Object.assign(this, rest);
    if (relationKey) {
      this._key = relationKey;
    }
    if (name) {
      this._name = name;
    }
    if (value) {
      this._value = value;
    }
  }
  get id() {
    return Edge.createId(this.source, this.target, this._key);
  }
  get key() {
    return this._key;
  }
  set key(key) {
    this._key = key;
  }
  get name() {
    return this._name || "";
  }
  set name(name) {
    this._name = name;
  }
  get lineType() {
    return this._style?.lineType || "bezier";
  }
  set lineType(lineType) {
    this._style = this._style || {};
    this._style.lineType = lineType;
  }
  // because base does not have a query
  isBaseToBase() {
    return this.source.isBase() && this.target.isBase();
  }
  isBaseToBucket() {
    return this.source.isBase() && this.target.isBucket();
  }
  isSupplyChain() {
    return this.source.hasDownstream() || this.target.hasUpstream();
  }
  isDownstream() {
    return this.source.hasDownstream();
  }
  getLabel() {
    const label = this._name || this._key || "";
    const value = this.getValue();
    if (value) {
      const unit = this.getUnit();
      if (unit) {
        return `${label} (${getRounding(value)} ${unit})`;
      }
      return `${label} (${value})`;
    }
    return label;
  }
  // cache
  getValue() {
    return this._value;
  }
  // cache
  setValue(value) {
    this._value = value;
  }
  // the carrier setting will determine where the value comes from (datasource, children or the property value)
  getPropertyValue(propertyKey) {
    const value = this._carrier?.getPropertyValue(propertyKey);
    this.setValue(value);
    return this.getValue();
  }
  getCarrierValue(propertyKey) {
    return this._carrier?.getPropertyValue(propertyKey);
  }
  getUnit() {
    return this._unit;
  }
  getFillColor() {
    return this._style?.fillColor;
  }
  getColorCode() {
    const fillColor = this.getFillColor();
    if (!fillColor) {
      return "";
    }
    const [r, g, b] = fillColor;
    return `rgb(${r},${g},${b})`;
  }
  setFillColor(color) {
    this._style = this._style || {};
    this._style.fillColor = color;
  }
  // the layout determines the position
  // hasPosition(): boolean {
  //   return this.source.hasPosition() && this.target.hasPosition();
  // }
  hasDataSource() {
    return this.key === RELATION_KEY_HAS_DATA_SOURCE;
  }
  // below inspired by https://github.com/microsoft/msagljs/blob/main/modules/core/src/structs/edge.ts, see LICENSE
  add() {
    if (this.source !== this.target) {
      this.source._outEdges.add(this);
      this.target._inEdges.add(this);
    } else {
      this.source._selfEdges.add(this);
    }
  }
  remove() {
    if (this.source !== this.target) {
      this.source._outEdges.delete(this);
      this.target._inEdges.delete(this);
    } else {
      this.source._selfEdges.delete(this);
    }
  }
  isInterGraphEdge() {
    return this.source._parent !== this.target._parent;
  }
  // the edge should have only one visible carrier node
  // however there could be multiple prepared edge nodes in the parent?
  // so to distinguish the edge nodes, a key should be used (on property key?)
  // note that buckets also should be used to filter the data on the edges
  static createId(source, target, key = "default") {
    const sourceUri = source.getUri();
    const targetUri = target.getUri();
    const relationKey = key;
    return Edge.edgePropsToId({ sourceUri, targetUri, relationKey });
  }
  static edgePropsToId(edgeProps) {
    return `${edgeProps.sourceUri}-${edgeProps.relationKey}-${edgeProps.targetUri}`;
  }
};

// src/node/node-collection.ts
var NodeCollection = class {
  remove(node) {
    this.nodeMap.delete(node.getVersionUri());
  }
  get size() {
    return this.nodeMap.size;
  }
  *nodes_() {
    for (const p of this.nodeMap.values())
      yield p;
  }
  *graphs_() {
    for (const n of this.nodes_()) {
      if (n instanceof Node) {
        yield n;
      }
    }
  }
  findShallow(versionUri) {
    return this.nodeMap.get(versionUri);
  }
  get nodesShallow() {
    return this.nodes_();
  }
  get graphs() {
    return this.graphs_();
  }
  nodeMap = /* @__PURE__ */ new Map();
  *_edges() {
    for (const node of this.nodeMap.values()) {
      for (const e of node._outEdges) {
        yield e;
      }
      for (const e of node._selfEdges) {
        yield e;
      }
    }
  }
  interGraphEdges() {
    throw new Error("not implemented");
  }
  get nodeShallowCount() {
    return this.nodeMap.size;
  }
  // caution: it is a linear by the number of nodes method
  get edgeCount() {
    let count = 0;
    for (const p of this.nodeMap.values()) {
      count += p.getOutDegree() + p.getSelfDegree();
    }
    return count;
  }
  /**  returns the edges of shallow nodes */
  get edges() {
    return this._edges();
  }
  addNode(node) {
    this.nodeMap.set(node.getVersionUri(), node);
  }
  nodeIsConsistent(n) {
    for (const e of n._outEdges) {
      if (e.source !== n) {
        return false;
      }
      if (e.source === e.target) {
        return false;
      }
    }
    for (const e of n._inEdges) {
      if (e.target !== n) {
        return false;
      }
      if (e.source === e.target) {
        return false;
      }
    }
    for (const e of n._selfEdges) {
      if (e.target !== e.source) {
        return false;
      }
      if (e.source !== n) {
        return false;
      }
    }
    return true;
  }
  isConsistent() {
    for (const node of this.nodeMap.values()) {
      if (!this.nodeIsConsistent(node)) {
        return false;
      }
    }
    return true;
  }
};

// src/node/node.ts
var Node = class {
  _props;
  // save the props on the node
  // getter / settes use _
  _id;
  // if not defined this is not saved yet in db
  _tempId;
  // use as temp id when creating a new node
  _key;
  // last part of the uri, use id if no key. Use this also for property nodes (property key)
  _type;
  _subTypeOf;
  _namespace;
  _name;
  // move these into runtime nodes as soon as possible after init to keep instances close by
  _boardId;
  _parentId;
  _createdAt;
  // runtime
  _typeNode;
  // the instance of the TypeNode derived from _type (for types this will be THE TypeNode)
  _subTypeNode;
  // the instance of the TypeNode derived from _subTypeOf (this will be null for instances as they are not TypeNodes (types))
  _parent;
  // hierarchy in the workspace
  _project;
  // the project node
  // carrier
  _sourceNode;
  // the source node of the carrier
  _targetNode;
  // the target node of the carrier
  // todo: write how this is needed, since source and target are added in runtime
  _carrierEdge;
  // if this is a carrier node, this is the edge that is the carrier
  _polygon;
  // should move to portal?
  _inEdges = /* @__PURE__ */ new Set();
  _outEdges = /* @__PURE__ */ new Set();
  _selfEdges = /* @__PURE__ */ new Set();
  // only use these if explicit choice - otherwise use cascade from property -> children -> data source (this means that a hierarchy is implicit)
  _useNodePropertyValue;
  // if true, use the value that is set in the property
  _useChildrenPropertyValue;
  // if true, use the children property values on this node
  _useDataSourcePropertyValue;
  // if true, use the data source children values
  _modelMatrix;
  // for 3d nodes
  _children = new NodeCollection();
  constructor(props) {
    const id = props?.id || getRandomId();
    const key = props?.key || id;
    const type = props?.type || NODE_TYPE_DEFAULT;
    const namespace = props?.namespace || DEFAULT_NAMESPACE;
    const boardId = props?.boardId || DEFAULT_PROJECT_ID;
    const createdAt = props?.createdAt || (/* @__PURE__ */ new Date()).toISOString();
    const resolvedProps = {
      ...props || {},
      id,
      key,
      type,
      namespace,
      boardId,
      createdAt
    };
    this._props = resolvedProps;
    if (props?.id) {
      this._id = id;
    } else {
      this._tempId = getRandomId();
    }
  }
  // let's say that there is only one edge node - it's always supply chain and can contain all kinds of data sources, children etc. Create buckets if needed or just filter the nodes depending on active indicator
  // the alternative would be to create several different nodes and expand the id with some key
  // also: consider moving some logic to node-utils that are not related to the node itself
  static createEdgeNodeId(sourceUri, targetUri) {
    return `${sourceUri}--${targetUri}`;
  }
  isTemporary() {
    return !!this._tempId;
  }
  // SEMANTIC CHECKS
  isCarrier() {
    return !!this.hasCarrierDemand() || !!this.hasCarrierSupply();
  }
  isDownstreamCarrier() {
    return !!this.hasCarrierSupply();
  }
  isUpstreamCarrier() {
    return !!this.hasCarrierDemand();
  }
  isBucket() {
    return Boolean(this.type === NODE_TYPE_BUCKET);
  }
  isBase() {
    return !this.isBucket();
  }
  hasDownstream() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_DOWNSTREAM]?.length > 0;
  }
  hasUpstream() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_UPSTREAM]?.length > 0;
  }
  hasDownstreamSupply() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_DOWNSTREAM_SUPPLY]?.length > 0;
  }
  hasUpstreamSupply() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_UPSTREAM_SUPPLY]?.length > 0;
  }
  hasDownstreamDemand() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_DOWNSTREAM_DEMAND]?.length > 0;
  }
  hasUpstreamDemand() {
    const relations = this.getRelations();
    return relations[RELATION_KEY_UPSTREAM_DEMAND]?.length > 0;
  }
  hasSupplyChain() {
    return this.hasDownstream() || this.hasUpstream();
  }
  hasCarrierSupply() {
    return this.hasDownstreamSupply() && this.hasUpstreamSupply();
  }
  hasCarrierDemand() {
    return this.hasDownstreamDemand() && this.hasUpstreamDemand();
  }
  // GETTERS AND SETTERS
  // after moving properties and relations to _props, we can use the getters and setters
  get id() {
    return this.getId();
  }
  get key() {
    return this.getKey();
  }
  get type() {
    return this._props.type;
  }
  set type(type) {
    this._props.type = type;
  }
  get namespace() {
    return this._props.namespace;
  }
  set namespace(namespace) {
    this._props.namespace = namespace;
  }
  // get name(): string {
  //   return this._props.name || '';
  // }
  // set name(name: string) {
  //   this._props.name = name;
  // }
  get parentId() {
    return this._props.parentId;
  }
  get x() {
    return this.getX();
  }
  set x(x) {
    this.setX(x);
  }
  get y() {
    return this.getY();
  }
  set y(y) {
    this.setY(y);
  }
  get z() {
    return this.getZ();
  }
  set z(z) {
    this.setZ(z);
  }
  get center() {
    const [x, y] = this.getPosition() || [0, 0];
    return [x, y];
  }
  get width() {
    return this.getWidth();
  }
  set width(width) {
    this.setWidth(width);
  }
  get height() {
    return this.getHeight();
  }
  set height(height) {
    this.setHeight(height);
  }
  get polygon() {
    return this._polygon;
  }
  set polygon(polygon) {
    this._polygon = polygon;
  }
  get longitude() {
    const location = this.getLocation();
    if (location) {
      return location[0];
    }
    return void 0;
  }
  set longitude(longitude) {
    const location = this.getLocation();
    if (location) {
      location[0] = longitude;
    } else {
      this.setPropertyValue("location", [longitude, 0]);
    }
  }
  get latitude() {
    const location = this.getLocation();
    if (location) {
      return location[1];
    }
    return void 0;
  }
  set latitude(latitude) {
    const location = this.getLocation();
    if (location) {
      location[1] = latitude;
    } else {
      this.setPropertyValue("location", [0, latitude]);
    }
  }
  // get bucket() {
  //   return this._props.bucket;
  // }
  // set bucket(bucket: any) {
  //   this._props.bucket = bucket;
  // }
  // a bit confusing between z, elevation and extrusion, but elevation should be geo-based
  // get elevation(): number | undefined {
  //   const elevation = this.getPropertyValue('elevation');
  //   return elevation || 0;
  // }
  // get geometry(): any {
  //   return this.getFeature();
  // }
  // SET AND GET FUNCTIONS
  // don't use id in general, it's the database id
  setId(id) {
    this._id = id;
    this._tempId = null;
  }
  getId() {
    if (this._id) {
      return this._id;
    }
    if (this._tempId) {
      return this._tempId;
    }
    this._tempId = getRandomId();
    return this._tempId;
  }
  getKey() {
    return this._props.key;
  }
  // setType(nodeType: Node) {
  //   this._typeNode = nodeType;
  //   this._type = nodeType.getKey();
  // }
  // short for non-versioned uri
  getUri() {
    const nodeProps = {
      key: this.getKey(),
      type: this._props.type,
      namespace: this._props.namespace,
      boardId: this._props.boardId
    };
    return Node.nodePropsToVersionUri(nodeProps);
  }
  // only the createAt part
  getVersion() {
    const createdAt = this._props.createdAt;
    if (createdAt) {
      return encodeURIComponent(createdAt);
    }
    return void 0;
  }
  setVersion(dateString) {
    this._props.createdAt = dateString || (/* @__PURE__ */ new Date()).toISOString();
  }
  // without key
  getBaseUri() {
    return `${this._props.namespace}/${this._props.type}`;
  }
  getVersionUri() {
    return this.getUri();
  }
  getProjectId() {
    return this._props.boardId;
  }
  setProjectId(boardId) {
    this._props.boardId = boardId;
  }
  // isShadow(): boolean {
  //   return !!this._props.isShadowNode;
  // }
  // setIsShadow(isShadow: boolean = true) {
  //   this._props.isShadowNode = isShadow;
  // }
  // setBucketIndicator(indicatorKey: string) {
  //   this._props.bucket = this._props.bucket || {};
  //   this._props.bucket.indicatorKey = indicatorKey;
  // }
  // setBucketLayout(layoutKey: string) {
  //   this._props.bucket = this._props.bucket || {};
  //   this._props.bucket.layoutKey = layoutKey;
  // }
  // setBucketFilterByTypes(types: string[]) {
  //   this._props.bucket = this._props.bucket || {};
  //   this._props.bucket.filterByTypes = types;
  // }
  // setBucketFilterByIds(ids: string[]) {
  //   this._props.bucket = this._props.bucket || {};
  //   this._props.bucket.filterByIds = ids;
  // }
  // only for manual
  getBounds() {
    const { width, height } = this;
    const center = this.center;
    if (!center || !width || !height) {
      return null;
    }
    const [x, y] = center;
    const minX = x - width / 2;
    const minY = y - height / 2;
    return [minX, minY, minX + width, minY + height];
  }
  // style related
  getX() {
    return this._props.appearance?.position?.[0];
  }
  setX(x) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.position = this._props.appearance.position || [
      0,
      0,
      0
    ];
    this._props.appearance.position[0] = x;
  }
  getY() {
    return this._props.appearance?.position?.[1];
  }
  setY(y) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.position = this._props.appearance.position || [
      0,
      0,
      0
    ];
    this._props.appearance.position[1] = y;
  }
  getZ() {
    return this._props.appearance?.position?.[2];
  }
  setZ(z) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.position = this._props.appearance.position || [
      0,
      0,
      0
    ];
    this._props.appearance.position[2] = z;
  }
  getPosition() {
    const x = this.getX();
    const y = this.getY();
    const z = this.getZ();
    if (!x && x !== 0 || !y && y !== 0) {
      return null;
    }
    return [x, y, z || 0];
  }
  // manual position only
  setPosition(x, y, z) {
    this._props.appearance = this._props.appearance || {};
    const useZ = z || z === 0 ? z : this.getZ() || 0;
    this._props.appearance.position = [x, y, useZ];
  }
  // this should be checked in layout
  // hasPosition(): boolean {
  //   return Boolean(this.getPosition());
  // }
  getWidth() {
    return this._props.appearance?.size?.[0];
  }
  setWidth(width) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.size = this._props.appearance.size || [0, 0];
    this._props.appearance.size[0] = width;
  }
  getHeight() {
    return this._props.appearance?.size?.[1];
  }
  setHeight(height) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.size = this._props.appearance.size || [0, 0];
    this._props.appearance.size[1] = height;
  }
  getSize() {
    const width = this.getWidth();
    const height = this.getHeight();
    if (!width && width !== 0 || !height && height !== 0) {
      return null;
    }
    return [width, height];
  }
  setSize(width, height) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.size = [width, height];
  }
  getRotation() {
    return this._props.appearance?.rotation;
  }
  setRotation(rotation) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.rotation = rotation;
  }
  getScale() {
    return this._props.appearance?.scale;
  }
  setScale(scale) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.scale = scale;
  }
  getExtrusion() {
    return this._props.appearance?.extrusion;
  }
  setExtrusion(extrusion) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.extrusion = extrusion;
  }
  getFillColor() {
    return this._props.appearance?.fillColor;
  }
  getColorCode() {
    const fillColor = this.getFillColor();
    if (!fillColor) {
      return "";
    }
    const [r, g, b] = fillColor;
    return `rgb(${r},${g},${b})`;
  }
  setFillColor(color) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.fillColor = color;
  }
  getStrokeColor() {
    return this._props.appearance?.strokeColor;
  }
  getColor() {
    const fillColor = this.getFillColor();
    const strokeColor = this.getStrokeColor();
    const color = {};
    if (fillColor) {
      color.fillColor = fillColor;
    }
    if (strokeColor) {
      color.strokeColor = strokeColor;
    }
    return color;
  }
  setStrokeColor(color) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.strokeColor = color;
  }
  getImageUrl() {
    return this._props.appearance?.imageUrl;
  }
  setImageUrl(url) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.imageUrl = url;
  }
  getAsset3dUrl() {
    return this._props.appearance?.asset3dUrl;
  }
  setAsset3dUrl(url) {
    this._props.appearance = this._props.appearance || {};
    this._props.appearance.asset3dUrl = url;
  }
  // PROPERTIES
  getLocation() {
    return this.getPropertyValue("location");
  }
  setLocation(location) {
    this.setPropertyValue("location", location);
  }
  // todo: might figure out a way to store the coordinates and type instead
  getFeature() {
    const geometry = this._props.appearance?.geometry;
    if (!geometry) {
      return void 0;
    }
    return {
      type: "Feature",
      geometry,
      properties: this.getProperties()
    };
  }
  // - DO NOT add template on the type! only a catalog item can define a nodes properties
  // nodes can have random properties without semantic meaning
  // when a type is assigned, we can try to connect a catalog item as well ( use the AI agent )
  // getPropertyTemplate(): any {
  //   return getDataTemplate(this);
  // }
  getProperties() {
    return this._props.properties;
  }
  getPropertyValue(propertyKey) {
    return this.getNodePropertyValue(propertyKey);
  }
  setPropertyValue(propertyKey, value) {
    this._props.properties = this._props.properties || {};
    this._props.properties[propertyKey] = value;
  }
  getNodePropertyValue(propertyKey) {
    return this._props.properties?.[propertyKey];
  }
  // todo: try not to use this and instead always return nodes with values for aggregation
  // only numeric
  // getChildrenPropertyValueSum(propertyKey: string): number | undefined {
  //   const nodeMap: NodeMap = {};
  //   const ignoreThisNode = true;
  //   // this.getPropertyValueNodes(propertyKey, nodeMap, ignoreThisNode);
  //   // const children = Object.values(nodeMap);
  //   // let foundValue = false;
  //   // const value = children
  //   //   // .filter(child => child.type === type)
  //   //   .map(child => child.getNodePropertyValue(propertyKey))
  //   //   .reduce((acc, curr) => {
  //   //     if (curr || curr === 0) {
  //   //       acc += curr;
  //   //       foundValue = true;
  //   //     }
  //   //     return acc;
  //   //   }, 0);
  //   // return foundValue ? value : undefined;
  //   const children = this.getChildren();
  //   let foundValue = false;
  //   // recursive!
  //   const value = children
  //     .map(child => child.getPropertyValue(propertyKey))
  //     .reduce((acc, curr) => {
  //       if (curr || curr === 0) {
  //         acc += curr;
  //         foundValue = true;
  //       }
  //       return acc;
  //     }, 0);
  //   return foundValue ? value : undefined;
  // }
  // only numeric
  // getDataSourcePropertyValue(propertyKey: string): number | undefined {
  //   if (!this._parent) {
  //     console.warn('Node has no parent, cannot get data source property value');
  //     return;
  //   }
  //   const dataSourceEdges = this.getDataSourceEdges();
  //   const nodeMap: NodeMap = {};
  //   for (const edge of dataSourceEdges) {
  //     const dataSourceNode = edge.target;
  //     dataSourceNode.getPropertyValueNodes(propertyKey, nodeMap);
  //   }
  //   const children = Object.values(nodeMap);
  //   let foundValue = false;
  //   const value = children
  //     // .filter(child => child.type === type)
  //     .map(child => child.getPropertyValue(propertyKey))
  //     .reduce((acc, curr) => {
  //       if (curr || curr === 0) {
  //         acc += curr;
  //         foundValue = true;
  //       }
  //       return acc;
  //     }, 0);
  //   return foundValue ? value : undefined;
  // }
  // NOTE: regarding bucket children - these are important as the are aggregated nodes or generated from separate insights (cannot be derived again from base, if manually created by user in separate layout)
  // this is a cache right? To avoid recalculating the bucket over and over. Be careful
  // try to first find all nodes, then do the calculation..
  // getBucketPropertyValue(propertyKey: string): number | undefined {
  //   const insightFromNodeEdges = this.getInsightFromNodeEdges();
  //   const nodeMap: NodeMap = {};
  //   for (const edge of insightFromNodeEdges) {
  //     const insightFromNode = edge.target;
  //     insightFromNode.getPropertyValueNodes(propertyKey, nodeMap);
  //   }
  //   const children = Object.values(nodeMap);
  //   let foundValue = false;
  //   const value = children
  //     // .filter(child => child.type === type)
  //     .map(child => child.getNodePropertyValue(propertyKey))
  //     .reduce((acc, curr) => {
  //       if (curr || curr === 0) {
  //         acc += curr;
  //         foundValue = true;
  //       }
  //       return acc;
  //     }, 0);
  //   return foundValue ? value : undefined;
  // }
  useNodePropertyValue() {
    this._useNodePropertyValue = true;
    this._useChildrenPropertyValue = false;
    this._useDataSourcePropertyValue = false;
  }
  useChildrenPropertyValue() {
    this._useNodePropertyValue = false;
    this._useChildrenPropertyValue = true;
    this._useDataSourcePropertyValue = false;
  }
  useDataSourcePropertyValue() {
    this._useNodePropertyValue = false;
    this._useChildrenPropertyValue = false;
    this._useDataSourcePropertyValue = true;
  }
  // at some place in node hierarchy the "cube nodes" could be the unit node - use for unit visualisation
  // getCubeNodes(properties: string[] = [], nodeMap: NodeMap = {}): Node[] {
  //   const children = this.getChildren();
  //   const { foundCubeNodes, drillDownNodes } = children.reduce(
  //     (acc, child) => {
  //       const childProperties = child.getProperties() || {};
  //       if (
  //         properties.every(property => {
  //           return childProperties[property] || childProperties[property] === 0;
  //         })
  //       ) {
  //         acc.foundCubeNodes.push(child);
  //       } else {
  //         acc.drillDownNodes.push(child);
  //       }
  //       return acc;
  //     },
  //     { foundCubeNodes: [], drillDownNodes: [] }
  //   );
  //   for (const node of foundCubeNodes) {
  //     nodeMap[node.getUri()] = node;
  //   }
  //   for (const child of drillDownNodes) {
  //     child.getCubeNodes(properties, nodeMap);
  //   }
  //   return Object.values(nodeMap);
  // }
  // get nodes with direct property values for further aggregation (for example vega)
  // getPropertyValueNodes(
  //   propertyKey: string,
  //   nodeMap: NodeMap = {},
  //   ignoreFirst?: boolean
  // ): Node[] {
  //   // first check local value and return if set
  //   if (!ignoreFirst) {
  //     // instead of looping through children, use this flag if THIS is not relevant
  //     // ignore first is a hack when we know that first node is not relevant but still the later checks must be done for relationships
  //     const hasOtherFlag =
  //       this._useChildrenPropertyValue || this._useDataSourcePropertyValue;
  //     const localValue = this.getNodePropertyValue(propertyKey);
  //     if (localValue || localValue === 0) {
  //       nodeMap[this.getVersionUri()] = this;
  //       return Object.values(nodeMap);
  //     }
  //   }
  //   // then check the children (maybe skip this and go directly to recursive...)
  //   const children = this.getChildren();
  //   // const foundChildren = children.filter(child => {
  //   //   const childValue = child.getNodePropertyValue(propertyKey);
  //   //   return childValue || childValue === 0;
  //   // });
  //   // if (foundChildren.length > 0) {
  //   //   nodes.push(...foundChildren);
  //   //   return nodes;
  //   // }
  //   // then check recursively
  //   let nodeLengthBefore = Object.values(nodeMap).length;
  //   for (const child of children) {
  //     child.getPropertyValueNodes(propertyKey, nodeMap);
  //   }
  //   // then check if insight from
  //   if (nodeLengthBefore === Object.values(nodeMap).length) {
  //     const insightFromNodeEdges = this.getInsightFromNodeEdges();
  //     for (const edge of insightFromNodeEdges) {
  //       const insightFromNode = edge.target;
  //       insightFromNode.getPropertyValueNodes(propertyKey, nodeMap);
  //     }
  //   }
  //   // then check data source
  //   if (nodeLengthBefore === Object.values(nodeMap).length) {
  //     const dataSourceEdges = this.getDataSourceEdges();
  //     for (const edge of dataSourceEdges) {
  //       const dataSourceNode = edge.target;
  //       dataSourceNode.getPropertyValueNodes(propertyKey, nodeMap);
  //     }
  //   }
  //   // if (childNodes.length > 0) {
  //   //   nodes.push(...childNodes);
  //   //   return nodes;
  //   // }
  //   // if not found, check data source
  //   return Object.values(nodeMap);
  // }
  // since parent has been removed from the constructor, this is the way to add a node to a parent
  // all nodes should have a parent, except the root node. But the root node will not need edges (this is the main reason for parent)
  setParent(parent) {
    this._parent = parent;
  }
  getSerializedProperties() {
    return this._props.properties || {};
  }
  // RELATIONS (TRY TO USE RELATIONS MORE AS NODE to NODE than edges in general -> the child connections in the parent)
  // run this on a parent to connect the children relations with edges
  instantiateAllRelations() {
    const childNodes = this.getChildren();
    for (const sourceNode of childNodes) {
      sourceNode._parent = this;
      sourceNode._parentId = this.getId();
      sourceNode.instantiateRelations();
    }
  }
  // run this after adding a single node on the node itself
  instantiateRelations() {
    if (!this._parent) {
      console.log("node has no parent", this.getUri());
      return;
    }
    const relationMap = this._props.relations;
    for (const relationKey in relationMap) {
      const relations = relationMap[relationKey];
      for (const targetUri of relations) {
        const targetNode = this._parent.findChildNodeByUri(targetUri);
        if (!targetNode) {
          console.warn("Target node not found", targetUri);
          continue;
        }
        const edgeId = Edge.createId(this, targetNode, relationKey);
        const existingEdge = this.findEdge(edgeId);
        if (!existingEdge) {
          targetNode._parent = this._parent;
          targetNode._parentId = this._parent.getId();
          this.addRelation(relationKey, targetNode);
        }
      }
    }
  }
  // note that this is the specific node (this) relations, not the edges between the children
  // (for edges between the children use getEdges())
  getRelations() {
    const relations = {};
    if (!this._parent) {
      console.log("no parent");
      return relations;
    }
    const edges = this._parent.getEdges().filter((edge) => edge.source === this);
    for (const edge of edges) {
      const relationKey = edge._key;
      relations[relationKey] = relations[relationKey] || [];
      relations[relationKey].push(edge);
    }
    return relations;
  }
  getSerializedRelations() {
    const relations = this.getRelations();
    const serializedRelations = {};
    for (const relationKey in relations) {
      const relationEdges = relations[relationKey];
      serializedRelations[relationKey] = relationEdges.map(
        (edge) => edge.target.getUri()
      );
    }
    const existingRelations = this._props.relations;
    for (const relationKey in existingRelations) {
      const existingTargets = existingRelations[relationKey];
      if (!serializedRelations[relationKey]) {
        serializedRelations[relationKey] = [];
      }
      for (const targetUri of existingTargets) {
        if (!serializedRelations[relationKey].find((uri) => uri === targetUri)) {
          serializedRelations[relationKey].push(targetUri);
        }
      }
    }
    return serializedRelations;
  }
  // runtime
  getRelationsByKey(relationKey) {
    if (!this._parent) {
      return [];
    }
    return this._parent.getEdges().filter(
      (edge) => edge.source.getUri() === this.getUri() && edge._key === relationKey
    );
  }
  // node relation from "this" to another node (use parent to connect edge)
  // note that uri is used for relations!
  addRelation(relationKey, node, relationProps = {}) {
    if (!node) {
      return;
    }
    if (!this._parent) {
      console.error(
        "Parent is missing - a node MUST have a parent to add edges",
        this.getUri()
      );
      return;
    }
    const existingNode = this._parent.findChildNodeByUri(node.getUri());
    if (!existingNode) {
      console.error(
        "Target node is missing - a node must already be added to add the in-memory edge",
        this.getUri()
      );
      return;
    }
    const edgeId = Edge.createId(this, node, relationKey);
    const existingEdge = this._parent.findEdge(edgeId);
    if (existingEdge) {
      console.log("Edge already exists");
      return;
    }
    relationProps.key = relationKey;
    if (!this._parent.findChildNodeByUri(node.getUri())) {
    }
    this._parent.addEdge(this.getUri(), node.getUri(), relationProps);
  }
  // relation helpers - do not connect two ways by default, instead apply some logic to find data "form the other node"
  connectToDataSource(dataSourceNode) {
    this.addRelation(RELATION_KEY_HAS_DATA_SOURCE, dataSourceNode);
  }
  // connectToInsight(insightNode: Node) {
  //   this.addRelation(RELATION_KEY_HAS_INSIGHT, insightNode);
  //   insightNode.addRelation(RELATION_KEY_INSIGHT_FROM, this);
  // }
  // the carrier is just a placeholder for information on the edge
  // the relations should be added to the children to help in importing data from source
  // (no need to create the semantic relations for carrierUpstream and carrierDownstream as the original relation should already exist in the first place)
  // the getPropertyValue will work as usual, the children could be stored on the carrier, but probably better to use datasource node
  // convertToCarrier(sourceNode: Node, targetNode: Node) {
  //   this._props.sourceUri = sourceNode.getUri();
  //   this._props.targetUri = targetNode.getUri();
  //   this._sourceNode = sourceNode;
  //   this._targetNode = targetNode;
  // }
  getDownstreamEdges() {
    return this.getRelationsByKey(RELATION_KEY_DOWNSTREAM);
  }
  getUpstreamEdges() {
    return this.getRelationsByKey(RELATION_KEY_UPSTREAM);
  }
  getDataSourceEdges() {
    return this.getRelationsByKey(RELATION_KEY_HAS_DATA_SOURCE);
  }
  getInsightFromNodeEdges() {
    console.warn("this is not used! in node.ts");
    return [];
  }
  // SERIALIZATION
  serialize() {
    const properties = this.getSerializedProperties();
    const relations = this.getSerializedRelations();
    const id = this.getId();
    const props = { ...this._props };
    if (props.appearance) {
      props.appearance = { ...props.appearance };
    }
    if (props.appearance) {
      props.appearance = JSON.parse(JSON.stringify(props.appearance));
    }
    const nodeData = {
      // assign the existing props, then override with changes
      ...props,
      properties,
      relations
    };
    if (!nodeData.key) {
      nodeData.key = this.getId();
    }
    const version = "latest";
    if (version) {
      nodeData.createdAt = version;
    }
    return nodeData;
  }
  // getSerializedNodes(): NodeProps[] {
  //   const allNodes = this.getAllNodes();
  //   return allNodes.map(node => node.serialize());
  // }
  // custom admin functions
  moveToParent(parent) {
    this.removeSubgraph();
    parent.addNode(this);
  }
  clone(deleteId) {
    const serialized = this.serialize();
    if (deleteId) {
      delete serialized.id;
      delete serialized.key;
    }
    const clone = new Node(serialized);
    return clone;
  }
  // getTypeNode(): Node {
  //   return this._typeNode;
  // }
  // todo: figure out how findParentNode (boardId) or rootNode - which is the best way
  findRootNode() {
    let parent = this;
    while (parent._parent) {
      parent = parent._parent;
    }
    return parent;
  }
  // API for getting nodes and edges - be CLEAR
  // children are the shallow nodes in the graph
  getChildren() {
    return [...this.getShallowNodes()];
  }
  getChildrenByType(type) {
    return this.getChildren().filter((child) => child.type === type);
  }
  // always use edges on graph level, cross-graph edges are not instantiated
  // (if a cross-graph edge needs to be shown - a shadow node should be used)
  getEdges() {
    return [...this.getShallowEdges()];
  }
  // FIND
  findEdge(id) {
    return this.getEdges().find((edge) => edge.id === id);
  }
  // ROOT NODE
  getNodesFromRootRecursive() {
    const rootNode = this.findRootNode();
    return [...rootNode.getNodesBreadthFirst()];
  }
  getNodesFromRootRecursiveByType(type) {
    const nodes = this.getNodesFromRootRecursive();
    return nodes.filter((child) => child.type === type);
  }
  getNodesFromRootRecursiveByTypes(types) {
    const nodes = this.getNodesFromRootRecursive();
    return nodes.filter((child) => types.includes(child.type));
  }
  // Following inspired by https://github.com/microsoft/msagljs/tree/main (see LICENSE)
  // DONT ADD NEW METHODS BELOW THIS LINE
  getOutDegree() {
    return this._outEdges.size;
  }
  getInDegree() {
    return this._inEdges.size;
  }
  getSelfDegree() {
    return this._selfEdges.size;
  }
  getDegree() {
    return this.getOutDegree() + this.getInDegree() + this.getSelfDegree();
  }
  /** iterates over the edges of the graph which adjacent to the nodes of the graph:
   * not iterating over the subgraphs
   */
  getShallowEdges() {
    return this._children.edges;
  }
  /** iterates over the edges of the graph including subgraphs */
  getDeepEdges() {
    return this.deepEdgesIt();
  }
  /** Iterates over the nodes of the current graph but not entering the subgraphs.
   *  Yields the top subgraphs among the nodes as well
   */
  getShallowNodes() {
    return this._children.nodesShallow;
  }
  /** Iterates over all the nodes of including the subgraphs.
   * The iteration happens in the breadth first pattern.
   */
  getNodesBreadthFirst() {
    return this.getNodesBreadthFirst_();
  }
  getShallowNodeCount() {
    return this._children.nodeShallowCount;
  }
  getNodeCountDeep() {
    let count = this._children.size;
    for (const p of this.getShallowNodes()) {
      if (p instanceof Node) {
        count += p.getNodeCountDeep();
      }
    }
    return count;
  }
  getEdgeCount() {
    return this._children.edgeCount;
  }
  /** return the number of all edges in the graph, including the subgraphs */
  getDeepEdgesCount() {
    let count = 0;
    for (const p of this.getNodesBreadthFirst()) {
      count += p.getOutDegree() + p.getSelfDegree();
    }
    return count;
  }
  removeOutEdge(edge) {
    this._outEdges.delete(edge);
  }
  removeInEdge(edge) {
    this._inEdges.delete(edge);
  }
  *_edges() {
    for (const e of this._inEdges)
      yield e;
    for (const e of this._outEdges)
      yield e;
    for (const e of this._selfEdges)
      yield e;
  }
  // // use to get bases and buckets, not property, indicator and relation nodes, etc
  // getNodes() {
  //   return [...this.getShallowNodes()].filter(
  //     n =>
  //       n.type !== NODE_TYPE_TYPE &&
  //       n.type !== NODE_TYPE_PROPERTY &&
  //       n.type !== NODE_TYPE_CATALOG &&
  //       n.type !== NODE_TYPE_INDICATOR
  //   ) as Node[];
  // }
  // // only bases and buckets
  // getNodesRecursive() {
  //   return [...this.getNodesBreadthFirst()].filter(
  //     n =>
  //       n.type !== NODE_TYPE_TYPE &&
  //       n.type !== NODE_TYPE_PROPERTY &&
  //       n.type !== NODE_TYPE_CATALOG &&
  //       n.type !== NODE_TYPE_INDICATOR
  //   ) as Node[];
  // }
  getParent() {
    return this._parent;
  }
  *getParents() {
    let p = this._parent;
    while (p != null) {
      yield p;
      p = p._parent;
    }
  }
  /**  Determines if this node is a childe of the given graph in the workspace */
  isChildOf(parentNode) {
    for (const p of this.getParents()) {
      if (p === parentNode)
        return true;
    }
    return false;
  }
  // get all nodes, properties, etc shallow
  // getAllChildren(): Node[] {
  //   return [...this.getShallowNodes()] as Node[];
  // }
  // getTypeNodes(): Node[] {
  //   const typeNodeMap: NodeMap = {};
  //   const rootNode = this.findRootNode();
  //   for (const nodeType of BUILT_IN_NODE_TYPES) {
  //     typeNodeMap[nodeType] = new Node({
  //       id: nodeType,
  //       key: nodeType,
  //       type: NODE_TYPE_TYPE,
  //       name: nodeType.replace('_', ''),
  //     });
  //   }
  //   for (const node of rootNode.getNodesBreadthFirst()) {
  //     if (!typeNodeMap[node._key]) {
  //       if (node._type === NODE_TYPE_TYPE) {
  //         typeNodeMap[node._key] = node;
  //       }
  //     }
  //   }
  //   return Object.values(typeNodeMap);
  // }
  // addTypeNode(typeNode: Node) {
  //   if (typeNode.type !== NODE_TYPE_TYPE) {
  //     console.warn('addTypeNode: Node is not a type node');
  //     return;
  //   }
  //   const rootNode = this.findRootNode();
  //   rootNode.addNode(typeNode);
  // }
  // getTypeNode(): Node {
  //   const typeNodes = this.getTypeNodes();
  //   let found = typeNodes.find(node => node._key === this.type);
  //   if (!found) {
  //     found = new Node({
  //       id: this.type,
  //       key: this.type,
  //       type: NODE_TYPE_TYPE,
  //       name: this.type.replace('_', ''),
  //     });
  //     this.addTypeNode(found);
  //   }
  //   return found;
  // }
  // getTypeList(): NodeRow[] {
  //   const typeNodes = this.getTypeNodes().filter(n => !n._key.startsWith('_'));
  //   return typeNodes.map(typeNode => {
  //     return {
  //       _node: typeNode,
  //       _level: 0,
  //       id: typeNode.id,
  //       name: typeNode.name || typeNode._key,
  //       parentId: typeNode._parentId,
  //       expanded: false,
  //       type: typeNode.type,
  //     } as NodeRow;
  //   });
  // }
  // // return all type nodes from this node and up to the root following the parent
  // getTypeParentHierarchy(): string[] {
  //   const types = [this.type];
  //   let p = this._parent;
  //   while (p != null) {
  //     types.push(p.type);
  //     p = p._parent;
  //   }
  //   return types;
  // }
  // getCarrierNodes(): Node[] {
  //   return this.getChildrenByType(NODE_TYPE_CARRIER);
  // }
  // todo: figure out a way to handle properties and indicator specifications
  // the node.properties should be kept very simple
  // getPropertyNodes(): Node[] {
  //   const indicatorNodeMap: NodeMap = {};
  //   const rootNode = this.findRootNode();
  //   for (const indicatorProps of propertyDataTemplates) {
  //     const { key, name } = indicatorProps;
  //     indicatorNodeMap[indicatorProps.key] = new Node({
  //       id: key,
  //       key,
  //       type: NODE_TYPE_INDICATOR,
  //       name,
  //     });
  //   }
  //   for (const node of rootNode.getNodesBreadthFirst()) {
  //     if (!indicatorNodeMap[node._key]) {
  //       if (node._type === NODE_TYPE_INDICATOR) {
  //         indicatorNodeMap[node._key] = node;
  //       }
  //     }
  //   }
  //   return Object.values(indicatorNodeMap);
  // }
  // getIndicatorNodes(): Node[] {
  //   const indicatorNodeMap: NodeMap = {};
  //   const rootNode = this.findRootNode();
  //   for (const indicatorProps of indicatorNodeData) {
  //     const { key, name } = indicatorProps;
  //     indicatorNodeMap[indicatorProps.key] = new Node({
  //       id: key,
  //       key,
  //       type: NODE_TYPE_INDICATOR,
  //       name,
  //     });
  //   }
  //   for (const node of rootNode.getNodesBreadthFirst()) {
  //     if (!indicatorNodeMap[node._key]) {
  //       if (node._type === NODE_TYPE_INDICATOR) {
  //         indicatorNodeMap[node._key] = node;
  //       }
  //     }
  //   }
  //   return Object.values(indicatorNodeMap);
  // }
  // getIndicatorNode(indicatorKey: string): Node | undefined {
  //   const indicatorNodes = this.getIndicatorNodes();
  //   return indicatorNodes.find(node => node._key === indicatorKey);
  // }
  // getIndicatorList(): NodeRow[] {
  //   const indicatorNodes = this.getIndicatorNodes();
  //   console.log('indicators', indicatorNodes);
  //   return indicatorNodes.map(indicatorNode => {
  //     return {
  //       _node: indicatorNode,
  //       _level: 0,
  //       id: indicatorNode.id,
  //       name: indicatorNode.name || indicatorNode._key,
  //       parentId: indicatorNode._parentId,
  //       expanded: false,
  //       type: indicatorNode.type,
  //     } as NodeRow;
  //   });
  // }
  // getCatalogNodes(): Node[] {
  //   return this.getChildrenByType(NODE_TYPE_CATALOG);
  // }
  *getParentTypeNodes() {
    let p = this._subTypeNode;
    while (p != null) {
      yield p;
      p = p._subTypeNode;
    }
  }
  /**  Determines if this node is a type of the given node.*/
  isTypeNodeOf(typeNode) {
    for (const p of this.getParentTypeNodes()) {
      if (p === typeNode)
        return true;
    }
    return false;
  }
  // getAllEdges(): Edge[] {
  //   return [...this.getDeepEdges()] as Edge[];
  // }
  // from graph class
  /** Removes itself from under the parent.
   *  Also removes all the edges leading out of the graph.
   */
  removeSubgraph() {
    const parent = this._parent;
    if (parent)
      parent.removeNode(this);
    for (const c of this.outGoingEdges()) {
      if (c.attachedAtSource) {
        c.node.removeOutEdge(c.edge);
      } else {
        c.node.removeInEdge(c.edge);
      }
    }
  }
  /** returns the objects that show how the edge is adjacent to a node  that is outside of the graph */
  *outGoingEdges() {
    for (const e of this._outEdges) {
      const t = e.target;
      if (!this.isAncestor(t)) {
        yield { edge: e, node: t, attachedAtSource: false };
      }
    }
    for (const e of this._inEdges) {
      const s = e.source;
      if (!this.isAncestor(s)) {
        yield { edge: e, node: s, attachedAtSource: true };
      }
    }
    for (const n of this.getNodesBreadthFirst()) {
      for (const e of n._outEdges) {
        const t = e.target;
        if (t === this)
          continue;
        if (!this.isAncestor(t)) {
          yield { edge: e, node: t, attachedAtSource: false };
        }
      }
      for (const e of n._inEdges) {
        const s = e.source;
        if (s === this)
          continue;
        if (!this.isAncestor(s)) {
          yield { edge: e, node: s, attachedAtSource: true };
        }
      }
    }
  }
  isAncestor(entity) {
    for (const ant of entity.getParents()) {
      if (ant === this) {
        return true;
      }
    }
    return false;
  }
  /**  Iterates over all connected components of the graph and for each component
   * returns all its nodes with "this" as the parent
   */
  // *getClusteredConnectedComponents(): IterableIterator<Array<Node>> {
  //   const processed = new Set<Node>()
  //   const q = new Queue<Node>()
  //   for (const v of this.getNodesBreadthFirst) {
  //     if (processed.has(v)) continue
  //     processed.add(v)
  //     q.enqueue(v)
  //     const component = new Set<Node>()
  //     do {
  //       const u = q.dequeue()
  //       if (u.parent === this) {
  //         component.add(u)
  //       }
  //       for (const w of this.reachableFrom(u)) {
  //         if (!processed.has(w)) {
  //           processed.add(w)
  //           q.enqueue(w)
  //         }
  //       }
  //     } while (q.length > 0)
  //     yield Array.from(component)
  //   }
  // }
  // private *reachableFrom(u: Node): IterableIterator<Node> {
  //   for (const e of u._outEdges) {
  //     yield e.target
  //   }
  //   for (const e of u._inEdges) {
  //     yield e.source
  //   }
  //   if (u instanceof Node) {
  //     yield* u.shallowNodes
  //   }
  //   if (u._parent != this) {
  //     yield u._parent as Node
  //   }
  // }
  // hasSomeAttrOnIndex(index: number): boolean {
  //   for (const n of this.getNodesBreadthFirst) {
  //     if (n.getAttr(index)) return true
  //   }
  //   for (const n of this.deepEdges) {
  //     if (n.getAttr(index)) return true
  //   }
  //   return false
  // }
  *graphs() {
    for (const g of this._children.graphs) {
      yield g;
    }
  }
  noEmptySubgraphs() {
    for (const g of this.subgraphsBreadthFirst()) {
      if (g.getShallowNodeCount() === 0)
        return false;
    }
    return true;
  }
  hasSubgraphs() {
    for (const n of this.getShallowNodes())
      if (n instanceof Node)
        return true;
    return false;
  }
  /** iterates breadth first  */
  *subgraphsBreadthFirst() {
    for (const n of this.getNodesBreadthFirst()) {
      if (n instanceof Node)
        yield n;
    }
  }
  isEmpty() {
    return this.getShallowNodeCount() === 0;
  }
  addEdge(sourceUri, targetUri, props) {
    return this.setEdge(sourceUri, targetUri, props);
  }
  setEdge(sourceUri, targetUri, props) {
    const s = this.findChildNodeByUri(sourceUri);
    if (s == null) {
      console.log("source not found", sourceUri);
      return;
    }
    const t = this.findChildNodeByUri(targetUri);
    if (t == null) {
      console.log("target not found", targetUri);
      return;
    }
    return new Edge({
      ...props,
      ...{ source: s, target: t }
    });
  }
  /** iterates breadth first  */
  *getNodesBreadthFirst_() {
    for (const n of this._children.nodesShallow) {
      yield n;
      if (n instanceof Node) {
        yield* n.getNodesBreadthFirst();
      }
    }
  }
  findEdgeRecursive(id) {
    for (const e of this.getDeepEdges()) {
      if (e.id === id)
        return e;
    }
    return null;
  }
  /**
   * Finds the node with the givin id belonging to a graph or one of its subgraphs.
   */
  findNodeRecursive(versionUri) {
    const n = this._children.findShallow(versionUri);
    if (n) {
      return n;
    }
    for (const g of this.getShallowNodes()) {
      if (g instanceof Node) {
        const nn = g.findNodeRecursive(versionUri);
        if (nn)
          return nn;
      }
    }
    return null;
  }
  /** Returns a node belonging to this graph having the same id.
   * If a node with the given id belongs to a subgraph than it would no be returned.
   * To find such a deeper nested node use findNodeRecursive
   */
  // Do NOT use the id in the graph, use the uri and versionUri
  // findChildNode(id: string): Node | undefined {
  //   return this._children.findShallow(id);
  // }
  findChildNodeByUri(uri) {
    return this._children.findShallow(uri);
  }
  findChildNodeByVersionUri(uri) {
    return this._children.findShallow(uri);
  }
  // findNodeByUriRecursive(uri: string): Node | undefined {
  //   const rootNode = this.findRootNode();
  //   const children = rootNode.getAllNodes();
  //   return children.find(child => child.getUri() === uri);
  // }
  // findNodeByTypeRecursive(type: string): Node | undefined {
  //   const rootNode = this.findRootNode();
  //   const children = rootNode.getAllNodes();
  //   return children.find(child => child.type === type);
  // }
  *deepEdgesIt() {
    for (const node of this.getNodesBreadthFirst()) {
      for (const e of node._outEdges) {
        yield e;
      }
      for (const e of node._selfEdges) {
        yield e;
      }
      for (const e of node._inEdges) {
        if (!this.isAncestor(e.source))
          yield e;
      }
    }
  }
  isConsistent() {
    if (this._parent)
      return this._parent.isConsistent();
    return this.eachNodeIdIsUnique() && this._children.isConsistent();
  }
  nodeIsConsistent(n) {
    return this._children.nodeIsConsistent(n);
  }
  // removeAllNodes() {
  //   for (const n of this.getAllNodes()) {
  //     this.removeNode(n);
  //   }
  // }
  // removeNodes(nodes: Node[]) {
  //   for (const n of nodes) {
  //     this.removeNode(n);
  //   }
  // }
  /** Detouches all the node's edges and removes the node from the graph.
   * This method does not change the parent of the node.
   */
  removeNode(node) {
    for (const e of node._outEdges) {
      e.target._inEdges.delete(e);
    }
    for (const e of node._inEdges) {
      e.source._outEdges.delete(e);
    }
    this._children.remove(node);
    for (const p of this.subgraphsBreadthFirst()) {
      p.removeNode(node);
    }
  }
  addNodes(nodes) {
    for (const node of nodes) {
      this.addNode(node);
    }
  }
  /** adds a node to the graph */
  addNode(n) {
    n._parent = this;
    n._parentId = this.getVersionUri();
    this._children.addNode(n);
    return n;
  }
  // If n has an ancestor which is the graph child then return it.
  // Otherwise return null
  liftNode(n) {
    while (n != null && n._parent !== this) {
      n = n._parent;
    }
    return n;
  }
  eachNodeIdIsUnique() {
    const ids = /* @__PURE__ */ new Set();
    for (const n of this.getNodesBreadthFirst()) {
      if (ids.has(n.id)) {
        return false;
      }
      ids.add(n.id);
    }
    return true;
  }
  /** returns all the nodes under graph and the edges with at least one end adjacent to the graph */
  // *allElements(): IterableIterator<Node> {
  //   for (const n of this.allSuccessorsWidthFirst()) {
  //     yield n
  //     for (const e of n._selfEdges) {
  //       yield e
  //     }
  //     for (const e of n._outEdges) {
  //       yield e
  //     }
  //     for (const e of n._inEdges) {
  //       if (!this.isAncestor(e.source)) {
  //         yield e
  //       }
  //     }
  //   }
  //   yield* this.edges // uses get edges() of Node
  // }
  *allSuccessorsWidthFirst() {
    for (const n of this.getShallowNodes()) {
      yield n;
    }
    for (const n of this.getShallowNodes()) {
      if (n instanceof Node) {
        yield* n.allSuccessorsWidthFirst();
      }
    }
  }
  *allSuccessorsDepthFirst() {
    for (const n of this.getShallowNodes()) {
      if (n instanceof Node) {
        yield* n.allSuccessorsDepthFirst();
      }
      yield n;
    }
  }
  static nodePropsToOrdinalKey(nodeProps) {
    return nodeProps.ordinal !== void 0 ? `${nodeProps.key}-${nodeProps.ordinal}` : nodeProps.key;
  }
  static nodePropsToUri(nodeProps) {
    const key = Node.nodePropsToOrdinalKey(nodeProps);
    return `${nodeProps.namespace}/${nodeProps.type}/${key}`;
  }
  static nodePropsToVersionUri(nodeProps) {
    const key = Node.nodePropsToOrdinalKey(nodeProps);
    let version = nodeProps.timing || nodeProps.timing === 0 ? nodeProps.timing : nodeProps.observedAt || "latest";
    return `${nodeProps.namespace}/${nodeProps.type}/${key}/${version}`;
  }
  static uriToNodeProps(uri, props) {
    const [namespace, type, key] = uri.split("/");
    return {
      ...props || {},
      namespace: namespace || DEFAULT_NAMESPACE,
      type: type || NODE_TYPE_DEFAULT,
      key: key || "missing"
    };
  }
  static versionUriToNodeUri(versionUri) {
    return versionUri.split("/").slice(0, 3).join("/");
  }
};

// src/synthetic/epd-list.ts
var lcaSteps = ["A1A3", "A4", "A5", "C1", "C2", "C3", "D"];
var defaultGlulamEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161],
  biogenicCarbonProduct: [1, 1, 1, 1, 1, 1, 1],
  epFreshWater: [1, 1, 1, 1, 1, 1, 1],
  epMarine: [1, 1, 1, 1, 1, 1, 1],
  componentsForReuse: [1, 1, 1, 1, 1, 1, 1],
  biogenicCarbonPackaging: [1, 1, 1, 1, 1, 1, 1],
  netUseOfFreshWater: [1, 1, 1, 1, 1, 1, 1],
  ODP: [1, 1, 1, 1, 1, 1, 1],
  AP: [1, 1, 1, 1, 1, 1, 1],
  EPfreshwater: [1, 1, 1, 1, 1, 1, 1],
  EPmarine: [1, 1, 1, 1, 1, 1, 1],
  EPterrestial: [1, 1, 1, 1, 1, 1, 1],
  POCP: [1, 1, 1, 1, 1, 1, 1],
  ADPm_m: [1, 1, 1, 1, 1, 1, 1],
  ADPfossil: [1, 1, 1, 1, 1, 1, 1],
  WDP: [1, 1, 1, 1, 1, 1, 1],
  RPEE: [1, 1, 1, 1, 1, 1, 1],
  RPEM: [1, 1, 1, 1, 1, 1, 1],
  TPE: [1, 1, 1, 1, 1, 1, 1],
  NRPE: [1, 1, 1, 1, 1, 1, 1],
  NRPM: [1, 1, 1, 1, 1, 1, 1],
  TRPE: [1, 1, 1, 1, 1, 1, 1],
  SM: [1, 1, 1, 1, 1, 1, 1],
  RSF: [1, 1, 1, 1, 1, 1, 1],
  NRSF: [1, 1, 1, 1, 1, 1, 1],
  W: [1, 1, 1, 1, 1, 1, 1],
  HW: [1, 1, 1, 1, 1, 1, 1],
  NHW: [1, 1, 1, 1, 1, 1, 1],
  RW: [1, 1, 1, 1, 1, 1, 1],
  CR: [1, 1, 1, 1, 1, 1, 1],
  MR: [1, 1, 1, 1, 1, 1, 1],
  MER: [1, 1, 1, 1, 1, 1, 1],
  EEE: [1, 1, 1, 1, 1, 1, 1],
  ETE: [1, 1, 1, 1, 1, 1, 1]
  // biogenicCarbonProduct: ,
  // epFreshWater: ,
  // epMarine: ,
  // componentsForReuse: ,
  // biogenicCarbonPackaging: ,
  // netUseOfFreshWater: ,
  // GWP-total
  // GWP-fossil
  // GWP-biogenic
  // GWP-LULUC
  // GWP-IOBC/GHG
  // ODP
  // AP
  // EP-freshwater
  // EP-marine
  // EP-terrestial
  // POCP
  // ADP-m&m
  // ADP-fossil
  // WDP
  //RPEE
  //RPEM
  //TPE
  //NRPE
  //NRPM
  //TRPE
  //SM
  //RSF
  //NRSF
  //W
  // HW
  // NHW
  // RW
  // CR
  // MR
  // MER
  // EEE
  // ETE
  //   Biogenic carbon content in product
  // Biogenic carbon content in the accompanying packaging
};
var concrete = {
  gwpTotal: [351 + 34 + 2.95, 19.5, 0, 0, 0, 0, 0],
  // 3.9 / 20 = 0.195 * 100 = 19.5
  gwpBiogenic: [0, 0, 0, 0, 0, 0, 0]
};
var concretePrefab = {
  gwpTotal: [398.4, 14.5, 0, 0, 0, 0, 0],
  gwpBiogenic: [0, 0, 0, 0, 0, 0, 0]
};
var concreteGreenSkanska = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var concreteExtraAggressiveEnvironmentalUnicorn = {
  gwpTotal: [351 + 34 + 2.95, 3.92],
  //, 3.84, 1.16e-1, 1.04, 7.51e2, 1.02e2],
  gwpFossil: [350 + 34 + 2.93, 3.92],
  gwpBiogenic: [-47.7 + -7470 + -233, 1680],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var concreteColumnNcg = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var lvlEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var pavaflexEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var bioProfileEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var boardsEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var plywoodEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var sipEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var woodenWallEpd = {
  gwpTotal: [-674, 2.68, 3.84, 0.116, 1.04, 751, 102],
  gwpFossil: [52.4, 2.68, 2.82, 0.115, 1.03, 0.118, -188],
  gwpBiogenic: [-744, 0, 0.326, 359e-6, 32e-4, 751, 290],
  gwpLuluc: [0.365, 555e-10, 0.0194, 644e-6, 575e-5, 295e-6, 0],
  gwpGhg: [52.7, 2.76, 2.84, 0.116, 1.04, 0.12, -161]
};
var epdList = [
  {
    id: "glulam",
    name: "Glulam EPD Moelven",
    lcaSteps,
    epdTable: defaultGlulamEpd
  },
  {
    id: "glulam-gl30c-spruce-planed",
    name: "Glulam GL30C Spruce planed",
    lcaSteps,
    epdTable: defaultGlulamEpd
  },
  {
    id: "lvl",
    name: "LVL",
    lcaSteps,
    epdTable: lvlEpd
  },
  {
    id: "pavaflex",
    name: "Pavaflex wood fiber insulation",
    lcaSteps,
    epdTable: pavaflexEpd
  },
  {
    id: "bio-profile",
    name: "Bio-based facade profiles",
    lcaSteps,
    epdTable: bioProfileEpd
  },
  {
    id: "boards",
    name: "Boards",
    lcaSteps,
    epdTable: boardsEpd
  },
  {
    id: "plywood",
    name: "Plywood",
    lcaSteps,
    epdTable: plywoodEpd
  },
  {
    id: "sip",
    name: "SIP",
    lcaSteps,
    epdTable: sipEpd
  },
  {
    id: "wooden-wall",
    name: "Wooden interior wall",
    lcaSteps,
    epdTable: woodenWallEpd
  },
  {
    id: "concrete",
    name: "Concrete",
    lcaSteps,
    epdTable: concrete
  },
  {
    id: "concrete-prefab",
    name: "Concrete prefab",
    lcaSteps,
    epdTable: concretePrefab
  },
  {
    id: "concrete-green-skanska",
    name: "Concrete Green Skanska",
    lcaSteps,
    epdTable: concreteGreenSkanska
  },
  {
    id: "concrete-extra-aggressive-environmental-unicorn",
    name: "Concrete Extra Aggressive Environmental Unicorn",
    lcaSteps,
    epdTable: concreteExtraAggressiveEnvironmentalUnicorn
  },
  {
    id: "concrete-column-ncg",
    name: "Concrete column NCG",
    lcaSteps,
    epdTable: concreteColumnNcg
  }
];

// src/synthetic/synthetic-node.ts
import { random } from "canvas-sketch-util";
var SyntheticNode = class extends Node {
  _seeder;
  _syntheticProps;
  // the props.synthetic has the input params that should generate the syntheticNodeProps
  // the first time the node is created the syntheticProps are used to generate the root node
  // the following times the syntheticProps are generated by this class to populate the graph
  constructor(props, syntheticProps) {
    super(props);
    const seed = syntheticProps.seed;
    this._seeder = random.createRandom(seed);
    this._syntheticProps = syntheticProps;
    this.generateSyntheticProperties(this, syntheticProps);
    this.generateChildNodes();
  }
  generateChildNodes() {
    const parentId = this.getId();
    for (const childSyntheticProps of this._syntheticProps?.syntheticChildren || []) {
      const {
        amountMin = 1,
        amountMax = 1,
        overrideChildrenNodeProps
      } = childSyntheticProps;
      if (overrideChildrenNodeProps) {
        for (const overrideNodeProps of overrideChildrenNodeProps) {
          this.addNode(new Node(overrideNodeProps));
        }
      } else {
        const key = childSyntheticProps.key;
        const amount = this.getIntegerValue(amountMin, amountMax);
        const seed = this._syntheticProps.seed;
        const seedNumbers = seed ? generateRandomSeedNumbers(
          `${parentId}-${key}-${this.type}-${seed}`,
          amount
        ) : void 0;
        for (let i = 0; i < amount; i++) {
          const clonedSynthetic = JSON.parse(
            JSON.stringify(childSyntheticProps)
          );
          clonedSynthetic.seed = seedNumbers ? seedNumbers[i] : void 0;
          const type = clonedSynthetic.type || NODE_TYPE_SYNTHETIC;
          clonedSynthetic.index = i;
          const nodeProps = {
            key: clonedSynthetic.key ? `${clonedSynthetic.key}-${parentId}-${i + 1}` : `${type}-${parentId}-${i + 1}`,
            type,
            parentId,
            boardId: this._props.boardId,
            namespace: this._props.namespace,
            name: {
              name: clonedSynthetic.name ? `${clonedSynthetic.name}-${i + 1}` : `${type}-${i + 1}`
            }
          };
          const childNode = new SyntheticNode(nodeProps, clonedSynthetic);
          this.addNode(childNode);
        }
      }
    }
  }
  // generateChildNodesOld() {
  //   const childNodes: Node[] = [];
  //   // per type
  //   const childNodeMap: NodeMap = {};
  //   for (const childNodeProps of this._syntheticChildren || []) {
  //     // generate the amount of child nodes
  //     const { amountMin = 1, amountMax = 1 } = childNodeProps;
  //     const amount = this.getIntegerValue(amountMin, amountMax);
  //     const childNodesPerSpec: Node[] = [];
  //     for (let i = 0; i < amount; i++) {
  //       // this will add the properties
  //       const childNode = this.generateChildNode(childNodeProps, i);
  //       childNodesPerSpec.push(childNode);
  //     }
  //     childNodes.push(childNodesPerSpec);
  //   }
  //   // now add the relations
  //   for (let i = 0; i < (this._syntheticChildren || []).length; i++) {
  //     const childNodeSpec = this._syntheticChildren[i];
  //     const { type, syntheticRelations } = childNodeSpec;
  //     if (syntheticRelations) {
  //       const childNodesPerSpec = childNodes[i];
  //       for (const childNode of childNodesPerSpec) {
  //         for (const syntheticRelation of syntheticRelations) {
  //           const { relationKey, targetTypes, carrierNodes } =
  //             syntheticRelation;
  //           const targetNode = this.getRandomChild(random.pick(targetTypes));
  //           if (targetNode) {
  //             const relation: NodeRelation = {
  //               targetId: targetNode.id,
  //               targetKey: targetNode.key,
  //               relationKey,
  //               type: NODE_TYPE_RELATION,
  //             };
  //             childNode.setSerializedRelation(relation);
  //             // if (carrierNodes) {
  //             //   for (const carrierNode of carrierNodes) {
  //             //     this.addCarrierNodes(childNode, relation, carrierNode);
  //             //   }
  //             // }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return childNodes;
  // }
  generateSyntheticProperties(node, props) {
    const syntheticProperties = props.syntheticProperties || [];
    for (const syntheticProperty of syntheticProperties || []) {
      const { propertyKey, propertyType, value, stringPrefix } = syntheticProperty;
      if (propertyType === "gaussianList") {
        const { mean, std, catalog } = this._seeder.pick(
          syntheticProperty.gaussianListSettings
        );
        node.setPropertyValue(PROPERTY_KEY_CATALOG, catalog);
        const generatedValue = node.generatePropertyValue({
          propertyKey,
          propertyType: "gaussian",
          mean,
          std
        });
        node.setPropertyValue(propertyKey, generatedValue);
      } else if (propertyKey === "name" && stringPrefix && !value) {
        const generatedValue = node.generatePropertyValue({
          ...syntheticProperty,
          value: `${stringPrefix}${props.index + 1}`
        });
        node.setPropertyValue(propertyKey, generatedValue);
        node._props.name = generatedValue;
      } else if (propertyKey === "observedAt") {
        const generatedValue = node.generatePropertyValue(syntheticProperty);
        node._props.observedAt = generatedValue;
      } else {
        const generatedValue = node.generatePropertyValue(syntheticProperty);
        node.setPropertyValue(propertyKey, generatedValue);
      }
    }
  }
  getChildNodeId() {
    return this.getIntegerValue(0, 1e9);
  }
  // go through this function used for carriers
  generateChildNode(clonedProps, i, parent = this) {
    const { type } = clonedProps;
    clonedProps.index = i;
    clonedProps.key = clonedProps.key || `${type}-${i + 1}`;
    clonedProps.name = clonedProps.name || `${type}-${i + 1}`;
    const childNode = new SyntheticNode(
      {
        key: clonedProps.key,
        type: clonedProps.type,
        boardId: clonedProps.boardId,
        name: { name: clonedProps.name },
        namespace: clonedProps.namespace
      },
      clonedProps
    );
    parent.addNode(childNode);
    return childNode;
  }
  // generateChildNode(
  //   type: string,
  //   index: number,
  //   propertySpecifications: SyntheticProperty[]
  // ): Node {
  //   const childNodeData = {
  //     id: `${type}-${index + 1}`,
  //     type,
  //   } as NodeProps;
  //   const childNode = new Node(childNodeData);
  //   this.generateSyntheticProperties(childNode, propertySpecifications);
  //   return childNode;
  // }
  generatePropertyValue(propertySpecification) {
    const {
      propertyType,
      propertyKey,
      min = 0,
      max = 100,
      mean,
      std,
      chance,
      categories,
      categoryDistribution,
      stringPrefix = "",
      value,
      startYear,
      endYear,
      startMonth,
      endMonth,
      startDay,
      endDay,
      startHour,
      endHour,
      startMinute,
      endMinute
    } = propertySpecification;
    if (value || value === 0 || value === false || value === "") {
      return value;
    }
    let generatedValue;
    switch (propertyType) {
      case "string":
        generatedValue = `${stringPrefix}${this.getIntegerValue(min, max).toString()}`;
        break;
      case "integer":
        generatedValue = this.getIntegerValue(min, max);
        break;
      case "float":
        generatedValue = this.getFloatValue(min, max);
        break;
      case "boolean":
        generatedValue = this.getBooleanValue();
        break;
      case "gaussian":
        generatedValue = this._seeder.gaussian(mean, std);
        break;
      case "date":
        generatedValue = this.getDate(
          startYear,
          endYear,
          startMonth,
          endMonth,
          startDay,
          endDay,
          startHour,
          endHour,
          startMinute,
          endMinute
        );
        break;
      case "category":
        if (categoryDistribution) {
          const index = this._seeder.weighted(categoryDistribution);
          generatedValue = categories[index];
        } else {
          generatedValue = this._seeder.pick(categories || []);
        }
        break;
      default:
        break;
    }
    return generatedValue;
  }
  getIntegerValue(min, max) {
    if (min === max) {
      return min;
    }
    const val = Math.round(this._seeder.range(min, max));
    return val;
  }
  getFloatValue(min, max) {
    const val = this._seeder.range(min, max);
    return val;
  }
  getBooleanValue(chance) {
    if (chance) {
      return this._seeder.chance(chance);
    }
    return this._seeder.boolean();
  }
  getNoiseValue(x, frequency = 0.2, amplitude = 10) {
    const val = this._seeder.noise1D(x, frequency, amplitude);
    return val + amplitude;
  }
  getNoiseValue2D(x, y) {
    const amplitude = 10;
    const val = this._seeder.noise2D(x, y, 0.2, amplitude);
    return val + amplitude;
  }
  getDate(startYear = 2e3, endYear = 2022, startMonth = 0, endMonth = 11, startDay = 1, endDay = 31, startHour = 1, endHour = 24, startMinute = 0, endMinute = 59, startSecond = 0, endSecond = 59) {
    const date = /* @__PURE__ */ new Date();
    date.setFullYear(this.getIntegerValue(startYear, endYear));
    date.setMonth(this.getIntegerValue(startMonth, endMonth));
    date.setDate(this.getIntegerValue(startDay, endDay));
    date.setHours(this.getIntegerValue(startHour, endHour));
    date.setMinutes(this.getIntegerValue(startMinute, endMinute));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  }
  createEdgeNodeName(sourceNode, targetNode) {
    return `${sourceNode._key} -> ${targetNode._key}`;
  }
  generateSyntheticRelations(node = this) {
    if (node._syntheticProps?.syntheticRelations) {
      for (const syntheticRelation of node._syntheticProps?.syntheticRelations) {
        const { relationKey, targetUris, targetTypes, carrierNodes } = syntheticRelation;
        if (!targetUris && !targetTypes) {
          throw "one of targetUris or targetTypes must be defined";
        }
        let targetType;
        let targetNode;
        if (targetUris) {
          const targetUri = this._seeder.pick(targetUris);
          targetNode = node._parent.findChildNodeByUri(targetUri);
          if (!targetNode) {
            throw "no target node found with this uri";
          }
          const edgeId = Edge.createId(node, targetNode, relationKey);
          const existingEdge = node._parent.findEdge(edgeId);
          if (!existingEdge) {
            node._props.relations = node._props.relations || {};
            node._props.relations[relationKey] = node._props.relations[relationKey] || [];
            node._props.relations[relationKey].push(targetNode.getVersionUri());
          }
        } else if (targetTypes) {
          targetType = this._seeder.pick(targetTypes);
          targetNode = node.getRandomSibling(targetType);
          if (!targetNode) {
            targetNode = new Node({
              key: `${targetType}-temp-for-${node._props.key}`,
              type: targetType,
              boardId: node._props.boardId,
              namespace: node._props.namespace
            });
          }
          const edgeId = Edge.createId(node, targetNode, relationKey);
          const existingEdge = node._parent.findEdge(edgeId);
          if (!existingEdge) {
            node._props.relations = node._props.relations || {};
            node._props.relations[relationKey] = node._props.relations[relationKey] || [];
            node._props.relations[relationKey].push(targetNode.getVersionUri());
          }
        }
      }
    }
    const children = node.getChildren().filter((n) => n instanceof SyntheticNode);
    for (const child of children) {
      this.generateSyntheticRelations(child);
    }
  }
  // need to run this after the first run when all nodes are generated since the carriers have id reference to the source and target
  generateCarrierNodes(relation, targetNodes, sourceNodes) {
    const { carrierNodes, relationKey } = relation;
    const edgeNodeMap = {};
    for (const carrierNodeProps of carrierNodes || []) {
      const { amountMin, amountMax } = carrierNodeProps;
      const amount = this.getIntegerValue(amountMin, amountMax);
      for (let i = 0; i < amount; i++) {
        const sourceNode = sourceNodes ? this._seeder.pick(sourceNodes) : this;
        const parent = sourceNode._parent;
        if (!parent) {
          throw new Error("no parent node for carriers ");
        }
        const targetNode = this._seeder.pick(targetNodes);
        if (!targetNode) {
          for (const targetNode2 of targetNodes) {
            console.log(targetNode2._key, targetNode2._type);
          }
          throw new Error("no target node for carriers ");
        }
        const edgeId = Edge.createId(sourceNode, targetNode, relationKey);
        const edgeNodeId = Node.createEdgeNodeId(
          sourceNode.getUri(),
          targetNode.getUri()
        );
        const existingEdge = parent.findEdge(edgeId);
        if (!edgeNodeMap[edgeNodeId] && !existingEdge) {
          sourceNode.addRelation(relationKey, targetNode);
          const edgeNode = new Node({
            key: edgeNodeId,
            type: NODE_TYPE_CARRIER,
            name: { name: this.createEdgeNodeName(sourceNode, targetNode) },
            namespace: this._namespace,
            boardId: this._boardId
            // sourceUri: sourceNode.getUri(),
            // targetUri: targetNode.getUri(),
          });
          edgeNodeMap[edgeNodeId] = edgeNode;
          parent.addNode(edgeNode);
          let edge = parent.findEdge(edgeId);
          if (!edge) {
            parent.addEdge(edgeId, sourceNode, targetNode, relationKey);
            edge = parent.findEdge(edgeId);
          }
          edgeNode._carrierEdge = edge;
          edge._carrier = edgeNode;
        }
        const carrierNodeParent = edgeNodeMap[edgeNodeId];
        sourceNode.generateChildNode(carrierNodeProps, i, carrierNodeParent);
      }
    }
  }
  // after the node is generated, the estimated style can be generated
  generateStyle(nodeProps) {
    let image = this.getImageFromProperties();
    if (!image) {
      image = this.getImageFromType();
    }
    const nodeStyle = {};
    if (image) {
      nodeStyle.imageUrl = `${ASSET_URL}/${image}`;
    }
    const color = this.getColorFromType();
    if (color) {
      nodeStyle.fillColor = color.fillColor;
      nodeStyle.strokeColor = color.strokeColor;
    }
    const size = nodeProps?.appearance?.size || [64, 64];
    const position = nodeProps?.appearance?.position || [42, 42, 0];
    nodeStyle.size = size;
    nodeStyle.position = position;
    return nodeStyle;
  }
  getImageFromProperties() {
    const properties = this.getProperties();
    const value = properties[PROPERTY_KEY_CATALOG];
    if (properties[PROPERTY_KEY_CATALOG] && catalogToImageMap[`${PROPERTY_KEY_CATALOG}:${value}`]) {
      const catalogImages = catalogToImageMap[`${PROPERTY_KEY_CATALOG}:${value}`];
      return this._seeder.pick(catalogImages);
    }
    return void 0;
  }
  getImageFromType() {
    if (typeToImageMap[this._type]) {
      const typeImages = typeToImageMap[this._type];
      return this._seeder.pick(typeImages);
    }
    return void 0;
  }
  getColorFromType() {
    if (typeToColorMap[this._type]) {
      return typeToColorMap[this._type];
    }
    return void 0;
  }
  getRandomSibling(type) {
    const siblings = type ? this._parent.getNodesFromRootRecursiveByType(type) : this._parent.getChildren();
    return this._seeder.pick(siblings);
  }
  getRandomChild(type) {
    const children = type ? this.getChildrenByType(type) : this.getChildren();
    return this._seeder.pick(children);
  }
  getRandomChildren(num, type) {
    const children = type ? this.getChildrenByType(type) : this.getChildren();
    return Array(num).fill(null).map(() => this._seeder.pick(children));
  }
};

// src/synthetic/modules/synthetic-module.ts
var SyntheticModule = class {
  // this is a cache to update if the inputParams change
  // cubes: SyntheticCube[] = [];
  syntheticNode;
  constructor(parentNodeProps, existingChildNodeProps) {
    this.generateSyntheticData(parentNodeProps, existingChildNodeProps);
  }
  // For each module: deal with how content in synthetic node can generate SyntheticCube[]
  // abstract generateSyntheticCubes(insight?: BucketInsight): SyntheticCube[];
  // the module is a "room" or "project" with nodes inside
  generateSyntheticData(parentNodeProps, existingChildNodeProps) {
    const syntheticNodeProps = this.getSyntheticNodePropsFromSyntheticInputParams(
      parentNodeProps,
      existingChildNodeProps
    );
    this.syntheticNode = new SyntheticNode(parentNodeProps, syntheticNodeProps);
    this.syntheticNode.generateSyntheticRelations();
  }
};

// src/product-data.ts
var products = [
  // {
  //   key: 'glulam',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Glulam',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 462,
  //     epdId: 'glulam',
  //   },
  // },
  // {
  //   key: 'spruce-boards',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Spruce boards',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 462,
  //     epdId: 'boards',
  //   },
  // },
  // {
  //   key: 'spruce-logs',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Spruce logs',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 462,
  //     epdId: 'boards',
  //   },
  // },
  // {
  //   key: 'lvl',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'LVL',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 500,
  //     epdId: 'lvl',
  //   },
  // },
  // {
  //   key: 'woodfiber-insulation',
  //   name: 'Pavaflex insulation',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 55,
  //     epdId: 'pavaflex',
  //   },
  // },
  // // facade
  // {
  //   key: 'bio-profile',
  //   name: 'Bio-based facade profiles',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 700,
  //     epdId: 'bio-profile',
  //   },
  // },
  // {
  //   key: 'wood-panel',
  //   name: 'Facade panel + cladding',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 470,
  //     epdId: 'boards',
  //   },
  // },
  // {
  //   key: 'plywood',
  //   name: 'Facade plywood',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 500,
  //     epdId: 'plywood',
  //   },
  // },
  // {
  //   key: 'sip',
  //   name: 'Facade SIP panels',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 470,
  //     epdId: 'sip',
  //   },
  // },
  // // roof
  // {
  //   key: 'cladding',
  //   name: 'Roof cladding',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 470,
  //     epdId: 'boards',
  //   },
  // },
  // {
  //   key: 'partition',
  //   name: 'Wooden interior wall',
  //   type: TYPE_CATALOG_PRODUCT,
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 470,
  //     epdId: 'wooden-wall',
  //   },
  // },
  // // {
  // //   key: 'partition',
  // //   name: 'Building separation walls',
  // //   type: TYPE_CATALOG_PRODUCT,
  // //   namespace: DEFAULT_NAMESPACE,
  // //   boardId: DEFAULT_PROJECT_ID,
  // //   properties: {
  // //     weightPerM3: 470,
  // //     epdId: 'wooden-wall',
  // //   },
  // // },
  // // {
  // //   key: 'partition',
  // //   name: 'General walls',
  // //   type: TYPE_CATALOG_PRODUCT,
  // //   namespace: DEFAULT_NAMESPACE,
  // //   boardId: DEFAULT_PROJECT_ID,
  // //   properties: {
  // //     weightPerM3: 470,
  // //     epdId: 'wooden-wall',
  // //   },
  // // },
  // // {
  // //   key: 'glulam-gl28c-spruce-planed',
  // //   type: TYPE_CATALOG_PRODUCT,
  // //   name: 'Glulam GL28C Spruce planed',
  // //   namespace: DEFAULT_NAMESPACE,
  // //   boardId: DEFAULT_PROJECT_ID,
  // //   properties: {
  // //     weightPerM3: 462,
  // //     epdId: 'glulam',
  // //   },
  // // },
  // {
  //   key: 'concrete',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Concrete, C40/C50',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 2400,
  //     epdId: 'concrete',
  //   },
  // },
  // {
  //   key: 'concrete-prefab',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Concrete beam - prefab',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 2400,
  //     epdId: 'concrete-prefab',
  //   },
  // },
  // {
  //   key: 'concrete-column-ncg',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Concrete column, C40/C50, NGC',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 2400,
  //     epdId: 'concrete-column-ncg',
  //   },
  // },
  // {
  //   key: 'concrete-green-skanska',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Concrete Green Skanska',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 2400,
  //     epdId: 'concrete-green-skanska',
  //   },
  // },
  // {
  //   key: 'concrete-extra-aggressive-environmental-unicorn',
  //   type: TYPE_CATALOG_PRODUCT,
  //   name: 'Concrete extra aggressive environmental, C40/C50, Unicorn',
  //   namespace: DEFAULT_NAMESPACE,
  //   boardId: DEFAULT_PROJECT_ID,
  //   properties: {
  //     weightPerM3: 2400,
  //     epdId: 'concrete-extra-aggressive-environmental-unicorn',
  //   },
  // },
];
var getProductDictionary = () => {
  const translations = products.reduce((dictionary, product) => {
    dictionary[product.key] = product.name;
    return dictionary;
  }, {});
  return translations;
};
var getEpd = (component) => {
  const product = products.find((product2) => product2.key === component);
  const epdId = product?.properties?.epdId;
  if (!epdId) {
    return null;
  }
  const epd = epdList.find((epd2) => epd2.id === epdId);
  return epd;
};

// src/carrier-data.ts
var carriers = [
  {
    key: "carrier-1",
    type: "Vechile",
    name: "Semi-trailer, TT/AT 28-34 + 34-40t",
    namespace: DEFAULT_NAMESPACE,
    projectId: DEFAULT_PROJECT_ID,
    properties: {
      fuelPerKkm: 0.027,
      // this is part of the glulam epd! not related to the truck
      loadFactor: 0.45,
      maxLoad: 40
    }
  }
].map((c) => {
  c.versionUri = Node.nodePropsToVersionUri(c);
  return c;
});

// src/parametric/get-cubes-from-node-props.ts
var torebodaUri = `${DEFAULT_NAMESPACE}/${DEFAULT_PROJECT_ID}/${TYPE_GLULAM_FACTORY}/toreboda/latest`;
var sourthernDemoUri = `${DEFAULT_NAMESPACE}/${DEFAULT_PROJECT_ID}/${TYPE_BUILDING}/southern-demo/latest`;
var forestUriPath = `${DEFAULT_NAMESPACE}/${DEFAULT_PROJECT_ID}/${TYPE_FOREST}`;
function getNodePropsFromParametricInputParamsList(nodeProps) {
  const parametric = nodeProps.parametric;
  if (!parametric || !parametric.amounts) {
    return [];
  }
  const parametricInputItems = getParametricInputItemsFromParametricInputParamsList(parametric);
  const outNodeProps = [];
  for (const item of parametricInputItems) {
    const measures = getMeasuresFromParametricInputItem(item);
    const nodePropsWithoutParametric = { ...nodeProps };
    delete nodePropsWithoutParametric.parametric;
    const nodePropsCopy = {
      ...nodePropsWithoutParametric,
      properties: {
        ...item,
        ...measures
      }
    };
    if (item.sourceUri && item.targetUri) {
      nodePropsCopy.relations = {
        [RELATION_KEY_CARRIER_SOURCE]: [item.sourceUri],
        [RELATION_KEY_CARRIER_TARGET]: [item.targetUri]
      };
    }
    outNodeProps.push(nodePropsCopy);
  }
  return outNodeProps;
}
function getPropertiesFromParametric(nodeProps) {
  const parametric = nodeProps.parametric;
  if (!parametric || !parametric.amounts) {
    return nodeProps;
  }
  const parametricInputItems = getParametricInputItemsFromParametricInputParamsList(parametric);
  const aggregatedMeasures = parametricInputItems.reduce((acc, item) => {
    const measures = getMeasuresFromParametricInputItem(item);
    for (const [key, value] of Object.entries(measures)) {
      if (acc[key]) {
        acc[key] += value;
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
  nodeProps.properties = {
    ...nodeProps.properties,
    ...aggregatedMeasures
  };
  return nodeProps;
}
function getParametricInputItemsFromParametricInputParamsList(parametricInputParamsList) {
  const {
    seed,
    sourceUri,
    targetUri,
    batchIds,
    shipmentIds,
    carrierUris,
    shipmentDistances,
    descriptions,
    statuses,
    amounts,
    costs,
    costsStd,
    costsMin,
    costsMax,
    weights,
    weightsStd,
    weightsMin,
    weightsMax,
    depths,
    depthsStd,
    depthsMin,
    depthsMax,
    widths,
    widthsStd,
    widthsMin,
    widthsMax,
    lengths,
    lengthsStd,
    lengthsMin,
    lengthsMax,
    isSupply,
    components,
    startDates,
    endDates
  } = parametricInputParamsList;
  const parametricInputItems = [];
  if (!amounts) {
    return parametricInputItems;
  }
  for (let i = 0; i < amounts.length; i++) {
    parametricInputItems.push({
      seed,
      amount: amounts ? amounts[i] : 1,
      sourceUri,
      targetUri,
      batchId: batchIds ? batchIds[i] : void 0,
      shipmentId: shipmentIds ? shipmentIds[i] : void 0,
      carrierUri: carrierUris ? carrierUris[i] : void 0,
      shipmentDistance: shipmentDistances ? shipmentDistances[i] : void 0,
      description: descriptions ? descriptions[i] : void 0,
      status: statuses ? statuses[i] : void 0,
      cost: costs ? costs[i] : void 0,
      costStd: costsStd ? costsStd[i] : void 0,
      costMin: costsMin ? costsMin[i] : void 0,
      costMax: costsMax ? costsMax[i] : void 0,
      weight: weights ? weights[i] : void 0,
      weightStd: weightsStd ? weightsStd[i] : void 0,
      weightMin: weightsMin ? weightsMin[i] : void 0,
      weightMax: weightsMax ? weightsMax[i] : void 0,
      depth: depths ? depths[i] : void 0,
      depthStd: depthsStd ? depthsStd[i] : void 0,
      depthMin: depthsMin ? depthsMin[i] : void 0,
      depthMax: depthsMax ? depthsMax[i] : void 0,
      width: widths ? widths[i] : void 0,
      widthStd: widthsStd ? widthsStd[i] : void 0,
      widthMin: widthsMin ? widthsMin[i] : void 0,
      widthMax: widthsMax ? widthsMax[i] : void 0,
      length: lengths ? lengths[i] : void 0,
      lengthStd: lengthsStd ? lengthsStd[i] : void 0,
      lengthMin: lengthsMin ? lengthsMin[i] : void 0,
      lengthMax: lengthsMax ? lengthsMax[i] : void 0,
      isSupply: isSupply ? isSupply[i] : false,
      component: components ? components[i] : "component",
      startDate: startDates ? startDates[i] : void 0,
      endDate: endDates ? endDates[i] : void 0
    });
  }
  return parametricInputItems;
}
function getParametricInputParamsListFromParametricInputItems(parametricInputItems) {
  const amounts = parametricInputItems.map((item) => item.amount);
  const costs = parametricInputItems.map((item) => item.cost);
  const costsStd = parametricInputItems.map((item) => item.costStd);
  const costsMin = parametricInputItems.map((item) => item.costMin);
  const costsMax = parametricInputItems.map((item) => item.costMax);
  const weights = parametricInputItems.map((item) => item.weight);
  const weightsStd = parametricInputItems.map((item) => item.weightStd);
  const weightsMin = parametricInputItems.map((item) => item.weightMin);
  const weightsMax = parametricInputItems.map((item) => item.weightMax);
  const depths = parametricInputItems.map((item) => item.depth);
  const depthsStd = parametricInputItems.map((item) => item.depthStd);
  const depthsMin = parametricInputItems.map((item) => item.depthMin);
  const depthsMax = parametricInputItems.map((item) => item.depthMax);
  const widths = parametricInputItems.map((item) => item.width);
  const widthsStd = parametricInputItems.map((item) => item.widthStd);
  const widthsMin = parametricInputItems.map((item) => item.widthMin);
  const widthsMax = parametricInputItems.map((item) => item.widthMax);
  const lengths = parametricInputItems.map((item) => item.length);
  const lengthsStd = parametricInputItems.map((item) => item.lengthStd);
  const lengthsMin = parametricInputItems.map((item) => item.lengthMin);
  const lengthsMax = parametricInputItems.map((item) => item.lengthMax);
  const isSupply = parametricInputItems.map((item) => item.isSupply);
  const components = parametricInputItems.map((item) => item.component);
  const startDates = parametricInputItems.map((item) => item.startDate);
  const endDates = parametricInputItems.map((item) => item.endDate);
  const batchIds = parametricInputItems.map((item) => item.batchId);
  const shipmentIds = parametricInputItems.map((item) => item.shipmentId);
  const carrierUris = parametricInputItems.map((item) => item.carrierUri);
  const shipmentDistances = parametricInputItems.map(
    (item) => item.shipmentDistance
  );
  const descriptions = parametricInputItems.map((item) => item.description);
  const list = {
    amounts,
    costs,
    costsStd,
    costsMin,
    costsMax,
    weights,
    weightsStd,
    weightsMin,
    weightsMax,
    depths,
    depthsStd,
    depthsMin,
    depthsMax,
    widths,
    widthsStd,
    widthsMin,
    widthsMax,
    lengths,
    lengthsStd,
    lengthsMin,
    lengthsMax,
    isSupply,
    components,
    startDates,
    endDates,
    batchIds,
    shipmentIds,
    carrierUris,
    shipmentDistances,
    descriptions
  };
  const sourceUri = parametricInputItems.find(
    (item) => item.sourceUri
  )?.sourceUri;
  if (sourceUri) {
    list.sourceUri = sourceUri;
  }
  const targetUri = parametricInputItems.find(
    (item) => item.targetUri
  )?.targetUri;
  if (targetUri) {
    list.targetUri = targetUri;
  }
  return list;
}
function getAggregatedParametricInputParamsListFromNodeProps(nodePropsList) {
  if (nodePropsList.length === 0) {
    return void 0;
  }
  const parametricInputLists = nodePropsList.map((nodeProps) => nodeProps.parametric).filter(Boolean);
  const amounts = parametricInputLists.flatMap((list) => list.amounts || []);
  const costs = parametricInputLists.flatMap((list) => list.costs || []);
  const costsStd = parametricInputLists.flatMap((list) => list.costsStd || []);
  const costsMin = parametricInputLists.flatMap((list) => list.costsMin || []);
  const costsMax = parametricInputLists.flatMap((list) => list.costsMax || []);
  const weights = parametricInputLists.flatMap((list) => list.weights || []);
  const weightsStd = parametricInputLists.flatMap(
    (list) => list.weightsStd || []
  );
  const weightsMin = parametricInputLists.flatMap(
    (list) => list.weightsMin || []
  );
  const weightsMax = parametricInputLists.flatMap(
    (list) => list.weightsMax || []
  );
  const depths = parametricInputLists.flatMap((list) => list.depths || []);
  const depthsStd = parametricInputLists.flatMap((list) => list.depthsStd || []);
  const depthsMin = parametricInputLists.flatMap((list) => list.depthsMin || []);
  const depthsMax = parametricInputLists.flatMap((list) => list.depthsMax || []);
  const widths = parametricInputLists.flatMap((list) => list.widths || []);
  const widthsStd = parametricInputLists.flatMap((list) => list.widthsStd || []);
  const widthsMin = parametricInputLists.flatMap((list) => list.widthsMin || []);
  const widthsMax = parametricInputLists.flatMap((list) => list.widthsMax || []);
  const lengths = parametricInputLists.flatMap((list) => list.lengths || []);
  const lengthsStd = parametricInputLists.flatMap(
    (list) => list.lengthsStd || []
  );
  const lengthsMin = parametricInputLists.flatMap(
    (list) => list.lengthsMin || []
  );
  const lengthsMax = parametricInputLists.flatMap(
    (list) => list.lengthsMax || []
  );
  const isSupply = parametricInputLists.flatMap((list) => list.isSupply || []);
  const components = parametricInputLists.flatMap(
    (list) => list.components || []
  );
  const startDates = parametricInputLists.flatMap(
    (list) => list.startDates || []
  );
  const endDates = parametricInputLists.flatMap((list) => list.endDates || []);
  const batchIds = parametricInputLists.flatMap((list) => list.batchIds || []);
  const shipmentIds = parametricInputLists.flatMap(
    (list) => list.shipmentIds || []
  );
  const carrierUris = parametricInputLists.flatMap(
    (list) => list.carrierUris || []
  );
  const shipmentDistances = parametricInputLists.flatMap(
    (list) => list.shipmentDistances || []
  );
  const descriptions = parametricInputLists.flatMap(
    (list) => list.descriptions || []
  );
  const statuses = parametricInputLists.flatMap((list) => list.statuses || []);
  return {
    amounts,
    costs,
    costsStd,
    costsMin,
    costsMax,
    weights,
    weightsStd,
    weightsMin,
    weightsMax,
    depths,
    depthsStd,
    depthsMin,
    depthsMax,
    widths,
    widthsStd,
    widthsMin,
    widthsMax,
    lengths,
    lengthsStd,
    lengthsMin,
    lengthsMax,
    isSupply,
    components,
    startDates,
    endDates,
    batchIds,
    shipmentIds,
    carrierUris,
    shipmentDistances,
    descriptions,
    statuses
  };
}
function getMeasuresFromParametricInputItem(inputItem) {
  let {
    seed,
    amount,
    depth,
    depthMin,
    depthMax,
    depthStd,
    width,
    widthMin,
    widthMax,
    widthStd,
    weight,
    weightMin,
    weightMax,
    weightStd,
    length,
    lengthMin,
    lengthMax,
    lengthStd,
    cost,
    costMin,
    costMax,
    costStd
  } = inputItem;
  const syntheticNode = new SyntheticNode(
    {
      key: "temp",
      type: "temp",
      namespace: "temp",
      boardId: "temp"
    },
    {
      seed
    }
  );
  if (!depth) {
    if (depthMin && depthMax) {
      depth = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "depth",
        min: depthMin,
        max: depthMax
      });
    } else if (depthStd) {
      depth = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "depth",
        mean: depth || 1,
        std: depthStd
      });
    } else if (depth) {
      depth = depth;
    }
  }
  if (!width) {
    if (widthMin && widthMax) {
      width = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "width",
        min: widthMin,
        max: widthMax
      });
    } else if (widthStd) {
      width = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "width",
        mean: width || 1,
        std: widthStd
      });
    } else if (width) {
      width = width;
    }
  }
  if (!length) {
    if (lengthMin && lengthMax) {
      length = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "length",
        min: lengthMin,
        max: lengthMax
      });
    } else if (lengthStd) {
      length = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "length",
        mean: length || 1,
        std: lengthStd
      });
    } else if (length) {
      length = length;
    }
  }
  if (!weight) {
    if (weightMin && weightMax) {
      weight = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "weight",
        min: weightMin,
        max: weightMax
      });
    } else if (weightStd) {
      weight = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "weight",
        mean: weight || 1,
        std: weightStd
      });
    } else if (weight) {
      weight = weight;
    }
  }
  if (!cost) {
    if (costMin && costMax) {
      cost = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "cost",
        min: costMin,
        max: costMax
      });
    } else if (costStd) {
      cost = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "cost",
        mean: cost || 1,
        std: costStd
      });
    } else if (cost) {
      cost = cost;
    }
  }
  return {
    depth: depth || 1,
    width: width || 1,
    length: length || 1,
    weight: (weight || 0) * (amount || 1),
    volume: (depth || 1) * (width || 1) * (length || 1) * (amount || 1),
    area: (width || 1) * (length || 1) * (amount || 1),
    cost: (cost || 0) * (amount || 1)
  };
}
function getPresets(item) {
  item.shipmentDistance = item.shipmentDistance || getDistancePreset(item) || 0;
  item.weight = item.weight || getWeightPreset(item) || 0;
}
function getSegmentsFromNodeProps(nodeProps) {
  let parametricItems;
  const cubes = [];
  if (nodeProps.parametric) {
    parametricItems = getParametricInputItemsFromParametricInputParamsList(
      nodeProps.parametric
    );
  }
  if (nodeProps.properties && nodeProps.properties.amount > 0 && !parametricItems?.length) {
    const {
      amount = 1,
      depth = 1,
      width = 1,
      length = 1,
      weight = 0,
      ...rest
    } = nodeProps.properties || {};
    parametricItems = [
      {
        amount,
        depth,
        width,
        length,
        weight,
        ...rest
      }
    ];
  }
  for (const item of parametricItems || []) {
    const { name, type } = nodeProps;
    const versionUri = Node.nodePropsToVersionUri(nodeProps);
    const {
      component = "component",
      description = "no description",
      isSupply = false,
      batchId = "1",
      shipmentId,
      carrierUri,
      shipmentDistance,
      startDate = (/* @__PURE__ */ new Date()).toISOString,
      endDate = (/* @__PURE__ */ new Date()).toISOString
    } = item;
    const propertiesFromParent = {
      versionUri,
      name,
      type,
      actor: name || type || "unknown"
    };
    const itemCubes = getCubesFromParametricInputParams(
      item,
      {
        ...propertiesFromParent,
        component,
        description,
        actor: name || type || "unknown",
        isSupply,
        batchId,
        shipmentId,
        carrierUri,
        shipmentDistance,
        startDate,
        endDate
      }
    );
    cubes.push(...itemCubes);
  }
  return cubes;
}
function getCubesFromNodeProps(nodeProps, nodePropsList, insightNodeProps) {
  let parametricItems;
  const cubes = [];
  for (const np of nodePropsList || []) {
    np.versionUri = Node.nodePropsToVersionUri(np);
  }
  if (nodeProps.parametric) {
    parametricItems = getParametricInputItemsFromParametricInputParamsList(
      nodeProps.parametric
    );
  }
  if (nodeProps.properties && nodeProps.properties.amount > 0 && !parametricItems?.length) {
    const {
      amount = 1,
      depth = 1,
      width = 1,
      length = 1,
      weight = 0,
      ...rest
    } = nodeProps.properties || {};
    parametricItems = [
      {
        amount,
        depth,
        width,
        length,
        weight,
        ...rest
      }
    ];
  }
  const shipmentValueMap = {};
  for (const item of parametricItems || []) {
    const { name, type } = nodeProps;
    const versionUri = Node.nodePropsToVersionUri(nodeProps);
    getPresets(item);
    const {
      component = "component",
      description = "no description",
      isSupply = false,
      batchId = "1",
      shipmentId,
      carrierUri,
      shipmentDistance,
      startDate = (/* @__PURE__ */ new Date()).toISOString,
      endDate = (/* @__PURE__ */ new Date()).toISOString
    } = item;
    const propertiesFromParent = {
      versionUri,
      name,
      type,
      actor: name || type || "unknown"
    };
    const shipmentValues = calculateShipmentValues(
      item,
      parametricItems,
      nodePropsList
    );
    shipmentValueMap[shipmentId] = {
      ...shipmentValues,
      ...propertiesFromParent
    };
    const itemCubes = getCubesFromParametricInputParams(
      item,
      {
        ...propertiesFromParent,
        component,
        description,
        actor: name || type || "unknown",
        isSupply,
        batchId,
        shipmentId,
        carrierUri,
        shipmentDistance,
        startDate,
        endDate,
        ...shipmentValues
      }
    );
    calculateGwpTotal(itemCubes, nodePropsList);
    calculateGwpFossil(itemCubes, nodePropsList);
    calculateGwpBiogenic(itemCubes, nodePropsList);
    calculateGwpLuluc(itemCubes, nodePropsList);
    calculateGwpGhg(itemCubes, nodePropsList);
    calculateNumTrees(itemCubes, nodePropsList);
    cubes.push(...itemCubes);
  }
  const measurementCubes = getCubesByMeasurementFromComponentCubes(
    cubes,
    insightNodeProps?.chart || nodeProps.chart
  );
  const aggregationCubes = getCubesByAggregation(
    measurementCubes,
    insightNodeProps?.chart || nodeProps.chart,
    shipmentValueMap
  );
  return aggregationCubes;
}
function getCubesByMeasurementFromComponentCubes(cubes, insight) {
  if (!insight || !insight.cubeUnit && !insight.cubeComponent || insight.cubeUnit === "amount" && insight.cubeComponent === "component") {
    return cubes;
  }
  const measurementCubes = [];
  const cubeUnit = insight.cubeUnit;
  if (!cubeUnit || cubeUnit === "amount") {
    return cubes;
  }
  const totalMeasure = cubes.reduce(
    (acc, cube) => acc + (cube[cubeUnit] || 0),
    0
  );
  if (totalMeasure === 0) {
    return cubes;
  }
  const measurePerCube = calculateResolution(totalMeasure);
  const aggregation = insight.cubeComponent || "component";
  const cubePerAggregation = cubes.reduce((acc, cube) => {
    if (!acc[cube[aggregation]]) {
      acc[cube[aggregation]] = [];
    }
    acc[cube[aggregation]].push(cube);
    return acc;
  }, {});
  for (const agg in cubePerAggregation) {
    const aggCubes = cubePerAggregation[agg];
    const volumePerAggregation = aggCubes.reduce(
      (acc, cube) => acc + cube.volume,
      0
    );
    const costPerAggregation = aggCubes.reduce(
      (acc, cube) => acc + cube.cost,
      0
    );
    const weightPerAggregation = aggCubes.reduce(
      (acc, cube) => acc + cube.weight,
      0
    );
    const costPerVolume = costPerAggregation / volumePerAggregation;
    const weightPerVolume = weightPerAggregation / volumePerAggregation;
    if (cubeUnit === "volume") {
      const numberOfCubes = volumePerAggregation / measurePerCube;
      const side = Math.cbrt(measurePerCube);
      for (let i = 0; i < numberOfCubes; i++) {
        const measurementCube = {
          cubeResolution: measurePerCube,
          amount: 1,
          volume: measurePerCube,
          area: side * side,
          length: side,
          width: side,
          depth: side,
          cost: costPerVolume * measurePerCube,
          weight: weightPerVolume * measurePerCube
        };
        measurementCube[aggregation] = agg;
        measurementCubes.push(measurementCube);
      }
    } else if (cubeUnit === "weight") {
      const numberOfCubes = weightPerAggregation / measurePerCube;
      const volumePerCube = volumePerAggregation / numberOfCubes;
      const side = Math.sqrt(volumePerCube);
      for (let i = 0; i < numberOfCubes; i++) {
        const measurementCube = {
          cubeResolution: measurePerCube,
          amount: 1,
          volume: volumePerCube * measurePerCube,
          area: side * side,
          length: side,
          width: side,
          depth: side,
          cost: costPerAggregation / weightPerAggregation * measurePerCube,
          weight: measurePerCube
        };
        measurementCube[aggregation] = agg;
        measurementCubes.push(measurementCube);
      }
    } else if (cubeUnit === "cost") {
      const numberOfCubes = costPerAggregation / measurePerCube;
      const volumePerCube = volumePerAggregation / numberOfCubes;
      const side = Math.sqrt(volumePerCube);
      for (let i = 0; i < numberOfCubes; i++) {
        const measurementCube = {
          cubeResolution: measurePerCube,
          amount: 1,
          volume: volumePerCube * measurePerCube,
          area: side * side,
          length: side,
          width: side,
          depth: side,
          cost: measurePerCube,
          weight: weightPerAggregation / costPerAggregation * measurePerCube
        };
        measurementCube[aggregation] = agg;
        measurementCubes.push(measurementCube);
      }
    }
  }
  return measurementCubes;
}
function getCubesByAggregation(cubes, insight, shipmentValueMap) {
  if (!insight || !insight.cubeComponent || insight.cubeComponent === "component") {
    return cubes;
  }
  const aggregatedCubes = [];
  const batchIdToComponent = cubes.reduce((acc, cube) => {
    acc[cube.batchId] = cube.component;
    return acc;
  }, {});
  const groupIds = cubes.map((cube) => cube[insight.cubeComponent]);
  const uniquGroupIds = [...new Set(groupIds)];
  for (const groupId of uniquGroupIds) {
    const aggCubes = cubes.filter(
      (cube) => cube[insight.cubeComponent] === groupId
    );
    const firstShipmentId = aggCubes[0].shipmentId;
    const shipmentValues = shipmentValueMap[firstShipmentId] || {};
    const batchValues = insight.cubeComponent === "batchId" ? { batchId: groupId, component: batchIdToComponent[groupId] } : {};
    aggregatedCubes.push({
      ...shipmentValues,
      ...batchValues,
      cubeResolution: 1,
      // could potentially need higher resolution if many batches or shipments
      amount: 1,
      cost: aggCubes.reduce((acc, cube) => acc + cube.cost, 0),
      volume: aggCubes.reduce((acc, cube) => acc + cube.volume, 0),
      area: aggCubes.reduce((acc, cube) => acc + cube.area, 0),
      weight: aggCubes.reduce((acc, cube) => acc + cube.weight, 0),
      depth: aggCubes.reduce((acc, cube) => acc + cube.depth, 0),
      width: aggCubes.reduce((acc, cube) => acc + cube.width, 0),
      length: aggCubes.reduce((acc, cube) => acc + cube.length, 0)
    });
  }
  return aggregatedCubes;
}
function getCubesFromParametricInputParams(inputParams, sharedCubeValuesFromParent) {
  const {
    depth,
    // depthMin,
    depthMax,
    depthStd,
    width,
    // widthMin,
    widthMax,
    widthStd,
    length,
    // lengthMin,
    lengthMax,
    lengthStd,
    weight,
    // weightMin,
    weightMax,
    weightStd,
    cost,
    // costMin,
    costMax,
    costStd,
    amount
  } = inputParams;
  const cubeResolution = calculateResolution(amount);
  const newAmount = amount / cubeResolution;
  const cubes = [];
  const syntheticNode = new SyntheticNode(
    {
      key: "temp",
      type: "temp",
      namespace: "temp",
      boardId: "temp"
    },
    {}
  );
  for (let i = 0; i < newAmount; i++) {
    let newWeight;
    if (weightMax) {
      const weightRandom = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "weight",
        min: weight || 0,
        max: weightMax
      });
      newWeight = weightRandom;
    } else if (weightStd) {
      const weightVariance = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "weight",
        mean: weight || 0,
        std: weightStd || 0
      });
      newWeight = weightVariance;
    } else {
      newWeight = weight || 0;
    }
    let newCost;
    if (costMax) {
      const costRandom = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "cost",
        min: cost || 0,
        max: costMax
      });
      newCost = costRandom;
    } else if (costStd) {
      const costVariance = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "cost",
        mean: cost || 0,
        std: costStd || 0
      });
      newCost = costVariance;
    } else {
      newCost = cost || 0;
    }
    let newDepth;
    if (depthMax) {
      const depthRandom = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "depth",
        min: depth || 1,
        max: depthMax
      });
      newDepth = depthRandom;
    } else if (depthStd) {
      const depthVariance = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "depth",
        mean: depth || 1,
        std: depthStd || 0
      });
      newDepth = depthVariance;
    } else {
      newDepth = depth || 1;
    }
    let newWidth;
    if (widthMax) {
      const widthRandom = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "width",
        min: width || 1,
        max: widthMax
      });
      newWidth = widthRandom;
    } else if (widthStd) {
      const widthVariance = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "width",
        mean: width || 1,
        std: widthStd || 0
      });
      newWidth = widthVariance;
    } else {
      newWidth = width || 1;
    }
    let newLength;
    if (lengthMax) {
      const lengthRandom = syntheticNode.generatePropertyValue({
        propertyType: "float",
        propertyKey: "length",
        min: length || 1,
        max: lengthMax
      });
      newLength = lengthRandom;
    } else if (lengthStd) {
      const lengthVariance = syntheticNode.generatePropertyValue({
        propertyType: "gaussian",
        propertyKey: "length",
        mean: length || 1,
        std: lengthStd || 0
      });
      newLength = lengthVariance;
    } else {
      newLength = length || 1;
    }
    const volume = newDepth * newWidth * newLength * cubeResolution;
    cubes.push({
      cubeResolution,
      amount: cubeResolution,
      cost: newCost * cubeResolution,
      volume,
      area: newLength * newWidth * cubeResolution,
      weight: newWeight * cubeResolution,
      depth: newDepth * cubeResolution,
      width: newWidth * cubeResolution,
      length: newLength * cubeResolution,
      ...sharedCubeValuesFromParent
    });
  }
  return cubes;
}
function getWeightPreset(item) {
  const componentProduct = products.find(
    (product) => product.key === item.component
  );
  const preset = componentProduct?.properties?.weightPerM3;
  if (!preset || !item.depth || !item.width || !item.length) {
    return 0;
  }
  return preset * item.depth * item.width * item.length * item.amount;
}
function interpolateValue(factor, value1, value2) {
  return value1 + factor * (value2 - value1);
}
function calculateCO2PerKm(loadInKg, maxLoadInTonnes) {
  const factor = loadInKg / 1e3 / maxLoadInTonnes;
  const value1 = 0.599;
  const value2 = 1.127;
  return interpolateValue(factor, value1, value2);
}
function getDistancePreset(item) {
  if (item.sourceUri === torebodaUri && item.targetUri === sourthernDemoUri) {
    return 2370;
  }
  if (item.sourceUri?.startsWith(forestUriPath) && item.targetUri === torebodaUri) {
    return 250;
  }
}
function calculateShipmentValues(item, items, nodePropsList) {
  const shipmentItems = items.filter(
    (shipmentItem) => shipmentItem.shipmentId === item.shipmentId
  );
  const shipmentWeight = shipmentItems.reduce(
    (acc, shipmentItem) => acc + (shipmentItem.weight || 0),
    0
  );
  let shipmentDuration;
  if (item.startDate && item.endDate) {
    const startDate = new Date(item.startDate).getTime();
    const endDate = new Date(item.endDate).getTime();
    shipmentDuration = endDate - startDate;
    shipmentDuration = shipmentDuration / (1e3 * 60 * 60 * 24);
  }
  let shipmentLoad = 0;
  for (const shipmentItem of shipmentItems) {
    const componentWeight = getWeightPreset(shipmentItem) || 0;
    shipmentLoad += componentWeight;
  }
  const carrier = carriers.find((c) => c.versionUri === item.carrierUri) || carriers[0];
  const vehicleMaxLoad = 24;
  const loadFactor = shipmentLoad / 1e3 / vehicleMaxLoad;
  const co2PerKm = item.component?.startsWith("concrete") ? 3 : calculateCO2PerKm(shipmentLoad, vehicleMaxLoad);
  const shipmentDistance = item.shipmentDistance || getDistancePreset(item) || 0;
  const shipmentEmissions = item.shipmentEmissions || co2PerKm * shipmentDistance;
  const fuelConsumptionLitersPerKm = (carrier.properties.fuelPerKkm || 0.027) / 1e3;
  const shipmentFuelConsumption = fuelConsumptionLitersPerKm * shipmentDistance;
  return {
    shipmentDuration,
    shipmentLoad,
    shipmentEmissions,
    shipmentLoadFactor: loadFactor,
    shipmentFuelConsumption,
    shipmentDistance,
    shipmentId: item.shipmentId
  };
}
function sumEpdStage(epdValues, lcaSteps2, distanceKm) {
  if (epdValues.length !== lcaSteps2.length) {
    console.warn("the epdValues and lcaSteps must have the same length!!");
    return 0;
  }
  const valueMap = lcaSteps2.reduce((acc, val, i) => {
    acc[val] = epdValues[i];
    return acc;
  }, {});
  const firstStage = valueMap.A1A3 ? valueMap.A1A3 : (valueMap.A1 || 0) + (valueMap.A2 || 0) + (valueMap.A3 || 0);
  const A4 = valueMap.A4 || 0;
  const A4WithDistance = distanceKm ? distanceKm / 100 * A4 : A4;
  const B = (valueMap.B1 || 0) + (valueMap.B2 || 0) + (valueMap.B3 || 0) + (valueMap.B4 || 0) + (valueMap.B5 || 0) + (valueMap.B6 || 0) + (valueMap.B7 || 0);
  const C = (valueMap.C1 || 0) + (valueMap.C2 || 0) + (valueMap.C3 || 0) + (valueMap.C4 || 0) + (valueMap.C5 || 0);
  return firstStage + A4WithDistance + B + C;
}
function setEpdStageValues(cube, unit, propertyKey, epdValues, lcaSteps2, distanceKm) {
  let stepIndex = 0;
  for (const lcaStep of lcaSteps2) {
    let value = epdValues[stepIndex];
    if (lcaStep === "A4" && distanceKm) {
      value = distanceKm / 100 * value;
    }
    cube[`${propertyKey}${lcaStep}`] = (cube[unit] || 0) * value;
    stepIndex++;
  }
  return cube;
}
function calculateGwpTotal(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (cube.gwpTotal) {
      continue;
    }
    const epd = getEpd(cube.component);
    const unit = epd?.measurement || "volume";
    if (epd?.epdTable?.gwpTotal) {
      cube.gwpTotal = (cube[unit] || 0) * sumEpdStage(epd.epdTable.gwpTotal, epd.lcaSteps, cube.shipmentDistance);
      setEpdStageValues(
        cube,
        unit,
        "gwpTotal",
        epd.epdTable.gwpTotal,
        epd.lcaSteps,
        cube.shipmentDistance
      );
    }
  }
}
function calculateGwpFossil(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (cube.gwpFossil) {
      continue;
    }
    const epd = getEpd(cube.component);
    const unit = epd?.measurement || "volume";
    if (epd?.epdTable?.gwpFossil) {
      cube.gwpFossil = (cube[unit] || 0) * sumEpdStage(
        epd.epdTable.gwpFossil,
        epd.lcaSteps,
        cube.shipmentDistance
      );
      setEpdStageValues(
        cube,
        unit,
        "gwpFossil",
        epd.epdTable.gwpFossil,
        epd.lcaSteps,
        cube.shipmentDistance
      );
    }
  }
}
function calculateGwpBiogenic(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (cube.gwpBiogenic) {
      continue;
    }
    const epd = getEpd(cube.component);
    const unit = epd?.measurement || "volume";
    if (epd?.epdTable?.gwpBiogenic) {
      cube.gwpBiogenic = (cube[unit] || 0) * sumEpdStage(
        epd.epdTable.gwpBiogenic,
        epd.lcaSteps,
        cube.shipmentDistance
      );
      setEpdStageValues(
        cube,
        unit,
        "gwpBiogenic",
        epd.epdTable.gwpBiogenic,
        epd.lcaSteps,
        cube.shipmentDistance
      );
    }
  }
}
function calculateGwpLuluc(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (cube.gwpLuluc) {
      continue;
    }
    const epd = getEpd(cube.component);
    const unit = epd?.measurement || "volume";
    if (epd?.epdTable?.gwpLuluc) {
      cube.gwpLuluc = (cube[unit] || 0) * sumEpdStage(epd.epdTable.gwpLuluc, epd.lcaSteps, cube.shipmentDistance);
      setEpdStageValues(
        cube,
        unit,
        "gwpLuluc",
        epd.epdTable.gwpLuluc,
        epd.lcaSteps,
        cube.shipmentDistance
      );
    }
  }
}
function calculateGwpGhg(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (cube.gwpGhg) {
      continue;
    }
    const epd = getEpd(cube.component);
    const unit = epd?.measurement || "volume";
    if (epd?.epdTable?.gwpGhg) {
      cube.gwpGhg = (cube[unit] || 0) * sumEpdStage(epd.epdTable.gwpGhg, epd.lcaSteps, cube.shipmentDistance);
      setEpdStageValues(
        cube,
        unit,
        "gwpGhg",
        epd.epdTable.gwpGhg,
        epd.lcaSteps,
        cube.shipmentDistance
      );
    }
  }
}
function calculateNumTrees(cubes, nodePropsList) {
  for (const cube of cubes) {
    if (treesByComponentFunctions[cube.component] && cube.volume > 0) {
      cube.numTrees = treesByComponentFunctions[cube.component](cube.volume);
    }
  }
  return cubes.reduce((acc, cube) => acc + cube.volume, 0);
}
var treesByComponentFunctions = {
  ["glulam"]: (volume) => glulamM3ToTrees(volume),
  ["glulam-gl30c-spruce-planed"]: (volume) => glulamM3ToTrees(volume),
  ["glulam-gl28c-spruce-planed"]: (volume) => glulamM3ToTrees(volume)
};
var glulamM3ToTrees = (volume) => {
  return 6;
  const properties = {};
  const glulamYieldDistribution = properties.glulamYieldDistribution || [
    0.7,
    0.3
  ];
  const sawmillYieldDistribution = properties.sawmillYieldDistribution || [
    0.336,
    0.224,
    0.2,
    0.2,
    0.04
  ];
  const harvestingYieldDistribution = properties.harvestingYieldDistribution || [0.9, 0.1];
  const glulamVolume = volume;
  const glulamYieldFactor = glulamYieldDistribution[0];
  const boardsM3 = glulamVolume / glulamYieldFactor;
  const sawmillYieldFactor = sawmillYieldDistribution[0];
  const sawLogsM3 = boardsM3 / sawmillYieldFactor;
  const sawlogsYieldFactor = harvestingYieldDistribution[0];
  const trees = sawLogsM3 / sawlogsYieldFactor;
  return trees;
};
function calculateResolution(numCubes) {
  if (numCubes < 1e3) {
    return 1;
  } else if (numCubes < 1e4) {
    return 10;
  } else if (numCubes < 1e5) {
    return 100;
  } else if (numCubes < 1e6) {
    return 1e3;
  } else {
    return 1e4;
  }
}

// src/parametric/get-module-children.ts
function getModuleChildren(nodeProps) {
  if (nodeProps.type === TYPE_BUILDING) {
    return getBuildingModuleChildren(nodeProps);
  }
  return [];
}
function getBuildingModuleChildren(nodeProps) {
  if (!nodeProps.parametric) {
    return [];
  }
  const childrenNodeProps = getNodePropsFromParametricInputParamsList(nodeProps);
  const childrenByComponent = childrenNodeProps.reduce(
    (acc, child) => {
      const component = child.properties.component || "default";
      if (!acc[component]) {
        const componentLargeCap = component.charAt(0).toUpperCase() + component.slice(1);
        acc[component] = {
          key: component,
          type: `BuildingDemand${componentLargeCap}`,
          namespace: nodeProps.namespace,
          boardId: nodeProps.boardId,
          properties: {
            isSupply: false,
            component,
            amount: 1,
            volume: 1,
            length: 1,
            width: 1,
            depth: 1,
            weight: 1
          }
        };
      }
      const area = (child.properties.width || 0) * (child.properties.length || 0);
      if (area) {
        acc[component].properties.area = (acc[component].properties.area || 0) + area;
      }
      return acc;
    },
    {}
  );
  const properties = nodeProps.properties || {};
  const glulamM3PerBuildingM2 = properties.glulamM3PerBuildingM2 || 1;
  const glulamNumComponentsPerM3 = properties.glulamNumComponentsPerM3 || 10;
  const glulamWeightPerM3 = properties.glulamWeightPerM3 || 462;
  const glulamCostPerM3 = properties.glulamCostPerM3 || 1e3;
  const glulamM3PerTransport = properties.glulamPerTransportM3 || 50;
  const glulamYieldDistribution = properties.glulamYieldDistribution || [
    0.7,
    0.3
  ];
  const sawmillYieldDistribution = properties.sawmillYieldDistribution || [
    0.336,
    0.224,
    0.2,
    0.2,
    0.04
  ];
  const boardsM3PerTransport = properties.boardsM3PerTransport || 50;
  const boardsNumComponentsPerM3 = properties.boardsNumComponentsPerM3 || 10;
  const boardsWeightPerM3 = properties.boardsWeightPerM3 || 489;
  const logsM3PerTransport = properties.logsM3PerTransport || 50;
  const logsNumComponentsPerM3 = properties.logsNumComponentsPerM3 || 10;
  const logWeightPerM3 = properties.logWeightPerM3 || 882;
  const insulationM3PerBuildingM2 = properties.insulationM3PerBuildingM2 || 0;
  const concreteWallM3PerBuildingM2 = properties.concreteSlabM3PerBuildingM2 || 0;
  const concreteFoundationM3PerBuildingM2 = properties.concreteFoundationM3PerBuildingM2 || 0;
  const facadeModulesM3PerBuildingM2 = properties.facadeModulesM3PerBuildingM2 || 0;
  const roofSipM3PerBuildingM2 = properties.roofSipM3PerBuildingM2 || 0;
  const interiorSipM3PerBuildingM2 = properties.interiorSipM3PerBuildingM2 || 0;
  const children = [];
  if (glulamM3PerBuildingM2 && childrenByComponent.glulam?.properties.area) {
    const glulamVolume = glulamM3PerBuildingM2 * childrenByComponent.glulam.properties.area;
    const side = Math.cbrt(1 / glulamNumComponentsPerM3);
    const numGlulamBatches = Math.ceil(glulamVolume / glulamM3PerTransport);
    const glulamBatches = {
      lengths: [],
      widths: [],
      depths: [],
      weights: [],
      amounts: [],
      components: [],
      isSupply: []
    };
    for (let j = 0; j < numGlulamBatches; j++) {
      const props = {
        length: side,
        width: side,
        depth: side,
        amount: glulamM3PerTransport * glulamNumComponentsPerM3,
        weight: glulamWeightPerM3 / glulamNumComponentsPerM3,
        component: "glulam",
        isSupply: false
      };
      glulamBatches.lengths.push(props.length);
      glulamBatches.widths.push(props.width);
      glulamBatches.depths.push(props.depth);
      glulamBatches.weights.push(props.weight);
      glulamBatches.amounts.push(props.amount);
      glulamBatches.components.push(props.component);
      glulamBatches.isSupply.push(props.isSupply);
    }
    const buildingVersionUri2 = Node.nodePropsToVersionUri(nodeProps);
    children.push({
      ...childrenByComponent.glulam,
      parametric: glulamBatches,
      relations: {
        [RELATION_KEY_UPSTREAM]: [buildingVersionUri2]
      }
      // properties: {
      //   ...childrenByComponent.glulam.properties,
      //   cost: glulamCostPerM3 * glulamVolume,
      //   weight: glulamWeightPerM3,
      //   amount: glulamVolume,
      //   area: 1,
      // },
    });
    const glulamYieldFactor = glulamYieldDistribution[0];
    const boardsM3 = glulamVolume / glulamYieldFactor;
    const boardsSide = Math.cbrt(1 / boardsNumComponentsPerM3);
    const numBoardsBatches = Math.ceil(boardsM3 / boardsM3PerTransport);
    const boardsBatches = {
      lengths: [],
      widths: [],
      depths: [],
      weights: [],
      amounts: [],
      components: [],
      isSupply: []
    };
    for (let j = 0; j < numBoardsBatches; j++) {
      const props = {
        length: boardsSide,
        width: boardsSide,
        depth: boardsSide,
        amount: boardsM3PerTransport * boardsNumComponentsPerM3,
        weight: boardsWeightPerM3 / boardsNumComponentsPerM3,
        component: "boards",
        isSupply: false
      };
      boardsBatches.lengths.push(props.length);
      boardsBatches.widths.push(props.width);
      boardsBatches.depths.push(props.depth);
      boardsBatches.weights.push(props.weight);
      boardsBatches.amounts.push(props.amount);
      boardsBatches.components.push(props.component);
      boardsBatches.isSupply.push(props.isSupply);
    }
    const glulamVersionUri = Node.nodePropsToVersionUri(children[0]);
    children.push({
      key: "boards",
      type: "BuildingDemandBoards",
      namespace: nodeProps.namespace,
      boardId: nodeProps.boardId,
      parametric: boardsBatches,
      relations: {
        [RELATION_KEY_UPSTREAM]: [glulamVersionUri]
      }
    });
    const sawmillYieldFactor = sawmillYieldDistribution[0];
    const logsM3 = boardsM3 / sawmillYieldFactor;
    const logsSide = Math.cbrt(1 / logsNumComponentsPerM3);
    const numLogBatches = Math.ceil(logsM3 / logsM3PerTransport);
    const logBatches = {
      lengths: [],
      widths: [],
      depths: [],
      weights: [],
      amounts: [],
      components: [],
      isSupply: []
    };
    for (let j = 0; j < numLogBatches; j++) {
      const props = {
        length: logsSide,
        width: logsSide,
        depth: logsSide,
        amount: logsM3PerTransport * logsNumComponentsPerM3,
        weight: logWeightPerM3 / logsNumComponentsPerM3,
        component: "logs",
        isSupply: false
      };
      logBatches.lengths.push(props.length);
      logBatches.widths.push(props.width);
      logBatches.depths.push(props.depth);
      logBatches.weights.push(props.weight);
      logBatches.amounts.push(props.amount);
      logBatches.components.push(props.component);
      logBatches.isSupply.push(props.isSupply);
    }
    const boardsVersionUri = Node.nodePropsToVersionUri(children[1]);
    children.push({
      key: "logs",
      type: "BuildingDemandLogs",
      namespace: nodeProps.namespace,
      boardId: nodeProps.boardId,
      parametric: logBatches,
      relations: {
        [RELATION_KEY_UPSTREAM]: [boardsVersionUri]
      }
    });
  }
  return children;
}

// src/node/edge-props.ts
function getEdgeId({ sourceUri, relationKey, targetUri }) {
  if (!sourceUri || !relationKey || !targetUri) {
    throw new Error("sourceUri, relationKey and targetUri are required");
  }
  return `${sourceUri}-${relationKey}-${targetUri}`;
}

// src/node/appearance.ts
function cloneAppearance(apperance = {}) {
  const clone = {
    ...apperance
  };
  if (apperance.fillColor) {
    clone.fillColor = [...apperance.fillColor];
  }
  if (apperance.strokeColor) {
    clone.strokeColor = [...apperance.strokeColor];
  }
  if (apperance.target) {
    clone.target = [...apperance.target];
  }
  if (apperance.rotation) {
    clone.rotation = [...apperance.rotation];
  }
  if (apperance.position) {
    clone.position = [...apperance.position];
  }
  if (apperance.size) {
    clone.size = [...apperance.size];
  }
  if (apperance.scale) {
    clone.scale = [...apperance.scale];
  }
  if (apperance.fontColor) {
    clone.fontColor = [...apperance.fontColor];
  }
  if (apperance.backgroundColor) {
    clone.backgroundColor = [...apperance.backgroundColor];
  }
  return clone;
}

// src/node/node-props.ts
function createRandomKey() {
  return Math.random().toString(36).substring(2);
}
function cloneNodeProps(nodeProps) {
  const clone = { ...nodeProps };
  if (clone.appearance) {
    clone.appearance = cloneAppearance(clone.appearance);
  }
  return clone;
}

// src/node/node-chart.ts
var defaultNodeChart = {
  // layoutKey: 'node',
  vegaType: "barchart",
  cubeUnit: "amount",
  cubeComponent: "component",
  x: "component",
  y: "component",
  z: "volume",
  color: "component",
  scheme: "browns",
  uid: "versionUri",
  sort: "component",
  width: 64,
  height: 64,
  chart: "stacks",
  view: "3d"
};
var nodeChartSettings = /* @__PURE__ */ new Map();
nodeChartSettings.set("layoutKey", {
  label: "Insight type",
  list: [
    { value: "node", label: "Node" },
    { value: "chart", label: "Chart" },
    { value: "map", label: "Map" },
    { value: "gltf", label: "3D model" }
  ],
  propertyKey: "layoutKey"
});

// src/node/get-data-source-nodes.ts
var getDataSourceNodeProps = (fromProps, allNodeProps) => {
  const nodeMap = allNodeProps.reduce((acc, node) => {
    const versionUri = Node.nodePropsToVersionUri(node);
    acc.set(versionUri, node);
    return acc;
  }, /* @__PURE__ */ new Map());
  const dataSourceNodeUris = fromProps.relations?.[RELATION_KEY_HAS_DATA_SOURCE] || [];
  if (!dataSourceNodeUris.length) {
    return [fromProps];
  }
  const dataSourceNodes = dataSourceNodeUris.reduce((acc, dataSourceUri) => {
    const foundNode = nodeMap.get(dataSourceUri);
    if (foundNode) {
      acc.push(foundNode);
    }
    return acc;
  }, []);
  return dataSourceNodes;
};

// src/node/get-node-props-changes.ts
function getNodePropsChanges(prev, next, updateTriggers = {}) {
  const nodeChanges = {
    namespace: prev.namespace,
    key: prev.key,
    type: prev.type
  };
  if (nodeChanges.namespace !== next.namespace) {
    console.warn("Namespace cannot be changed");
    return {
      nodeChanges: null,
      updateTriggers
    };
  }
  if (nodeChanges.key !== next.key) {
    console.warn("Key cannot be changed");
    return {
      nodeChanges: null,
      updateTriggers
    };
  }
  if (nodeChanges.type !== next.type) {
    console.warn("Type cannot be changed");
    return {
      nodeChanges: null,
      updateTriggers
    };
  }
  if (next.updatedAt !== prev.updatedAt) {
    nodeChanges.updatedAt = next.updatedAt;
  }
  if (next.observedAt !== prev.observedAt) {
    nodeChanges.observedAt = next.observedAt;
  }
  if (next.layoutKey !== prev.layoutKey) {
    nodeChanges.layoutKey = next.layoutKey;
    updateTriggers.nodeIconChange = Date.now();
  }
  const {
    appearanceChanges: nameAppearanceChanges,
    updateTriggers: nameAppearanceUpdateTriggers
  } = getAppearanceChanges(prev.name || {}, next.name || {}, updateTriggers);
  if (Object.keys(nameAppearanceChanges).length > 0) {
    nodeChanges.name = { ...nameAppearanceChanges };
    updateTriggers = { ...updateTriggers, ...nameAppearanceUpdateTriggers };
  }
  const {
    appearanceChanges: descriptionAppearanceChanges,
    updateTriggers: descriptionAppearanceUpdateTriggers
  } = getAppearanceChanges(
    prev.description || {},
    next.description || {},
    updateTriggers
  );
  if (Object.keys(descriptionAppearanceChanges).length > 0) {
    nodeChanges.description = { ...descriptionAppearanceChanges };
    updateTriggers = {
      ...updateTriggers,
      ...descriptionAppearanceUpdateTriggers
    };
  }
  const { appearanceChanges, updateTriggers: appearanceUpdateTriggers } = getAppearanceChanges(
    prev.appearance || {},
    next.appearance || {},
    updateTriggers
  );
  if (Object.keys(appearanceChanges).length > 0) {
    nodeChanges.appearance = { ...appearanceChanges };
    updateTriggers = { ...updateTriggers, ...appearanceUpdateTriggers };
  }
  const parametricHasChanged = getParametricChanges(
    prev.parametric || {},
    next.parametric || {}
  );
  if (parametricHasChanged) {
    nodeChanges.parametric = next.parametric;
    updateTriggers.parametricChange = Date.now();
  }
  const { chartChanges, updateTriggers: chartUpdateTriggers } = getNodeChartChanges(prev.chart || {}, next.chart || {});
  if (Object.keys(chartChanges).length > 0) {
    nodeChanges.chart = { ...chartChanges };
    updateTriggers = { ...updateTriggers, ...chartUpdateTriggers };
  }
  if (Object.keys(nodeChanges).length > 3) {
    if (prev.timing || prev.timing === 0) {
      nodeChanges.timing = next.timing;
    } else if (next.timing || next.timing === 0) {
      nodeChanges.timing = next.timing;
    }
    if (prev.boardId) {
      nodeChanges.boardId = prev.boardId;
    } else if (next.boardId) {
      nodeChanges.boardId = next.boardId;
    }
    return {
      nodeChanges,
      updateTriggers
    };
  }
  return {
    nodeChanges: null,
    updateTriggers
  };
}
function getAppearanceChanges(prev, next, updateTriggers = {}) {
  const appearanceChanges = {};
  const prevName = prev.name || "";
  const nextName = next.name || prevName || "";
  if (nextName !== prevName) {
    appearanceChanges.name = nextName;
  }
  const prevDescription = prev.description || "";
  const nextDescription = next.description || prevDescription || "";
  if (nextDescription !== prevDescription) {
    appearanceChanges.description = nextDescription;
  }
  const prevTarget = prev.target || [0, 0, 0];
  const nextTarget = next.target || prevTarget || [0, 0, 0];
  if (nextTarget[0] !== prevTarget[0] || nextTarget[1] !== prevTarget[1] || nextTarget[2] !== prevTarget[2]) {
    appearanceChanges.target = [...nextTarget];
  }
  const prevZoom = prev.zoom || 0;
  const nextZoom = next.zoom || prevZoom || 0;
  if (nextZoom !== prevZoom) {
    appearanceChanges.zoom = nextZoom;
  }
  const prevRotationX = prev.rotationX || 0;
  const nextRotationX = next.rotationX || prevRotationX || 0;
  if (nextRotationX !== prevRotationX) {
    appearanceChanges.rotationX = nextRotationX;
  }
  const prevRotationOrbit = prev.rotationOrbit || 0;
  const nextRotationOrbit = next.rotationOrbit || prevRotationOrbit || 0;
  if (nextRotationOrbit !== prevRotationOrbit) {
    appearanceChanges.rotationOrbit = nextRotationOrbit;
  }
  const prevLongitude = prev.longitude || 0;
  const nextLongitude = next.longitude || prevLongitude || 0;
  if (nextLongitude !== prevLongitude) {
    appearanceChanges.longitude = nextLongitude;
  }
  const prevLatitude = prev.latitude || 0;
  const nextLatitude = next.latitude || prevLatitude || 0;
  if (nextLatitude !== prevLatitude) {
    appearanceChanges.latitude = nextLatitude;
  }
  const prevBearing = prev.bearing || 0;
  const nextBearing = next.bearing || prevBearing || 0;
  if (nextBearing !== prevBearing) {
    appearanceChanges.bearing = nextBearing;
  }
  const prevPitch = prev.pitch || 0;
  const nextPitch = next.pitch || prevPitch || 0;
  if (nextPitch !== prevPitch) {
    appearanceChanges.pitch = nextPitch;
  }
  const prevSize = prev.size || [0, 0];
  const nextSize = next.size || prevSize || [0, 0];
  if (nextSize[0] !== prevSize[0] || nextSize[1] !== prevSize[1]) {
    appearanceChanges.size = [...nextSize];
    updateTriggers.nodeSizeChange = Date.now();
  }
  const prevPosition = prev.position || [0, 0, 0];
  const nextPosition = next.position || prevPosition || [0, 0, 0];
  if (nextPosition[0] !== prevPosition[0] || nextPosition[1] !== prevPosition[1] || nextPosition[2] !== prevPosition[2]) {
    appearanceChanges.position = [...nextPosition];
    updateTriggers.nodePositionChange = Date.now();
  }
  const prevScale = prev.scale || [0, 0, 0];
  const nextScale = next.scale || prevScale || [0, 0, 0];
  if (nextScale[0] !== prevScale[0] || nextScale[1] !== prevScale[1] || nextScale[2] !== prevScale[2]) {
    appearanceChanges.scale = [...nextScale];
  }
  const prevExtrusion = prev.extrusion || 0;
  const nextExtrusion = next.extrusion || prevExtrusion || 0;
  if (nextExtrusion !== prevExtrusion) {
    appearanceChanges.extrusion = nextExtrusion;
  }
  const prevFillColor = prev.fillColor || [0, 0, 0];
  const nextFillColor = next.fillColor || prevFillColor || [0, 0, 0];
  if (nextFillColor[0] !== prevFillColor[0] || nextFillColor[1] !== prevFillColor[1] || nextFillColor[2] !== prevFillColor[2]) {
    appearanceChanges.fillColor = [...nextFillColor];
  }
  const prevStrokeColor = prev.strokeColor || [0, 0, 0];
  const nextStrokeColor = next.strokeColor || prevStrokeColor || [0, 0, 0];
  if (nextStrokeColor[0] !== prevStrokeColor[0] || nextStrokeColor[1] !== prevStrokeColor[1] || nextStrokeColor[2] !== prevStrokeColor[2]) {
    appearanceChanges.strokeColor = [...nextStrokeColor];
  }
  const prevOpacity = prev.opacity || 0;
  const nextOpacity = next.opacity || prevOpacity || 0;
  if (nextOpacity !== prevOpacity) {
    appearanceChanges.opacity = nextOpacity;
  }
  const prevRotation = prev.rotation || [0, 0, 0];
  const nextRotation = next.rotation || prevRotation || [0, 0, 0];
  if (nextRotation[0] !== prevRotation[0] || nextRotation[1] !== prevRotation[1] || nextRotation[2] !== prevRotation[2]) {
    appearanceChanges.rotation = [...nextRotation];
  }
  if (Object.keys(appearanceChanges).length > 0) {
    return {
      appearanceChanges,
      updateTriggers
    };
  }
  return {
    appearanceChanges: {},
    updateTriggers
  };
}
var getNodeChartChanges = (prev, next) => {
  return {
    chartChanges: {},
    // todo: implement
    updateTriggers: {}
  };
};
var getParametricChanges = (prev, next) => {
  const prevAmounts = prev.amounts || [];
  const nextAmounts = next.amounts || [];
  if (prevAmounts.length !== nextAmounts.length) {
    return true;
  }
  for (let i = 0; i < prevAmounts.length; i++) {
    if (prevAmounts[i] !== nextAmounts[i]) {
      return true;
    }
  }
  return false;
};

// src/node/merge-node-props.ts
function mergeNodeProps(nodePropsList) {
  const createdAtMap = /* @__PURE__ */ new Map();
  const latestNodes = /* @__PURE__ */ new Map();
  const latestNodePropsList = nodePropsList.filter((n) => n.timing === void 0).sort((a, b) => {
    const aCreatedAt = a.createdAt ? new Date(a.createdAt) : /* @__PURE__ */ new Date(0);
    const bCreatedAt = b.createdAt ? new Date(b.createdAt) : /* @__PURE__ */ new Date(0);
    return aCreatedAt.getTime() - bCreatedAt.getTime();
  });
  for (const nodeProps of latestNodePropsList) {
    if (!createdAtMap.has(nodeProps.nodeUri)) {
      createdAtMap.set(nodeProps.nodeUri, nodeProps.createdAt);
    }
    const versionUri = Node.nodePropsToVersionUri(nodeProps);
    const acc = latestNodes.get(versionUri) || {};
    const merged = {
      ...acc,
      ...nodeProps,
      appearance: mergeAppearance(
        acc.appearance || {},
        nodeProps.appearance || {}
      ),
      parametric: {
        ...acc.parametric || {},
        ...nodeProps.parametric || {}
      },
      chart: {
        ...acc.chart || {},
        ...nodeProps.chart || {}
      }
    };
    Object.keys(acc).forEach((key) => {
      if (!nodeProps[key] && typeof acc[key] === "string") {
        merged[key] = acc[key];
      }
    });
    if (!Object.keys(merged.appearance || {}).length) {
      delete merged.appearance;
    }
    if (!Object.keys(merged.parametric || {}).length) {
      delete merged.parametric;
    }
    if (!Object.keys(merged.chart || {}).length) {
      delete merged.chart;
    }
    latestNodes.set(versionUri, merged);
  }
  const timingNodes = /* @__PURE__ */ new Map();
  const timingNodePropsListByDate = nodePropsList.filter((n) => n.timing !== void 0).sort((a, b) => {
    const aCreatedAt = a.createdAt ? new Date(a.createdAt) : /* @__PURE__ */ new Date(0);
    const bCreatedAt = b.createdAt ? new Date(b.createdAt) : /* @__PURE__ */ new Date(0);
    return aCreatedAt.getTime() - bCreatedAt.getTime();
  });
  const timingNodePropsListByTiming = timingNodePropsListByDate.sort((a, b) => {
    return a.timing - b.timing;
  });
  for (const nodeProps of timingNodePropsListByTiming.filter(
    (n) => n.timing !== void 0
  )) {
    const versionUri = Node.nodePropsToVersionUri(nodeProps);
    const latestUri = Node.nodePropsToVersionUri({
      ...nodeProps,
      timing: void 0
    });
    let acc = timingNodes.get(versionUri);
    const latestNode = latestNodes.get(latestUri) || {};
    if (!acc) {
      acc = latestNode;
    }
    const merged = {
      ...acc,
      ...nodeProps,
      // always use latest node layout key - cannot change layout between timings
      layoutKey: latestNode.layoutKey,
      appearance: mergeAppearance(
        acc.appearance || {},
        nodeProps.appearance || {}
      ),
      parametric: {
        ...acc.parametric || {},
        ...nodeProps.parametric || {}
      },
      animation: {
        ...acc.animation || {},
        ...nodeProps.animation || {}
      },
      chart: {
        ...acc.chart || {},
        ...nodeProps.chart || {}
      }
    };
    Object.keys(acc).forEach((key) => {
      if (!nodeProps[key] && typeof acc[key] === "string") {
        merged[key] = acc[key];
      }
    });
    if (!Object.keys(merged.appearance || {}).length) {
      delete merged.appearance;
    }
    if (!Object.keys(merged.parametric || {}).length) {
      delete merged.parametric;
    }
    if (!Object.keys(merged.animation || {}).length) {
      delete merged.animation;
    }
    timingNodes.set(versionUri, merged);
  }
  const latestNodesArray = Array.from(latestNodes.values());
  const timingNodesArray = Array.from(timingNodes.values());
  return [...latestNodesArray, ...timingNodesArray].map((np) => {
    np.createdAt = createdAtMap.get(np.nodeUri) || np.createdAt;
    return np;
  });
}
function mergeAppearance(appearance1, appearance2) {
  const appearance1Clone = cloneAppearance(appearance1);
  const appearance2Clone = cloneAppearance(appearance2);
  const appearance = { ...appearance1Clone };
  Object.keys(appearance2Clone).forEach((key) => {
    if (appearance2Clone[key] !== void 0) {
      appearance[key] = appearance2Clone[key];
    } else {
      delete appearance[key];
    }
  });
  return appearance;
  return appearance;
}

// src/properties.ts
var dimensions = {
  dim1: {
    L: "1",
    T: "0",
    M: "0",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  dim2: {
    L: "2",
    T: "0",
    M: "0",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  dim3: {
    L: "3",
    T: "0",
    M: "0",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  measureByDim3: {
    L: "-3",
    T: "0",
    M: "1",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  time: {
    L: "0",
    T: "1",
    M: "0",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  measure: {
    L: "0",
    T: "0",
    M: "1",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  },
  unitless: {
    L: "0",
    T: "0",
    M: "0",
    Theta: "0",
    N: "0",
    I: "0",
    J: "0"
  }
};
var physicalQuantities = {
  length: {
    name: {
      "cf78479d-f570-4d5b-86ca-e7d064710ea2": "length"
    },
    definition: {
      "25f68337-a661-4354-ac1e-34eef26bfddc": "n/a"
    },
    dimension: dimensions.dim1
  },
  volume: {
    name: {
      "11e0e578-2c87-42e6-94ce-e4f0e9260e49": "volume"
    },
    definition: {
      "8ff48534-7edd-4d0d-a76e-1c39dcc599f4": "n/a"
    },
    dimension: dimensions.dim3
  },
  time: {
    name: {
      "3682b123-1d4a-4bdc-824c-a970ddf2f413": "time"
    },
    definition: {
      "207a10ec-68cb-4393-a690-eaeafade747a": "n/a"
    },
    dimension: dimensions.time
  }
};
var units = {
  mm: {
    symbol: "mm",
    name: {
      "495d16db-ef18-48e8-b252-80cec14f4de9": "millimetre"
    },
    definition: {
      "bcbb18ba-06fd-417b-bac3-2b59c0aa1c78": "prefix: milli = 1/1000  SI base unit: metre."
    },
    dimension: dimensions.dim1
  },
  kgM3: {
    symbol: "kg/m\xB3",
    name: {
      "1b45831b-b0a5-4c18-9702-99d91609430a": "kilogram per cubic metre"
    },
    definition: {
      "b9ab9e3b-ebd5-4be1-8569-577a3abb9143": "N/A"
    },
    dimension: dimensions.measureByDim3
  },
  unitless: {
    symbol: "unitless",
    name: {
      "b267ad31-8ddf-475c-9bad-2de87d595aff": "unitless"
    },
    definition: {
      "4a56a189-a2d9-4f83-bcb9-a8b0d8106356": "n/a"
    },
    dimension: dimensions.unitless
  },
  kg: {
    symbol: "kg",
    name: {
      "f59208e3-a8c2-495f-acd4-1c0908b04873": "kilogram"
    },
    definition: {
      "d3a3b2f4-ad11-4972-a058-866014cf786b": "n/a"
    },
    dimension: dimensions.measure
  },
  m3: {
    symbol: "m3",
    name: {
      "655820bc-d405-4203-9bc5-ff2c60043f56": "cubic meter"
    },
    definition: {
      "c9b1b95a-1f03-489a-8625-13a6d2e38b17": "n/a"
    },
    dimension: dimensions.dim3
  },
  kgCo2Eq: {
    name: {
      "3b23101d-6d15-4e79-b940-92751b90b9aa": "kg CO2 eq (100 years)"
    },
    definition: {
      "40b5662d-9307-40e0-a267-d3869e417a67": "unit of global warming potential equivalent to the impact caused 100 years after release of 1 kg of carbon dioxide (CO2)"
    },
    dimension: dimensions.measure
  },
  kgC: {
    name: {
      "73f096f1-171e-4974-9016-3b1edb7fad34": "kg C"
    },
    definition: {
      "84075ad2-ba1c-4525-99f6-2dee487ac206": "kg of carbon where 1 kg biogenic carbon is equivalent to 44/12 kg of CO2"
    },
    dimension: dimensions.measure
  }
};
var propertyList = {
  elementDepth: {
    name: "element depth according to EN 14080",
    definition: "distance from the base to the top",
    dataType: "REAL",
    physicalQuantity: physicalQuantities.length,
    unit: units.mm
  },
  elementWidth: {
    name: "element width according to EN 14080",
    definition: "the shortest distance between the edge surfaces on a specified point of measure of the material transversal to the length",
    dataType: "REAL",
    physicalQuantity: physicalQuantities.length,
    unit: units.mm
  },
  elementLength: {
    name: "element length according to EN 14080",
    definition: "the shortest distance between the edge surfaces on a specified point of measure of the material",
    dataType: "REAL",
    physicalQuantity: physicalQuantities.length,
    unit: units.mm
  },
  volume: {
    name: "volume",
    definition: "the amount of space that a substance or object occupies",
    dataType: "REAL",
    physicalQuantity: physicalQuantities.length,
    unit: units.mm
  }
};

// src/semantic-model.ts
var genericProperties = {
  name: "string",
  description: "string",
  volume: "number",
  length: "number",
  width: "number",
  depth: "number",
  weight: "number",
  cost: "number",
  amount: "number",
  area: "number",
  component: "string",
  isSupply: "boolean",
  observedAt: "date"
};
var actorProperties = {
  longitude: "number",
  latitude: "number"
};
var batchItemProperties = {
  batchId: "string",
  shipmentId: "string"
};
var abstractNode = {
  key: NODE_TYPE_DEFAULT,
  type: NODE_TYPE_TYPE,
  // name: 'Node',
  // description: 'A generic node',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var abstractNodeVersionUri = Node.nodePropsToVersionUri(abstractNode);
var bucketNode = {
  key: NODE_TYPE_BUCKET,
  type: NODE_TYPE_TYPE,
  // name: 'Bucket',
  parentId: abstractNodeVersionUri,
  // description: 'A node used to create insights from other nodes',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var baseNode = {
  key: NODE_TYPE_BASE,
  type: NODE_TYPE_TYPE,
  // name: 'Base',
  parentId: abstractNodeVersionUri,
  // description: 'Generic node for things',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: [249, 246, 243],
    strokeColor: [209, 206, 203]
  }
};
var baseVersionUri = Node.nodePropsToVersionUri(baseNode);
var buildingType = {
  key: TYPE_BUILDING,
  type: NODE_TYPE_TYPE,
  // name: 'Building',
  parentId: baseVersionUri,
  // description: 'Generic building type',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_BUILDING].fillColor,
    strokeColor: typeToColorMap[TYPE_BUILDING].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_BUILDING]}`
  },
  properties: {
    ...genericProperties,
    ...actorProperties
  }
};
var buildingVersionUri = Node.nodePropsToVersionUri(buildingType);
var factoryType = {
  key: TYPE_FACTORY,
  type: NODE_TYPE_TYPE,
  // name: 'Factory',
  parentId: baseVersionUri,
  // description: 'A generic factory type',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_FACTORY].fillColor,
    strokeColor: typeToColorMap[TYPE_FACTORY].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_FACTORY]}`
  },
  properties: {
    ...genericProperties,
    ...actorProperties
  }
};
var factoryVersionUri = Node.nodePropsToVersionUri(factoryType);
var glulamFactoryType = {
  key: TYPE_GLULAM_FACTORY,
  type: NODE_TYPE_TYPE,
  // name: 'Glulam Factory',
  parentId: factoryVersionUri,
  // description: 'A factory that produces glulam components',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_GLULAM_FACTORY].fillColor,
    strokeColor: typeToColorMap[TYPE_GLULAM_FACTORY].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_GLULAM_FACTORY]}`
  },
  properties: {
    ...genericProperties,
    ...actorProperties
  }
};
var glulamFactoryVersionUri = Node.nodePropsToVersionUri(glulamFactoryType);
var sawmillType = {
  key: TYPE_SAWMILL,
  type: NODE_TYPE_TYPE,
  // name: 'Sawmill',
  parentId: factoryVersionUri,
  // description: 'A sawmill that produces sawn goods',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_SAWMILL].fillColor,
    strokeColor: typeToColorMap[TYPE_SAWMILL].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_SAWMILL]}`
  },
  properties: {
    ...genericProperties,
    ...actorProperties
  }
};
var sawmillVersionUri = Node.nodePropsToVersionUri(sawmillType);
var forestType = {
  key: TYPE_FOREST,
  type: NODE_TYPE_TYPE,
  // name: 'Forest',
  parentId: baseVersionUri,
  // description: 'A forest used to quantify potential forest products',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_FOREST].fillColor,
    strokeColor: typeToColorMap[TYPE_FOREST].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_FOREST]}`
  },
  relations: {
    [RELATION_KEY_DOWNSTREAM]: [sawmillVersionUri]
  },
  properties: {
    ...genericProperties,
    ...actorProperties
  }
};
var forestVersionUri = Node.nodePropsToVersionUri(forestType);
var batchListType = {
  key: TYPE_BATCH,
  type: NODE_TYPE_TYPE,
  // name: 'Generic Batch',
  parentId: abstractNodeVersionUri,
  // description: 'A generic batch of items',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var batchTypeVersionUri = Node.nodePropsToVersionUri(batchListType);
var batchItemType = {
  key: TYPE_BATCH_ITEM,
  type: NODE_TYPE_TYPE,
  // name: 'Batch Item',
  parentId: abstractNodeVersionUri,
  // description: 'An item in a batch',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  properties: {
    ...genericProperties,
    ...batchItemProperties
  }
};
var batchItemVersionUri = Node.nodePropsToVersionUri(batchItemType);
var supplyBatchType = {
  key: TYPE_SUPPLY_BATCH,
  type: NODE_TYPE_TYPE,
  // name: 'Supply batch list',
  parentId: batchTypeVersionUri,
  // description: 'A batch of items for downstream supply',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_SUPPLY_BATCH].fillColor,
    strokeColor: typeToColorMap[TYPE_SUPPLY_BATCH].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_SUPPLY_BATCH]}`
  }
};
var supplyBatchVersionUri = Node.nodePropsToVersionUri(supplyBatchType);
var quantificationBatchType = {
  key: TYPE_QUANTIFICATION_BATCH,
  type: NODE_TYPE_TYPE,
  // name: 'Quantification list',
  parentId: batchTypeVersionUri,
  // description: 'A batch of items for quantification',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var quantificationBatchVersionUri = Node.nodePropsToVersionUri(
  quantificationBatchType
);
var caseType = {
  key: NODE_TYPE_CASE,
  type: NODE_TYPE_TYPE,
  // name: 'Case',
  parentId: abstractNodeVersionUri,
  // description: 'A case for decision support',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var caseVersionUri = Node.nodePropsToVersionUri(caseType);
var buildingDemandCase = {
  key: "building-demand-case",
  type: NODE_TYPE_TYPE,
  // name: 'Building component demand case',
  parentId: caseVersionUri,
  // description: 'A case for building component demand',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var componentType = {
  key: TYPE_COMPONENT,
  type: NODE_TYPE_TYPE,
  // name: 'Component',
  parentId: baseVersionUri,
  // description: 'A component of product or material',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var componentVersionUri = Node.nodePropsToVersionUri(componentType);
var dataSourceType = {
  key: NODE_TYPE_DATA_SOURCE,
  type: NODE_TYPE_TYPE,
  // name: 'Data Source',
  parentId: baseVersionUri,
  // description: 'A data source such as a file or database',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID
};
var dataSourceVersionUri = Node.nodePropsToVersionUri(dataSourceType);
var dataSourceExcelFileType = {
  key: TYPE_EXCEL_FILE,
  type: NODE_TYPE_TYPE,
  // name: 'Excel File',
  parentId: dataSourceVersionUri,
  // description: 'An Excel file data source',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_EXCEL_FILE].fillColor,
    strokeColor: typeToColorMap[TYPE_EXCEL_FILE].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_EXCEL_FILE]}`
  }
};
var dataSourceIfcFileType = {
  key: TYPE_IFC_FILE,
  type: NODE_TYPE_TYPE,
  // name: 'IFC File',
  parentId: dataSourceVersionUri,
  // description: 'An IFC file data source',
  namespace: DEFAULT_NAMESPACE,
  boardId: DEFAULT_PROJECT_ID,
  appearance: {
    fillColor: typeToColorMap[TYPE_IFC_FILE].fillColor,
    strokeColor: typeToColorMap[TYPE_IFC_FILE].strokeColor,
    imageUrl: `${ASSET_URL}/${typeToImageMap[TYPE_IFC_FILE]}`
  }
};
buildingType.relations = {
  [RELATION_KEY_UPSTREAM]: [factoryVersionUri, glulamFactoryVersionUri]
};
factoryType.relations = {
  [RELATION_KEY_DOWNSTREAM]: [buildingVersionUri],
  [RELATION_KEY_UPSTREAM]: [sawmillVersionUri]
};
glulamFactoryType.relations = {
  [RELATION_KEY_DOWNSTREAM]: [buildingVersionUri],
  [RELATION_KEY_UPSTREAM]: [sawmillVersionUri]
};
sawmillType.relations = {
  [RELATION_KEY_DOWNSTREAM]: [factoryVersionUri, glulamFactoryVersionUri],
  [RELATION_KEY_UPSTREAM]: [forestVersionUri]
};
forestType.relations = {
  [RELATION_KEY_DOWNSTREAM]: [sawmillVersionUri]
};
batchListType.relations = {
  [RELATION_KEY_CONTAINS]: [batchItemVersionUri]
};
batchItemType.relations = {
  [RELATION_KEY_CONTAINED_IN]: [batchTypeVersionUri]
};
supplyBatchType.relations = {
  [RELATION_KEY_UPSTREAM_SUPPLY]: [
    forestVersionUri,
    sawmillVersionUri,
    factoryVersionUri,
    glulamFactoryVersionUri
  ],
  [RELATION_KEY_DOWNSTREAM_SUPPLY]: [
    sawmillVersionUri,
    factoryVersionUri,
    glulamFactoryVersionUri,
    buildingVersionUri
  ]
};
quantificationBatchType.relations = {
  [RELATION_KEY_COMPOSES]: [
    buildingVersionUri,
    factoryVersionUri,
    glulamFactoryVersionUri,
    sawmillVersionUri,
    forestVersionUri
  ],
  [RELATION_KEY_COMPOSED_BY]: [componentVersionUri]
};
dataSourceType.relations = {
  [RELATION_KEY_DATA_SOURCE_TO]: [
    buildingVersionUri,
    factoryVersionUri,
    glulamFactoryVersionUri,
    sawmillVersionUri,
    forestVersionUri,
    supplyBatchVersionUri,
    quantificationBatchVersionUri
  ]
};
var semanticModel = [
  abstractNode,
  bucketNode,
  baseNode,
  buildingType,
  factoryType,
  glulamFactoryType,
  sawmillType,
  forestType,
  batchListType,
  batchItemType,
  supplyBatchType,
  quantificationBatchType,
  caseType,
  buildingDemandCase,
  dataSourceType,
  dataSourceExcelFileType,
  dataSourceIfcFileType
];

// src/kpis.ts
var defaultInsightKpiDialog = {
  ...defaultNodeChart,
  x: "component",
  y: "volume",
  z: "cost",
  chart: "barchart",
  vegaType: "barchart"
};
var kpiList = [
  {
    name: "Volume",
    propertyKey: "volume",
    benchmarkOn: "sum",
    kpiWeight: 1,
    unit: "m\xB3",
    insight: {
      ...defaultInsightKpiDialog,
      cubeUnit: "amount",
      cubeComponent: "component",
      x: "component",
      y: "volume",
      vegaType: "barchart",
      scheme: "browns"
    },
    defaultVisible: true
  },
  {
    name: "Volume per shipment",
    propertyKey: "volume",
    benchmarkOn: "avg",
    kpiWeight: 1,
    unit: "m\xB3 / shipment",
    insight: {
      ...defaultInsightKpiDialog,
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      x: "shipmentId",
      y: "volume",
      vegaType: "barchart",
      scheme: "browns"
    }
  },
  {
    name: "Emissions per shipment",
    propertyKey: "shipmentEmissions",
    benchmarkOn: "avg",
    kpiWeight: 1,
    unit: "kg CO\u2082eq / shipment",
    insight: {
      ...defaultInsightKpiDialog,
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      color: "shipmentId",
      x: "type",
      y: "shipmentEmissions",
      z: "shipmentId",
      vegaType: "stackedbarchart",
      scheme: "blues"
    }
  },
  {
    name: "Carbon budget kg CO\u2082eq",
    propertyKey: "gwpTotal",
    benchmarkOn: "sum",
    kpiWeight: 1,
    unit: "kg CO\u2082eq",
    insight: {
      ...defaultInsightKpiDialog,
      x: "component",
      y: "gwpTotal",
      cubeUnit: "amount",
      cubeComponent: "component",
      vegaType: "barchart",
      scheme: "tableau20"
    },
    defaultVisible: true
  },
  {
    name: "Total cost",
    propertyKey: "cost",
    benchmarkOn: "sum",
    kpiWeight: 1,
    unit: "\u20AC",
    insight: {
      ...defaultInsightKpiDialog,
      x: "component",
      y: "cost",
      cubeUnit: "amount",
      cubeComponent: "component",
      vegaType: "barchart",
      scheme: "blues"
    },
    defaultVisible: true
  },
  {
    name: "Forest impact",
    propertyKey: "numTrees",
    benchmarkOn: "sum",
    kpiWeight: 1,
    unit: "trees",
    insight: {
      ...defaultInsightKpiDialog,
      x: "component",
      y: "distance",
      cubeUnit: "amount",
      cubeComponent: "component",
      vegaType: "barchart",
      scheme: "greens"
    },
    defaultVisible: true
  },
  {
    name: "Cost per m\xB3 \u20AC",
    propertyKey: "cost",
    benchmarkOn: "avg",
    kpiWeight: 1,
    unit: "\u20AC",
    insight: {
      ...defaultInsightKpiDialog,
      x: "component",
      y: "cost",
      cubeUnit: "amount",
      cubeComponent: "component",
      vegaType: "barchart",
      scheme: "blues"
    }
  },
  // {
  //   name: 'Circularity m³ wood',
  //   propertyKey: 'm³',
  //   sufficient: 1000,
  //   excellent: 500,
  //   kpiWeight: 1,
  // },
  {
    name: "Avg shipment distance",
    propertyKey: "shipmentDistance",
    benchmarkOn: "avg",
    kpiWeight: 2,
    unit: "km / shipment",
    insight: {
      ...defaultInsightKpiDialog,
      x: "shipmentId",
      y: "distance",
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      vegaType: "barchart",
      scheme: "greys"
    }
  },
  {
    name: "Total shipment distance",
    propertyKey: "shipmentDistance",
    benchmarkOn: "sum",
    kpiWeight: 0,
    unit: "km",
    insight: {
      ...defaultInsightKpiDialog,
      x: "shipmentId",
      y: "distance",
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      vegaType: "barchart",
      scheme: "greys"
    }
  },
  {
    name: "Avg fuel consumption / m\xB3",
    propertyKey: "shipmentFuelConsumption",
    benchmarkOn: "avg",
    // sufficient: 0.015,
    // excellent: 0.027,
    kpiWeight: 1,
    unit: "l / m\xB3",
    numDecimals: 4,
    insight: {
      ...defaultInsightKpiDialog,
      x: "shipmentId",
      y: "shipmentFuelConsumption",
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      vegaType: "barchart",
      scheme: "greys"
    }
  },
  {
    name: "Fuel consumption / m\xB3",
    propertyKey: "shipmentFuelConsumption",
    benchmarkOn: "sum",
    // sufficient: 3,
    // excellent: 1.5,
    kpiWeight: 1,
    unit: "l / m\xB3",
    numDecimals: 2,
    insight: {
      ...defaultInsightKpiDialog,
      x: "shipmentId",
      y: "shipmentFuelConsumption",
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      vegaType: "barchart",
      scheme: "greys"
    }
  },
  {
    name: "Transportation emissions",
    propertyKey: "shipmentEmissions",
    benchmarkOn: "sum",
    kpiWeight: 1,
    unit: "kg CO\u2082eq",
    numDecimals: 2,
    insight: {
      ...defaultInsightKpiDialog,
      x: "shipmentId",
      y: "shipmentEmissions",
      cubeUnit: "amount",
      cubeComponent: "shipmentId",
      vegaType: "barchart",
      scheme: "greys"
    }
  }
  // {
  //   name: 'Total global warming potential',
  //   propertyKey: 'gwpTotal',
  //   benchmarkOn: 'sum',
  //   sufficient: 1000,
  //   excellent: 500,
  //   kpiWeight: 1,
  //   unit: 'kg CO₂eq',
  //   numDecimals: 0,
  // },
  // {
  //   name: 'Biogenic global warming potential',
  //   propertyKey: 'gwpBiogenic',
  //   benchmarkOn: 'sum',
  //   sufficient: 1000,
  //   excellent: 500,
  //   kpiWeight: 1,
  //   unit: 'kg CO₂eq',
  // },
  // {
  //   name: 'Fossil global warming potential',
  //   propertyKey: 'gwpFossil',
  //   benchmarkOn: 'sum',
  //   sufficient: 1000,
  //   excellent: 500,
  //   kpiWeight: 1,
  //   unit: 'kg CO₂eq',
  // },
  // {
  //   name: 'Sequestred carbon',
  //   propertyKey: 'gpwFossil',
  //   benchmarkOn: 'sum',
  //   sufficient: 1000,
  //   excellent: 500,
  //   kpiWeight: 1,
  //   unit: 'kg CO₂eq',
  // },
];
function calculateBad(sufficient, excellent, highIsBad) {
  let bad;
  if (!highIsBad) {
    bad = sufficient - 1.5 * (excellent - sufficient);
  } else {
    bad = sufficient + 1.5 * (excellent - sufficient);
  }
  return bad;
}
function normalize(bad, good, reference, result) {
  const normalizedResult = (result - bad) / (good - bad);
  const normalizedReference = (reference - bad) / (good - bad);
  const deviation = (normalizedResult - normalizedReference) / (2 * (1 - normalizedReference)) + 0.5;
  return Math.max(0, Math.min(1, deviation));
}
function normalizeScore(bad, good, sufficient, result, weight) {
  const normalizedResult = normalize(bad, good, sufficient, result);
  weight = Math.max(0, Math.min(1, weight));
  let weightedScore = 10 * normalizedResult * weight;
  let finalScore = Math.max(0, Math.min(10, weightedScore));
  return finalScore;
}
var getKpiStatsFromCubes = (cubes, config) => {
  let result;
  let resultSupply;
  let resultDemand;
  let total = 0;
  let totalAmount = 0;
  let totalVolume = 0;
  let totalWeight = 0;
  let totalSupply = 0;
  let totalAmountSupply = 0;
  let totalVolumeSupply = 0;
  let totalWeightSupply = 0;
  let totalDemand = 0;
  let totalAmountDemand = 0;
  let totalVolumeDemand = 0;
  let totalWeightDemand = 0;
  let avg;
  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;
  let benchmark;
  let score;
  let scoreSupply;
  let scoreDemand;
  let highIsBad = false;
  let badValue;
  let perComponent;
  let perM3;
  let perKg;
  let avgSupply;
  let avgDemand;
  let minSupply = Number.MAX_VALUE;
  let minDemand = Number.MAX_VALUE;
  let maxSupply = Number.MAX_VALUE;
  let maxDemand = Number.MAX_VALUE;
  let perComponentSupply;
  let perComponentDemand;
  let perM3Supply;
  let perM3Demand;
  let perKgSupply;
  let perKgDemand;
  cubes.forEach((cube) => {
    const value = cube[config.propertyKey];
    if (value || value === 0) {
      total += value;
      totalAmount += cube.amount || 1;
      totalVolume += cube.volume || 0;
      totalWeight += cube.weight || 0;
      min = Math.min(min, value);
      max = Math.max(max, value);
      if (cube.isSupply) {
        totalSupply += value;
        totalAmountSupply += cube.amount || 1;
        totalVolumeSupply += cube.volume || 0;
        totalWeightSupply += cube.weight || 0;
        minSupply = Math.min(minSupply, value);
        maxSupply = Math.max(maxSupply, value);
      } else {
        totalDemand += value;
        totalAmountDemand += cube.amount || 1;
        totalVolumeDemand += cube.volume || 0;
        totalWeightDemand += cube.weight || 0;
        minDemand = Math.min(minDemand, value);
        maxDemand = Math.max(maxDemand, value);
      }
    }
  });
  avg = total / cubes.length;
  perComponent = total / totalAmount;
  perM3 = total / totalVolume;
  perKg = total / totalWeight;
  if (totalSupply) {
    avgSupply = totalSupply / cubes.length;
    perComponentSupply = totalSupply / totalAmountSupply;
    perM3Supply = totalSupply / totalVolumeSupply;
    perKgSupply = totalSupply / totalWeightSupply;
  }
  if (totalDemand) {
    avgDemand = totalDemand / cubes.length;
    perComponentDemand = totalDemand / totalAmountDemand;
    perM3Demand = totalDemand / totalVolumeDemand;
    perKgDemand = totalDemand / totalWeightDemand;
  }
  if ((config.excellent || config.excellent === 0) && (config.sufficient || config.sufficient === 0)) {
    highIsBad = config.excellent < config.sufficient;
    badValue = calculateBad(config.sufficient, config.excellent, highIsBad);
    benchmark = config.sufficient;
    result = total;
    if (config.benchmarkOn === "avg") {
      result = avg;
    } else if (config.benchmarkOn === "min") {
      result = min;
    } else if (config.benchmarkOn === "max") {
      result = max;
    } else if (config.benchmarkOn === "amount") {
      result = total / totalAmount;
    } else if (config.benchmarkOn === "volume") {
      result = total / totalVolume;
    } else if (config.benchmarkOn === "weight") {
      result = total / totalWeight;
    }
    score = normalizeScore(
      badValue,
      config.excellent,
      config.sufficient,
      result,
      config.kpiWeight
    );
    if (totalSupply) {
      resultSupply = total;
      if (config.benchmarkOn === "avg") {
        resultSupply = avg;
      } else if (config.benchmarkOn === "min") {
        resultSupply = min;
      } else if (config.benchmarkOn === "max") {
        resultSupply = max;
      } else if (config.benchmarkOn === "amount") {
        resultSupply = total / totalAmount;
      } else if (config.benchmarkOn === "volume") {
        resultSupply = total / totalVolume;
      } else if (config.benchmarkOn === "weight") {
        resultSupply = total / totalWeight;
      }
      scoreSupply = normalizeScore(
        badValue,
        config.excellent,
        config.sufficient,
        resultSupply,
        config.kpiWeight
      );
    }
    if (totalDemand) {
      resultDemand = total;
      if (config.benchmarkOn === "avg") {
        resultDemand = avg;
      } else if (config.benchmarkOn === "min") {
        resultDemand = min;
      } else if (config.benchmarkOn === "max") {
        resultDemand = max;
      } else if (config.benchmarkOn === "amount") {
        resultDemand = total / totalAmount;
      } else if (config.benchmarkOn === "volume") {
        resultDemand = total / totalVolume;
      } else if (config.benchmarkOn === "weight") {
        resultDemand = total / totalWeight;
      }
      scoreDemand = normalizeScore(
        badValue,
        config.excellent,
        config.sufficient,
        totalDemand,
        config.kpiWeight
      );
    }
  }
  return {
    result,
    resultSupply,
    resultDemand,
    avg,
    min,
    max,
    total,
    totalAmount,
    totalVolume,
    totalWeight,
    perComponent,
    perM3,
    perKg,
    avgSupply,
    avgDemand,
    minSupply,
    minDemand,
    maxSupply,
    maxDemand,
    perComponentSupply,
    perComponentDemand,
    perM3Supply,
    perM3Demand,
    perKgSupply,
    perKgDemand,
    score,
    scoreSupply,
    scoreDemand,
    highIsBad,
    badValue
  };
};

// src/node/node-utils.ts
function getChildNodes(nodePropsMap, nodeUri) {
  const nodes = [...nodePropsMap.values()];
  function getChildNodesRecursive(parentId) {
    let childNodes = [];
    for (const n of nodes) {
      if (parentId && n.parentId === parentId) {
        childNodes.push(n);
        const childUri = Node.nodePropsToUri(n);
        const grandChildNodes = getChildNodesRecursive(childUri);
        childNodes = childNodes.concat(grandChildNodes);
      }
    }
    return childNodes;
  }
  return getChildNodesRecursive(nodeUri);
}
export {
  ASSET_URL,
  BUILT_IN_NODE_TYPES,
  CATALOG_BIRCH,
  CATALOG_BIRCH_LOGS,
  CATALOG_GLULAM,
  CATALOG_GLULAM_BEAM,
  CATALOG_GLULAM_COLUMN,
  CATALOG_PINE,
  CATALOG_PINE_LOGS,
  CATALOG_SMALL_DIAMETER_PINE_LOGS,
  CATALOG_SMALL_DIAMETER_SPRUCE_LOGS,
  CATALOG_SPRUCE,
  CATALOG_SPRUCE_LOGS,
  DEFAULT_NAMESPACE,
  DEFAULT_PROJECT_ID,
  Edge,
  NODE_TYPE_BASE,
  NODE_TYPE_BUCKET,
  NODE_TYPE_CARRIER,
  NODE_TYPE_CASE,
  NODE_TYPE_CATALOG,
  NODE_TYPE_CELL,
  NODE_TYPE_DATA_SOURCE,
  NODE_TYPE_DEFAULT,
  NODE_TYPE_INDICATOR,
  NODE_TYPE_INSIGHT_COLUMN,
  NODE_TYPE_PROJECT,
  NODE_TYPE_PROPERTY,
  NODE_TYPE_RELATION,
  NODE_TYPE_SCENARIO,
  NODE_TYPE_SYNTHETIC,
  NODE_TYPE_THING,
  NODE_TYPE_TYPE,
  NODE_TYPE_WORKSPACE,
  Node,
  PROPERTY_EVENT_ARRIVED_AT,
  PROPERTY_EVENT_SHIPPED_AT,
  PROPERTY_KEY_CATALOG,
  PROPERTY_LOCATION,
  RELATION_KEY_CARRIER_SOURCE,
  RELATION_KEY_CARRIER_TARGET,
  RELATION_KEY_COMPOSED_BY,
  RELATION_KEY_COMPOSES,
  RELATION_KEY_CONTAINED_IN,
  RELATION_KEY_CONTAINS,
  RELATION_KEY_DATA_SOURCE_TO,
  RELATION_KEY_DOWNSTREAM,
  RELATION_KEY_DOWNSTREAM_DEMAND,
  RELATION_KEY_DOWNSTREAM_SUPPLY,
  RELATION_KEY_HAS_CHILD,
  RELATION_KEY_HAS_DATA_SOURCE,
  RELATION_KEY_HAS_PARENT,
  RELATION_KEY_HAS_PROPERTY,
  RELATION_KEY_HAS_TYPE,
  RELATION_KEY_PROPERTY_OF,
  RELATION_KEY_TRACE,
  RELATION_KEY_TRACK,
  RELATION_KEY_UPSTREAM,
  RELATION_KEY_UPSTREAM_DEMAND,
  RELATION_KEY_UPSTREAM_SUPPLY,
  SyntheticModule,
  SyntheticNode,
  TEST_URI_1,
  TEST_URI_2,
  TYPE_BATCH,
  TYPE_BATCH_ITEM,
  TYPE_BOARD_BATCH,
  TYPE_BOM,
  TYPE_BUILDING,
  TYPE_BUILDING_COMPONENT,
  TYPE_BUILDING_SUB_COMPONENT,
  TYPE_BUILDING_SYSTEM,
  TYPE_BUILDING_SYSTEM_FACADE,
  TYPE_BUILDING_SYSTEM_INTERIOR,
  TYPE_BUILDING_SYSTEM_ROOF,
  TYPE_BUILDING_SYSTEM_STRUCTURE,
  TYPE_CATALOG_MATERIAL,
  TYPE_CATALOG_PROCESSED_MATERIAL,
  TYPE_CATALOG_PRODUCT,
  TYPE_CATALOG_PRODUCT_EPD,
  TYPE_CATALOG_PRODUCT_GLULAM,
  TYPE_CATALOG_RAW_MATERIAL,
  TYPE_COMPONENT,
  TYPE_EXCEL_FILE,
  TYPE_FACTORY,
  TYPE_FILE,
  TYPE_FOREST,
  TYPE_FOREST_CELL,
  TYPE_FOREST_HARVESTING,
  TYPE_FOREST_LOADING_ZONE,
  TYPE_FOREST_PRODUCT_BATCH,
  TYPE_FOREST_STAND,
  TYPE_FOREST_TREE,
  TYPE_FOREST_TREE_CELL,
  TYPE_FOREST_TREE_GROUP,
  TYPE_GEOJSON_FILE,
  TYPE_GLULAM_BATCH,
  TYPE_GLULAM_FACTORY,
  TYPE_GLULAM_FACTORY_PRODUCT_BATCH,
  TYPE_GLULAM_FACTORY_STATION,
  TYPE_HARVESTING_BRANCHES,
  TYPE_HARVESTING_FIREWOOD,
  TYPE_HARVESTING_LOG,
  TYPE_HARVESTING_TRUNK,
  TYPE_HARVESTING_VIRKESORDER,
  TYPE_IFC_FILE,
  TYPE_LOG_BATCH,
  TYPE_QUANTIFICATION_BATCH,
  TYPE_SAWMILL,
  TYPE_SAWMILL_PRODUCT_BATCH,
  TYPE_SAWMILL_STATION,
  TYPE_SUPPLY_BATCH,
  accent,
  blue,
  brown,
  carriers,
  catalogToImageMap,
  cloneAppearance,
  cloneNodeProps,
  createRandomKey,
  dark,
  defaultNodeChart,
  dimensions,
  epdList,
  generateForest,
  generateNodeKey,
  generateRandomSeedNumbers,
  getAggregatedParametricInputParamsListFromNodeProps,
  getAppearanceChanges,
  getChildNodes,
  getCubesFromNodeProps,
  getDataSourceNodeProps,
  getEdgeId,
  getEpd,
  getKpiStatsFromCubes,
  getModuleChildren,
  getNodeChartChanges,
  getNodePropsChanges,
  getNodePropsFromParametricInputParamsList,
  getParametricChanges,
  getParametricInputItemsFromParametricInputParamsList,
  getParametricInputParamsListFromParametricInputItems,
  getProductDictionary,
  getPropertiesFromParametric,
  getRandomId,
  getRounding,
  getSegmentsFromNodeProps,
  getTreePositions,
  green,
  grey,
  kpiList,
  mergeAppearance,
  mergeNodeProps,
  nodeChartSettings,
  orange,
  physicalQuantities,
  pink,
  products,
  propertyList,
  purple,
  red,
  roundNumber,
  semanticModel,
  typeToColorMap,
  typeToImageMap,
  units,
  white,
  yellow
};
