import { OrbitViewState, DeckProps } from '@deck.gl/core/typed';
import {
  Appearance,
  DEFAULT_NAMESPACE,
  Node,
  NodeProps,
} from '@paramountric/graph';
import { cubicIn } from './util/ease';
import { InteractionMode } from './interaction-manager';

export type SelectionStyle = {
  fillColor?: [number, number, number, number];
  strokeColor?: [number, number, number, number];
  strokeWidth?: number;
  filled?: boolean;
  stroked?: boolean;
};

export type NodeViewerProps = {
  // try to clean this up
  projectNode: NodeProps;
  presenting?: boolean;

  // OLD OPTIONS, MOVE UP OR DELETE

  debug?: boolean;
  insights?: Appearance[];
  // many of the first properties are mandatory by logic (do not type it due to prototyping)
  assetUrl?: string;
  // urls
  baseMapConfig?: {
    [mapSetting: string]: string;
  };
  interactionMode?: InteractionMode;
  onContextLost?: (event: any) => void;
  // backend props
  offline?: boolean; // if true, no backend fetching will be done
  token?: string;
  projectName?: string;
  namespace?: string;
  nodesUrl?: string;
  hubUrl?: string;
  speckleUrl?: string;
  activeProjectIds?: { [projectId: string]: boolean };
  typeNodes?: Node[];
  propertyNodes?: Node[];
  // initial layout
  initialPortalLayout?: any;
  // viewport size in pixels
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  // if this is used, no fetching from backend will be done
  nodes?: Node[];
  // camera
  zoom?: number;
  target?: [number, number, number];
  rotationX?: number;
  rotationOrbit?: number;
  minZoom?: number;
  maxZoom?: number;
  // background
  backgroundColor?: number[];
  darkMode?: boolean;
  darkModeBackgroundColor?: number[];
  lightModeBackgroundColor?: number[];
  // view
  viewX?: number;
  viewY?: number;
  viewWidth?: number;
  viewHeight?: number;

  flyTo?: OrbitViewState;
  flyToDuration?: number; // milliseconds
  orthographic?: boolean;
  disableController?: boolean;
  disableController2?: boolean;
  animate?: boolean;
  animateNodes?: boolean; // for transitions on nodes
  graph?: Node;

  typeToAsset?: {
    [type: string]: string; // string is full url to image/gltf (depending on file ending) on server
  };

  hideGrid?: boolean; // deactivate grid / points
  hideViewportLayers?: boolean; // don't render viewport layers - for example if portal overlay
  showNodeBorders?: boolean; // show helper borders around nodes - not to confuse with any node style

  showTimeline?: boolean;
  timelineStart?: Date; // start date
  timelineEnd?: Date; // end date
  timelineCursor?: Date; // cursor position
  timelineSpeed?: number; // better make this slow/fast and then look at range to see how much time per step
  timelineState?: 'play' | 'pause' | 'stop';

  focusNode?: Node;
  focusNodeWidth?: number;
  focusNodeHeight?: number;

  selectionStyle?: SelectionStyle;
  resizeStyle?: SelectionStyle;

  awsAccessKeyId?: string;
  awsSecretAccessKey?: string;

  presenters?: any;
  // onClick?: (event: any) => void;
  // layout?:
  //   | 'force'
  //   | 'tree'
  //   | 'sankey'
  //   | 'auto'
  //   | 'manual'
  //   | 'focus'
  //   | undefined;
  // these are the relations that will be shown on the canvas
  relationKeys?: string[];
  // this is the indicator key for the value that will be used from the nodes and edges
  propertyKey?: string;
  // actually we are using a list as well
  propertyKeys?: string[];
  // nodeGroups?: NodeGroups;
  showCarrierNodesByType?: {
    [type: string]: boolean;
  };
  edgeType?: 'straight' | 'bezier';
  enableDragging?: boolean;
  // gltfs?: GltfMap;
  metadataLevels?: number;
  autoGroupLimit?: number;
  showTypeNodes?: boolean;

  unlockOutgoingNodesOnDrag?: boolean;

  lockNodesOnLayoutDone?: boolean;

  stopForceLayoutAfterSeconds?: number;
  showCircles?: boolean; // override squared only showing circles

  // put all default node style into the nodeStyleProps
  // nodeStyle?: NodeStyle;
  // edgeStyle?: EdgeStyle;

  showEdgeLabels?: boolean;

  longitude?: number;
  latitude?: number;
  bearing?: number;
  pitch?: number;
  parent?: HTMLDivElement;
  canvas?: HTMLCanvasElement | string;
  meshData?: any;
  mesh?: any;
  meshes?: any; // map of { model (mesh data), modelMatrix}

  useLightingEffects?: boolean;
  postProcessEffects?: any; // an array of the configs of PostProcessEffect

  onClick?: (info: any, e: any) => void;
  transitions?: {
    [propertyKey: string]: any;
  };
  disableTransitions?: boolean;
  glOptions?: any;

  ignoreDisconnectedNodes?: boolean;

  // force
  collisionRadius?: number;
  alpha?: number;
  resumeAlpha?: number;
  nBodyStrength?: number;
  nBodyDistanceMin?: number;
  nBodyDistanceMax?: number;

  // sankey
  sankeyWidth?: number;
  sankeyHeight?: number;
  sankeyNodeWidth?: number;
  sankeyNodePadding?: number;
  sankeyHorizontalDistanceBetweenNodes?: number;
  sankeyAutoSize?: boolean;
  sankeyAlignment?: 'left' | 'right' | 'center' | 'justify';
  useSankeyHeights?: boolean;

  // these are for feeding in custom classes/settings for the json parser
  classes?: any;
  functions?: any;
  enumerations?: any;
  constants?: any;
} & Omit<DeckProps, 'views'>;

// this is overridden from board with the user settings on initial camera state
export const defaultState = {
  id: 'master',
  name: 'Board',
  timing: 0,
  slideDuration: 1000,
  transitionDuration: 0,
  transitionEasing: 'linear',
};

export const defaultNodeViewerProps: NodeViewerProps = {
  // this should be replaced from the data in DbBoard
  projectNode: {
    key: 'project',
    name: { name: 'Project' },
    type: 'Project',
    namespace: DEFAULT_NAMESPACE,
    appearance: defaultState, // the default insight of the board
  },
  insights: [], // the other slides
};
